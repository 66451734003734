@use '../lib/screen';

.cart-panel {
  position: fixed;
  bottom: var(--size-tabbar-height);
  left: 0;
  z-index: var(--z-index-tabbar);
  width: 100%;
  pointer-events: none;

  @include screen.from(screen.$md) {
    right: var(--size-container-offset-left);
    bottom: 0;
    left: var(--size-container-offset-left);
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: auto;
    column-gap: var(--size-gap);
  }
}

.cart-panel__inner {
  display: flex;
  align-items: center;
  min-height: var(--size-buy-bar-height);
  padding: 12px var(--size-container-sidegap);
  border-top: 1px solid var(--color-lines);
  background-color: var(--color-bg);
  filter: drop-shadow(0 0 12px rgb(0 0 0 / 12%));
  pointer-events: auto;

  @include screen.from(screen.$md) {
    padding: 12px 16px;
    border-radius: 16px 16px 0 0;
  }
}

.cart-panel__submit {
  min-width: 150px;
  margin-left: auto;
}

.cart-panel__count {
  margin-bottom: 4px;
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 16px;
}

.cart-panel__price {
  font-weight: 550;
  font-size: 13px;
  line-height: 16px;
}
