@use "../lib/screen";

.subcategories {
  overflow: hidden;
}

.subcategories__swiper {
  overflow: visible;
}

.subcategories__list {
  @include screen.from(screen.$md) {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 8px;
  }
}

.subcategories__item {
  width: 136px;
  height: 128px;
  margin-right: 8px;

  @include screen.from(screen.$sm) {
    width: 172px;
    margin-right: 12px;
  }

  @include screen.from(screen.$md) {
    width: auto !important;
    height: 96px;
    margin-right: 0 !important;
  }
}

.subcategories__card {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  background: var(--color-grey-05);
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  transition: color var(--transition-in);
  isolation: isolate;

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }
}

.subcategories__picture {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 96px;
  height: 96px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
