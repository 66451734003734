@use "../lib/screen";

.h1 {
  font-weight: 550;
  font-size: 22px;
  line-height: 1.25;

  @include screen.from(screen.$sm) {
    font-size: 24px;
    line-height: 1.35;
  }
}

.h2 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 550;
  font-size: 20px;
  line-height: 1.25;

  @include screen.from(screen.$sm) {
    font-size: 22px;
    line-height: 1.35;
  }
}

.h3 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 550;
  font-size: 18px;
  line-height: 1.25;

  @include screen.from(screen.$sm) {
    font-size: 20px;
    line-height: 1.35;
  }
}

.h-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.h-counter {
  vertical-align: 10%;
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 16px;
}
