$xs: 320px; // mobile
$sm: 768px;
$md: 1024px; // tablet
$lg: 1200px;

@mixin from($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin to($breakpoint) {
  @media screen and (max-width: $breakpoint - 1) {
    @content;
  }
}

@mixin between($from, $to) {
  @media screen and (min-width: $from - 1) and (max-width: $to - 1) {
    @content;
  }
}

@mixin from-height($breakpoint) {
  @media screen and (min-height: $breakpoint) {
    @content;
  }
}
