@use '../lib/screen';

.blog {
  padding-top: 24px;
  padding-bottom: 96px;
}

.blog__list {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  row-gap: 16px;

  // overlaps and hides line of last items
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-bg);
  }

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 24px;
    column-gap: var(--size-gap);
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.blog__item {
  position: relative;
  display: flex;
  padding-bottom: 16px;

  & > * {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% + var(--size-gap));
    height: 1px;
    background-color: var(--color-lines);
  }

  @include screen.from(screen.$sm) {
    padding-bottom: 24px;
  }
}
