@use '../lib/screen';

.custom-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 320px;
  margin: 0 auto;
  padding: 16px 16px 96px;

  @include screen.from(screen.$sm) {
    padding-top: 24px;
  }
}

.custom-payment__legal {
  color: var(--color-text-aux);
  font-size: 12px;
  text-align: center;
}
