@use '../lib/screen';

.review-card {
  display: grid;
  grid-template:
    'avatar name'
    'avatar rating'
    'text text'
    'gallery gallery' 1fr
    / 48px minmax(0, 1fr);
  overflow: hidden;
  padding: 16px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  column-gap: 12px;

  &.is-dark {
    background: var(--color-grey-05);
  }
}

.review-card__avatar {
  display: block;
  grid-area: avatar;
  width: 48px;
  height: 48px;
  border-radius: 999px;
  background: var(--color-grey-05) url(../svg/review-avatar.svg) no-repeat center;
  background-size: contain;

  &.is-yandex {
    background-image: url(../svg/review-avatar-yandex.svg);
  }

  &.is-google {
    background-image: url(../svg/review-avatar-google.svg);
  }

  .review-card.is-dark & {
    background-color: var(--color-grey-1);
  }
}

.review-card__name {
  grid-area: name;
  margin-bottom: 4px;
  font-weight: 550;
}

.review-card__rating {
  display: flex;
  grid-area: rating;
  align-items: center;
}

.review-card__date {
  display: flex;
  color: var(--color-text-aux);

  @include screen.to(screen.$sm) {
    .review-card.is-narrow & {
      span {
        display: none;
      }
    }
  }
}

.review-card__text {
  grid-area: text;
  margin-top: 12px;
}

.review-card__gallery-swiper {
  grid-area: gallery;
  overflow: visible;
  margin: 12px 0 0;
}

.review-card__slide {
  width: 96px;
  height: 96px;
  margin-right: 8px;
}

.reviews-card__link {
  display: block;
  overflow: hidden;
  border-radius: 8px;
}

.reviews-card__photo {
  position: relative;
  display: block;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
