.checkbox {
  display: block;
  padding: 4px 0;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.checkbox__label {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 28px;
  line-height: 20px;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-lines);
    border-radius: 4px;
    background-color: #fff;
    transition: background-color var(--transition-in),
      border-color var(--transition-in), box-shadow var(--transition-in);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    background: url(../svg/checkbox-mark.svg) no-repeat center;
  }

  &:hover,
  &:focus {
    &::before {
      border-color: var(--color-accent);
    }
  }

  .checkbox__input:focus-visible + & {
    &::before {
      border-color: var(--color-accent);
      box-shadow: var(--focus-visible-shadow);
    }
  }

  .checkbox__input:checked + & {
    &::before {
      border-color: var(--color-accent);
      background-color: var(--color-accent);
    }
  }

  .checkbox__aux-label {
    display: inline-block;
    margin-left: 4px;
    color: var(--color-text-aux)
  }
}
