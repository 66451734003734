@use '../lib/screen';

.contacts-page__contacts {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  margin-bottom: 48px;

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include screen.from(screen.$lg) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.contacts-page__contact {
  min-height: 144px;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--color-blocks);
  font-size: 13px;
  line-height: 1.4;

  @include screen.from(screen.$sm) {
    padding: 16px;
  }
}

.contacts-page__contact-title {
  margin-bottom: 4px;
  font-weight: 550;
}

.contacts-page__contact-text {
  margin-bottom: 4px;
}

.contacts-page__contact-aux {
  color: var(--color-text-aux);
}

.contacts-page__requisites {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 16px;

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include screen.from(screen.$lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
