@use '../lib/screen';

.totop {
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: var(--z-index-fab);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 0;
  border-radius: 100%;
  background: var(--color-accent);
  color: var(--color-white);
  opacity: 0;
  cursor: pointer;
  user-select: none;
  transition: background-color var(--transition-in), opacity var(--transition-in),
    transform var(--transition-in);
  transform: translateY(10px);
  appearance: none;

  &:hover,
  &:focus-visible {
    background-color: var(--color-accent-active);
  }

  &.is-shown {
    opacity: 1;
    transform: none;
  }

  @include screen.to(screen.$md) {
    display: none;
  }
}
