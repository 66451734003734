.toggle {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
}

.toggle__input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.toggle__icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: var(--color-accent);
}

.toggle__label {
  display: flex;
  gap: 4px;
  align-items: center;
  padding-right: 44px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 36px;
    height: 20px;
    border: 1px solid var(--color-lines);
    border-radius: 999px;
    background-color: #fff;
    transition: background-color var(--transition-in), border-color var(--transition-in);
  }

  &::after {
    content: '';
    position: absolute;
    top: 3px;
    right: 19px;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background-color: var(--color-grey-2);
    transition: background-color var(--transition-in), transform 0.3s var(--ease-in-out-quart);
  }

  .toggle__icon ~ & {
    padding-left: 32px;
  }

  &:hover {
    &::before {
      border-color: var(--color-accent);
    }
  }

  .toggle__input:checked + & {
    &::before {
      border-color: var(--color-accent);
      background-color: var(--color-accent);
    }

    &::after {
      background-color: #fff;
      transform: translateX(16px);
    }
  }

  .toggle__input:focus + &,
  .toggle__input:active + & {
    &::before {
      border-color: var(--color-accent);
      box-shadow: var(--focus-visible-shadow);
    }
  }
}

.toggle__description {
  display: block;
  margin-top: 4px;
  padding-right: 40px;
  color: var(--color-text-aux);

  .toggle__icon ~ & {
    padding-left: 32px;
  }
}

.toggle__link {
  margin-top: 4px;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;

  .toggle__icon ~ & {
    padding-left: 32px;
  }
}
