.toolbar-button {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 13px;
  transition: color var(--transition-in);
  appearance: none;

  &:hover,
  &:focus-visible,
  &.is-active {
    color: var(--color-accent);
  }
}

.toolbar-button__inner {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toolbar-button__badge {
  position: absolute;
  top: -4px;
  right: 8px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  height: 18px;
  padding: 0 2px;
  border: 2px solid #fff;
  border-radius: 999px;
  background-color: var(--color-accent);
  color: #fff;
  font-weight: 550;
  font-size: 12px;
  line-height: 1;
}
