@use '../lib/screen';

.reviews {
}

.reviews__section {
  display: grid;
  grid-template:
    'heading'
    'summary'
    'list'
    'more'
    / 100%;
  align-items: start;

  &:not(:last-of-type) {
    margin-bottom: 48px;
    padding-bottom: 48px;
    border-bottom: 1px solid var(--color-lines);
  }

  @include screen.from(screen.$md) {
    grid-template:
      'heading heading'
      'list    summary'
      'more    more'
      / minmax(0, 8fr) minmax(0, 4fr);
    column-gap: var(--size-gap);
  }
}

.reviews__heading {
  grid-area: heading;
}

.reviews__summary {
  grid-area: summary;
  margin-bottom: 16px;

  @include screen.from(screen.$sm) {
    margin-bottom: 24px;
  }

  @include screen.from(screen.$md) {
    margin-left: 16px;
  }

  @include screen.from(screen.$lg) {
    margin-left: 24px;
  }
}

.reviews__list {
  display: grid;
  grid-area: list;
  grid-template-columns: 100%;
  gap: 16px;
}

.reviews__more {
  margin-top: 16px;
}
