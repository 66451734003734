.details,
.wp-block-details {
  border-top: 1px solid var(--color-lines);

  &[open] {
    padding-bottom: 12px;
  }

  &:last-child {
    border-bottom: 1px solid var(--color-lines);
  }

  summary {
    position: relative;
    padding-top: 12px;
    padding-right: 32px;
    padding-bottom: 12px;
    outline: none;
    list-style: none;
    font-weight: 550;
    cursor: pointer;
    user-select: none;

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 20px;
      height: 20px;
      background-color: var(--color-grey-3);
      transition: transform var(--transition-in);
      transform: translateY(-50%);
      mask-image: url(../svg/icons/chevron-down.svg);
    }
  }
}

details[open] summary {
  &::after {
    transform: translateY(-50%) scaleY(-1);
  }
}
