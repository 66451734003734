@use '../lib/screen';

.promotions {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background: var(--color-grey-4);
  color: #fff;
}

.promotions__bg {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  pointer-events: none;
  user-select: none;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.promotions__layout {
  position: relative;
  padding: 16px;
  border-bottom: 1px solid var(--color-white-16);

  @include screen.from(screen.$sm) {
    display: grid;
    grid-template-columns: minmax(0, 5fr) minmax(0, 7fr);
    gap: 16px;
    align-items: start;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: minmax(0, 1fr) 400px;

    .promotions.is-wide & {
      grid-template-columns: minmax(0, 1fr) 530px;
    }
  }
}

.promotions__left {
  margin-bottom: 12px;
}

.promotions__heading {
  margin-bottom: 8px;
  font-weight: 550;
  font-size: 20px;
  line-height: 24px;

  @include screen.from(screen.$sm) {
    font-size: 22px;
    line-height: 28px;
  }
}

.promotions__heading-icon {
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 2px;
}

.promotions__heading-text {
  display: inline;
}

.promotions__description {
  max-width: 350px;
  color: var(--color-white-50);
  font-size: 14px;
  line-height: 20px;

  & > p + p {
    margin-top: 1em;
  }
}

.promotions__slider {
  --swiper-navigation-offset: -8px;
}

.promotions__swiper {
  width: 100%;

  @include screen.to(screen.$sm) {
    overflow: visible;
  }
}

.promotions__slide {
  display: flex;
  height: auto;

  > * {
    width: 100%;
    height: 100%;
  }
}

.promotions__countdown {
  margin-top: 24px;
  margin-bottom: 24px;
}

.promotions__telegram {
  position: relative;
  padding: 16px;
  color: var(--color-white-50);
  font-size: 14px;
  line-height: 20px;

  @include screen.from(600px) {
    display: flex;
    align-items: center;
    padding: 12px 16px;
  }
}

.promotions__more {
  margin-bottom: 12px;

  @include screen.from(600px) {
    margin-bottom: 0;
  }
}

.promotions__subscribe {
  width: 100%;

  @include screen.from(600px) {
    width: auto;
    margin-left: auto;
  }
}
