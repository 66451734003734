@use '../lib/screen';

.reviews-slider {
  overflow: hidden;
}

.reviews-slider__summary {
  display: flex;
  align-items: center;
}

.reviews-slider__rating {
  margin-right: 4px;
  font-weight: 550;
  font-size: 16px;
  line-height: 20px;
}

.reviews-slider__count {
  display: flex;
  align-items: center;
  color: var(--color-text-aux);
}

.reviews-slider__slide {
  display: grid;
  grid-template-columns: 100%;
  width: 280px;
  height: auto;

  @include screen.from(screen.$sm) {
    width: 340px;
  }
}
