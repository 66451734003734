@use '../lib/screen';

.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-tabbar);
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  width: 100%;
  min-height: var(--size-tabbar-height);
  border-top: 1px solid var(--color-lines);
  background: var(--color-bg);

  &.is-on-top {
    z-index: var(--z-index-tabbar-on-top);
  }

  @include screen.from(screen.$md) {
    display: none;
  }
}
