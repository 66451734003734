.details-group {
  border: 1px solid var(--color-lines);
  border-radius: 8px;

  details {
    padding: 0 16px;

    &[open] {
      padding-bottom: 16px;
    }
  }

  details:not(:last-child) {
    border-bottom: 1px solid var(--color-lines);
  }

  summary {
    position: relative;
    padding-top: 16px;
    padding-right: 32px;
    padding-bottom: 16px;
    outline: none;
    list-style: none;
    font-weight: 550;
    cursor: pointer;
    user-select: none;

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      display: block;
      width: 20px;
      height: 20px;
      background-color: var(--color-grey-3);
      transform: translateY(-50%);
      mask-image: url(../svg/icons/chevron-down.svg);
      transition: transform var(--transition-in);
    }
  }

  details[open] summary {
    &::after {
      transform: translateY(-50%) scaleY(-1);
    }
  }
}
