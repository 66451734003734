.icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 100%;
  color: var(--color-grey-4);
  cursor: pointer;
  user-select: none;
  transition: color var(--transition-in), border-color var(--transition-in),
    background-color var(--transition-in);
  appearance: none;

  &:hover,
  &:focus-visible {
    background-color: var(--color-grey-05);
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }

  svg {
    width: 16px;
    height: auto;
  }
}

.icon-button--accent {
  background-color: var(--color-accent);
  color: #fff;

  &:hover,
  &:focus-visible {
    background-color: var(--color-accent-active);
  }
}

.icon-button--secondary-outline {
  border-color: var(--color-grey-1);
  background-color: var(--color-bg);
  color: var(--color-grey-3);

  &:hover,
  &:focus-visible {
    background-color: var(--color-grey-05);
  }
}

.icon-button--s {
  width: 36px;
  height: 36px;
}
