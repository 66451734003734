.image-attach {
}

.image-attach__preview {
  position: relative;
  display: grid;
  grid-template:
    'image title'
    'image info'
    / 48px minmax(0, 1fr);
  row-gap: 4px;
  align-items: center;
  padding: 12px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  column-gap: 12px;
  transition: border-color var(--color-error);

  &.has-error {
    border-color: var(--color-error);
  }
}

.image-attach__picture {
  display: block;
  overflow: hidden;
  width: 48px;
  height: 48px;
  border: 1px solid rgb(0 0 0 / 8%);
  border-radius: 4px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.image-attach__title {
  grid-area: title;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  transition: color var(--color-error);

  .image-attach__preview.has-error & {
    color: var(--color-error);
  }
}

.image-attach__info {
  grid-area: info;
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 16px;
}

.image-attach__type {
  text-transform: uppercase;
}

.image-attach__image {
  display: grid;
  grid-area: image;
  grid-template-columns: 100%;
  align-items: center;
  justify-items: center;
}

.image-attach__picture {
  grid-row: 1 / -1;
  grid-column: 1 / -1;
}

.image-attach__loader {
  // https://uiball.com/loaders/
  --loader-speed: 2s;

  grid-row: 1 / -1;
  grid-column: 1 / -1;
  width: 32px;
  height: 32px;
  transition: opacity var(--transition-in);
  transform-origin: center;
  animation: rotate var(--loader-speed) linear infinite;

  circle {
    fill: none;
    stroke: var(--color-accent);
    animation: stretch calc(var(--loader-speed) * 0.75) ease-in-out infinite;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -124px;
  }
}

.image-attach__unknown-icon {
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  color: var(--color-error);
}

.image-attach__error {
  display: block;
  margin-top: 8px;
  color: var(--color-error);
  font-size: 12px;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-color: var(--color-error);
    mask-image: url(../svg/icons/error.svg);
  }
}

.image-attach__remove {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
}

.image-attach__uploader {
  padding: 16px;
  border: 1px dashed var(--color-lines);
  border-radius: 8px;
  transition: border-color var(--transition-in);

  &:hover,
  &:focus-within {
    border-color: var(--color-accent);
  }
}

.image-attach__label {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.image-attach__icon {
  margin-bottom: 8px;
  color: var(--color-accent);
}

.image-attach__input {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
  appearance: none;
  clip-path: inset(50%);
}

.image-attach__control-group {
  display: grid;
  grid-template-columns: 1fr 94px;
  gap: 8px;
  align-items: center;
}
