@use "../lib/screen";

.cards-slider {
  overflow: hidden;
}

.cards-slider__swiper {
  overflow: visible;

  @include screen.from(screen.$md) {
    overflow: hidden;
  }
}

.cards-slider__slide {
  width: 280px;

  @include screen.from(screen.$md) {
    width: auto;
  }
}
