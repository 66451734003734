.countdown {
  color: #eee;
  font-size: 14px;
  text-align: center;
}

.countdown__number-wrapper {
  position: relative;
  display: inline;
  margin: 6px;
  border-radius: 7px;
  box-shadow: var(--shadow-z3);
}

.countdown__number {
  display: inline-block;
  height: 60px;
  padding: 0 8px;
  border: 1px solid var(--color-white-16);
  border-radius: 7px;
  background: var(--color-grey-4);
  background-clip: padding-box;
  box-shadow: inset 0 4px 0 rgb(255 255 255 / 10%);
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  text-shadow: 0 3px 3px #000;
}

.countdown__line {
  position: absolute;
  top: 52%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #000;
  box-shadow: 0 1px 0 rgb(255 255 255 / 20%);
}

.countdown__caption {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  color: var(--color-white-50);
  font-weight: 400;
  text-align: center;
}

.countdown__title {
  color: #fff;
  text-align: center;
}
