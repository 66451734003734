@use '../lib/screen';

.cookie-consent {
  position: fixed;
  right: 8px;
  bottom: calc(var(--size-tabbar-height) + 8px);
  left: 8px;
  z-index: var(--z-index-notifications);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 224px;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--color-grey-4);
  color: #fff;

  @include screen.to(screen.$md) {
    .product-page-body & {
      bottom: calc(var(--size-tabbar-height) + var(--size-buy-bar-height) + 8px);
    }
  }

  @include screen.from(450px) {
    width: 328px;
  }

  @include screen.from(screen.$md) {
    bottom: var(--size-container-sidegap);
  }
}

.cookie-consent__text {
  font-size: 12px;
  line-height: 16px;
}

.cookie-consent__button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 32px;
  margin-left: 8px;
  padding: 7px;
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 8px;
  font-weight: 550;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: border-color var(--transition-in), box-shadow var(--transition-in);
  appearance: none;

  &:focus-visible {
    box-shadow: 0 0 0 3px rgb(255 255 255 / 16%);
    outline: none;
  }

  &:hover,
  &:focus-visible {
    border-color: #fff;
  }

  @include screen.from(450px) {
    margin-left: 24px;
  }
}
