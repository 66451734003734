@use '../lib/screen';

.footer {
  background-color: var(--color-blocks);

  @include screen.to(screen.$md) {
    padding-bottom: var(--size-tabbar-height);

    .product-page-body & {
      padding-bottom: calc(var(--size-tabbar-height) + var(--size-buy-bar-height) + 0px);
    }
  }
}

.footer__inner {
  padding-top: 24px;
  padding-bottom: 16px;
}

.footer__top {
  margin-bottom: 24px;

  @include screen.from(screen.$sm) {
    display: flex;
    justify-content: space-between;
    align-self: center;
  }

  @include screen.from(screen.$md) {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-lines);
  }
}

.footer__logo {
  position: relative;
  display: block;
  color: var(--color-text-aux);
  font-size: 12px;
  font-family: var(--font-logo);
  line-height: 1.3;

  @include screen.to(screen.$sm) {
    margin-bottom: 12px;
    padding-bottom: 12px;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 64px;
      height: 1px;
      background-color: var(--color-lines);
      transform: translateX(-50%);
    }
  }
}

.footer__logo-img {
  @include screen.to(screen.$sm) {
    margin: 0 auto;
  }
}

.footer__location {
  text-align: center;

  @include screen.from(screen.$sm) {
    text-align: right;
  }
}

.footer__address {
  line-height: 1.4;
}

.footer__time {
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 1.3;
}

.footer__navigation {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-lines);

  @include screen.from(screen.$sm) {
    padding-bottom: 24px;
  }

  @include screen.from(screen.$md) {
    display: grid;
    grid-template:
      'communication buyers company' /
      6fr 3fr 3fr;
    gap: var(--size-gap);
  }
}

.footer__menu {
  &[data-footer-menu='#buyers'] {
    @include screen.from(screen.$md) {
      grid-area: buyers;
    }
  }

  &[data-footer-menu='#company'] {
    @include screen.from(screen.$md) {
      margin-bottom: 24px;
      border-bottom: 1px solid var(--color-lines);

      @include screen.from(screen.$md) {
        grid-area: company;
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}

.footer__menu--company {
  margin-bottom: 24px;
  border-bottom: 1px solid var(--color-lines);

  @include screen.from(screen.$md) {
    grid-area: company;
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.footer__menu-heading {
  @include screen.to(screen.$md) {
    position: relative;
    padding: 16px 36px 16px 0;
    border-top: 1px solid var(--color-lines);
    font-weight: 550;
    cursor: pointer;
    user-select: none;
    transition: color var(--transition-in);

    &:hover {
      color: var(--color-accent);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 20px;
      height: 20px;
      background: url(../svg/icons/chevron-down.svg) no-repeat center;
      transition: transform 0.1s ease-out;
      transform: translateY(-50%);

      .footer__menu.is-open & {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  @include screen.from(screen.$md) {
    margin-bottom: 6px;
    color: var(--color-text-aux);
  }
}

.footer__menu-list {
  @include screen.to(screen.$md) {
    .footer__menu:not(.is-open) & {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      white-space: nowrap;
      clip-path: inset(50%);
    }
  }
}

.footer__menu-link {
  display: block;
  padding: 16px 36px 16px 0;
  border-top: 1px solid var(--color-lines);

  @include screen.from(screen.$md) {
    padding: 6px 0;
    border-top: 0;
  }
}

.footer__communication {
  padding-top: 24px;
  border-top: 1px solid var(--color-lines);

  @include screen.between(screen.$sm, screen.$md) {
    display: flex;
    justify-content: space-between;
  }

  @include screen.from(screen.$md) {
    grid-area: communication;
    padding-top: 0;
    border-top: 0;
  }
}

.footer__heading {
  margin-bottom: 8px;
  color: var(--color-text-aux);
}

.footer__contacts {
  margin-bottom: 16px;
  text-align: center;

  @include screen.from(screen.$sm) {
    margin-bottom: 0;
    text-align: left;
  }

  @include screen.from(screen.$md) {
    margin-bottom: 24px;
  }
}

.footer__contacts-list {
  @include screen.from(screen.$sm) {
    display: flex;
    gap: 8px;
  }
}

.footer__contact {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 12px;
  border: 1px solid var(--color-lines);
  border-radius: 7px 8px;
  line-height: 20px;
  transition: color var(--transition-in);

  img {
    flex-shrink: 0;
    margin-right: 8px;
  }

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: 0;
  }

  @include screen.to(screen.$sm) {
    margin-bottom: 8px;
  }
}

.footer__socials {
  text-align: center;

  @include screen.from(screen.$sm) {
    text-align: right;
  }

  @include screen.from(screen.$md) {
    text-align: left;
  }
}

.footer__socials-list {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  @include screen.from(screen.$sm) {
    justify-content: flex-end;
  }

  @include screen.from(screen.$md) {
    justify-content: flex-start;
  }
}

.footer__social {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background-color: var(--color-grey-1);
  transition: color var(--transition-in);

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: 0;
  }
}

.footer__socials-tip {
  margin-top: 8px;
  color: var(--color-text-aux);
  font-size: 10px;
}

.footer__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-lines);
  color: var(--color-text-aux);
  font-size: 13px;
  line-height: 1.2;

  @include screen.from(screen.$sm) {
    flex-direction: row;
  }
}

.footer__legal {
  text-align: center;

  @include screen.from(screen.$sm) {
    text-align: left;
  }
}

.footer__copyright {
  margin-bottom: 8px;

  @include screen.from(screen.$md) {
    display: inline-block;
    margin-bottom: 0;
  }
}

.footer__policy {
  display: block;
  margin-bottom: 8px;

  @include screen.from(screen.$sm) {
    margin-bottom: 0;
  }

  @include screen.from(screen.$md) {
    display: inline-block;
    margin-left: 12px;
  }
}

.footer__paysystems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include screen.from(screen.$sm) {
    margin-left: auto;
  }
}

.footer__partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  font-size: 13px;
  line-height: 16px;

  @include screen.from(screen.$sm) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__dvebukvi {
  display: inline-flex;
  align-items: center;
  margin-bottom: 6px;
  color: var(--color-text-aux);

  img {
    margin-left: 8px;
  }

  @include screen.from(screen.$sm) {
    margin-bottom: 0;

    img {
      order: -1;
      margin-right: 8px;
      margin-left: 0;
    }
  }
}

.footer__altera {
  display: inline-flex;
  align-items: center;
  color: var(--color-text-aux);

  img {
    margin-top: 2px;
    margin-left: 8px;
  }
}
