.blog-bg-card {
  position: relative;
  display: block;
}

.blog-bg-card__category {
  position: absolute;
  top: 16px;
  left: 16px;
  display: block;
  padding: 2px 8px;
  border-radius: 999px;
  background: rgb(0 0 0 / 40%);
  color: var(--color-white);
  font-weight: 550;
  font-size: 12px;
  line-height: 16px;
}

.blog-bg-card__views {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 999px;
  background: rgb(0 0 0 / 40%);
  color: var(--color-white);
  font-weight: 550;
  font-size: 12px;
  line-height: 16px;

  .i {
    margin-right: 4px;
  }
}

.blog-bg-card__link {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
  background: var(--color-blocks);
  isolation: isolate;

  &::before {
    content: '';
    display: block;
    padding-top: calc(239 / 324 * 100%);
  }
}

.blog-bg-card__bg {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 0%,
      rgb(0 0 0 / 1.03%) 13%,
      rgb(0 0 0 / 3.94%) 23.69%,
      rgb(0 0 0 / 8.5%) 32.36%,
      rgb(0 0 0 / 14.4%) 39.32%,
      rgb(0 0 0 / 21.5%) 44.86%,
      rgb(0 0 0 / 29.5%) 49.29%,
      rgb(0 0 0 / 38.1%) 52.92%,
      rgb(0 0 0 / 47.1%) 56.03%,
      rgb(0 0 0 / 56.3%) 58.94%,
      rgb(0 0 0 / 65.3%) 61.94%,
      rgb(0 0 0 / 73.9%) 65.33%,
      rgb(0 0 0 / 82%) 69.42%,
      rgb(0 0 0 / 89.2%) 74.51%,
      rgb(0 0 0 / 95.3%) 80.9%,
      #000 88.89%
    );
    opacity: 0.75;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in;

    .blog-bg-card:hover &,
    .blog-bg-card:focus-visible & {
      transform: scale(1.05);
    }
  }
}

.blog-bg-card__body {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.blog-bg-card__time {
  display: block;
  margin-top: auto;
  margin-bottom: 4px;
  color: var(--color-white);
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
}

.blog-bg-card__title {
  display: block;
  color: var(--color-white);
  font-weight: 550;
  font-size: 16px;
  line-height: 20px;
}
