@use '../lib/screen';

.favorite-button {
  flex: 0 0 auto;
  position: relative;
  display: block;
  width: 36px;
  height: 36px;
  margin-left: 4px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  transition: background-color var(--transition-in);
  appearance: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 7px;
    display: block;
    width: 20px;
    height: 20px;
    transition: background-color var(--transition-in);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }

  &::before {
    background-color: var(--color-white);
    mask-image: url(../svg/favorite-button-heart-bg.svg);
  }

  &::after {
    background-color: var(--color-black);
    mask-image: url(../svg/favorite-button-heart-outline.svg);
  }

  &:hover,
  &:focus-visible {
    background: var(--color-grey-05);

    &::before {
      background: var(--color-grey-05);
    }
  }

  &.is-active {
    &::before,
    &::after {
      background-color: var(--color-accent);
    }
  }

  @include screen.from(screen.$md) {
    width: 44px;
    height: 44px;
    padding: 12px;
    margin-left: 8px;

    &::before,
    &::after {
      top: 11px;
      left: 11px;
    }
  }
}
