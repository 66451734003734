@use '../lib/screen';

.reviews-summary {
  padding: 16px;
  border-radius: 8px;
  background: var(--color-blocks);

  @include screen.from(screen.$sm) {
    padding: 24px;
  }
}

.reviews-summary__heading {
  margin-bottom: 12px;
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
}

.reviews-summary__info {
  display: grid;
  grid-template:
    'rating stars' 1fr
    'rating text' 1fr
    / max-content minmax(0, 1fr);
  row-gap: 6px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-lines);
  column-gap: 12px;
}

.reviews-summary__rating {
  grid-area: rating;
  font-weight: 550;
  font-style: normal;
  font-size: 48px;
  line-height: 100%; /* 48px */
  letter-spacing: -1.92px;
}

.reviews-summary__stars {
  display: flex;
  grid-area: stars;
  align-self: end;
}

.reviews-summary__star {
  color: var(--color-grey-2);

  &.is-active {
    color: var(--color-yellow);
  }
}

.reviews-summary__text {
  grid-area: text;
  align-self: start;
  color: var(--color-text-aux);
}

.reviews-summary__bars {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) max-content;
  gap: 8px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-lines);
  color: var(--color-text-aux);
}

.reviews-summary__bar {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 999px;
    background: var(--color-grey-1);
    transform: translateY(-50%);
  }

  &::after {
    background: var(--color-yellow);
    clip-path: polygon(0 0, var(--fraction) 0, var(--fraction) 100%, 0 100%);
  }
}
