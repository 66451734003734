.image-uploader {
  display: flex;
  align-items: center;
}

.image-uploader__input {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
  appearance: none;
  clip-path: inset(50%);
}

.image-uploader__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  border: 1px dashed var(--color-grey-1);
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: border-color var(--transition-in);

  &:hover,
  &:focus-visible {
    border-color: var(--color-accent);
  }
}

.image-uploader__uploaded {
  overflow: hidden;
  width: 96px;
  height: 96px;
  border: 1px dashed var(--color-grey-1);
  border-radius: 8px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
}
