@use '../lib/screen';

.product-params {
}

.product-params__row {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.product-params__name {
  float: left;
  margin-right: 8px;
  color: var(--color-text-aux);
}

.product-params__value {
}
