@use '../lib/screen';

.checkout-status {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 24px 0 96px;

  @include screen.from(screen.$sm) {
    padding-top: 32px;
  }
}

.checkout-status__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  text-align: center;
}

.checkout-status__icon {
  margin-bottom: 16px;
}

.checkout-status__title {
  margin-bottom: 4px;
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
}

.checkout-status__params {
  border: 1px solid var(--color-lines);
  border-radius: 8px;
}

.checkout-status__section {
  padding: 12px 16px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-lines);
  }

  @include screen.from(screen.$sm) {
    display: flex;
    flex-wrap: wrap;
  }
}

.checkout-status__name {
  margin-bottom: 4px;
  color: var(--color-text-aux);

  @include screen.from(screen.$sm) {
    width: 160px;
    margin-right: 8px;
  }
}

.checkout-status__value {
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  @include screen.from(screen.$sm) {
    width: calc(100% - 160px - 8px);
  }
}

.checkout-status__product-item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.checkout-status__product {
  display: flex;
}

.checkout-status__product-image {
  position: relative;
  display: block;
  flex-shrink: 0;
  overflow: hidden;
  width: 56px;
  height: 56px;
  margin-right: 8px;
  border-radius: 8px;
  background: var(--color-blocks) url(../svg/product-bg-sm.svg) no-repeat center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.checkout-status__product-title {
  margin-bottom: 4px;
  font-size: 13px;
  transition: color var(--transition-in);

  .checkout-status__product:hover &,
  .checkout-status__product:focus-visible & {
    color: var(--color-accent);
  }
}

.checkout-status__product-prices {
  display: flex;
  align-items: center;
}

.checkout-status__product-price {
  font-weight: 550;
  font-size: 14px;

  &.is-discount {
    color: var(--color-marketing);
  }
}

.checkout-status__product-old-price {
  margin-left: 4px;
  padding-top: 2px;
  color: var(--color-grey-3);
  font-size: 11px;
  line-height: 16px;
  text-decoration: line-through;
}
