@use '../lib/screen';

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-dialog);
  display: flex;
}

.dialog[aria-hidden='true'] {
  display: none;
}

.dialog__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0 0 0 / 50%);
  animation: fade-in 200ms both;
}

.dialog__body {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-height: 100%;
  background-color: rgb(255 255 255);
  animation: fade-in 400ms 200ms both, slide-up 400ms 200ms both;

  @include screen.from(screen.$md) {
    width: 384px;
    max-width: 90%;
    margin: auto;
    border-radius: 16px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 8%);
    filter: drop-shadow(0 0 12px rgb(0 0 0 / 12%));
  }
}

.dialog__fieldset {
  opacity: 1;
  transition: opacity var(--transition-in);

  &.is-submitting {
    opacity: 0.5;
  }
}

.dialog__content {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  width: var(--size-container-width);
  margin: 0 auto;
  padding: 16px var(--size-container-sidegap);

  @include screen.from(screen.$sm) {
    padding: 24px var(--size-container-sidegap);
  }

  @include screen.from(screen.$md) {
    width: 100%;
    margin: 0;
    padding: 24px;
  }
}

.dialog__panel {
  padding: 16px var(--size-container-sidegap);
  border-top: 1px solid var(--color-lines);

  &.has-buttons {
    display: flex;
    justify-content: space-between;
  }

  @include screen.from(screen.$sm) {
    padding: 24px var(--size-container-sidegap);
  }

  @include screen.from(screen.$md) {
    padding: 16px 24px;
  }
}

.dialog__success,
.dialog__failure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg);
  text-align: center;
}

.dialog__title {
  display: flex;
  align-items: center;
  min-height: 56px;
  padding-left: 16px;
  border-bottom: 1px solid var(--color-lines);
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;

  @include screen.from(screen.$sm) {
    padding-left: 24px;
  }

  @include screen.from(screen.$md) {
    min-height: 64px;
  }
}

.dialog__close {
  position: absolute;
  top: 8px;
  right: 8px;

  @include screen.from(screen.$sm) {
    right: 16px;
  }

  @include screen.from(screen.$md) {
    top: 12px;
    right: 12px;
  }
}

.dialog--is-mobile-panel {
  @include screen.to(screen.$md) {
    .dialog__body {
      height: max-content;
      margin-top: auto;
      border-radius: 16px 16px 0 0;
    }
  }
}

.dialog--has-small-heading {
  .dialog__title {
    border-bottom: none;
  }

  .dialog__content {
    padding-top: 0;
  }
}

.dialog--medium {
  .dialog__body {
    @include screen.from(screen.$md) {
      width: 512px;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}
