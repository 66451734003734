@use '../lib/screen';

.editor-content {
  display: grid;
  grid-template-columns: 100%;

  b,
  strong {
    font-weight: 550;
  }

  a {
    color: var(--color-accent);
    transition: color var(--transition-in);

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }

    &:focus-visible {
      outline-width: 2px;
    }
  }

  p {
    margin-top: 8px;
    margin-bottom: 4px;
  }

  ul,
  ol {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  ul:not([class]) li {
    position: relative;
    padding-left: 24px;

    &::before {
      content: '';
      position: absolute;
      top: 7px;
      left: 6px;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: var(--color-accent);
    }
  }

  ol:not([class]) {
    counter-reset: list;

    li {
      position: relative;
      padding-left: 24px;
      counter-increment: list;

      &::before {
        content: counters(list, '.');
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        padding: 0 6px;
        color: var(--color-accent);
        font-weight: 550;
      }
    }
  }

  h1 {
    margin-top: 24px;
    margin-bottom: 8px;
    font-weight: 550;
    font-size: 22px;
    line-height: 1.25;

    @include screen.from(screen.$sm) {
      font-size: 24px;
      line-height: 1.35;
    }
  }

  h2 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 550;
    font-size: 20px;
    line-height: 1.25;

    @include screen.from(screen.$sm) {
      font-size: 22px;
      line-height: 1.35;
    }
  }

  h3 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 550;
    font-size: 18px;
    line-height: 1.25;

    @include screen.from(screen.$sm) {
      font-size: 20px;
      line-height: 1.35;
    }
  }

  h4 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 550;
    font-size: 16px;
    line-height: 1.25;

    @include screen.from(screen.$sm) {
      font-size: 18px;
      line-height: 1.35;
    }
  }

  iframe {
    overflow: hidden;
    margin: 16px 0;
    border-radius: 8px;
  }

  figure {
    margin: 16px 0;

    img {
      display: block;
      overflow: hidden;
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  figcaption {
    margin-top: 12px;
    color: var(--color-text-aux);
    font-size: 12px;
  }

  figure,
  picture {
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  [class='wp-block-table'] {
    table {
      overflow: hidden;
      width: 100%;
      border-spacing: 0;
      border-collapse: separate;
      border-radius: 8px;
      table-layout: fixed;

      td,
      th {
        vertical-align: top;
        padding: 8px;
        border-right: 1px solid var(--color-lines);
        border-bottom: 1px solid var(--color-lines);

        & > :first-child {
          margin-top: 0;
        }

        & > :last-child {
          margin-bottom: 0;
        }
      }

      th {
        background-color: var(--color-blocks);
        font-weight: 550;
        text-align: center;
      }

      td:first-child,
      th:first-child {
        border-left: 1px solid var(--color-lines);
      }

      tr:first-child {
        th,
        td {
          border-top: 1px solid var(--color-lines);
        }

        th {
          padding: 16px 8px;
          text-align: center;
        }

        th:first-child,
        td:first-child {
          border-top-left-radius: 8px;
        }

        th:last-child,
        td:last-child {
          border-top-right-radius: 8px;
        }
      }

      tr:not(:first-child) th {
        width: max-content;
        text-align: right;
      }

      tbody tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
      }

      tbody tr:last-child td:last-child {
        border-bottom-right-radius: 8px;
      }

      caption {
        margin-top: 12px;
        color: var(--color-text-aux);
        caption-side: bottom;
        font-size: 12px;
        text-align: left;
      }
    }

    @include screen.to(542px) {
      display: flex;
      overflow-x: auto;
      width: 100vw;
      margin-left: 50%;
      transform: translateX(-50%);

      &::before,
      &::after {
        content: '';
        display: block;
        flex-shrink: 0;
        width: var(--size-container-sidegap);
      }

      table {
        min-width: 510px;
      }
    }
  }

  .wp-block-table.is-style-with-line table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;

    tr {
      position: relative;

      &:not(:first-child) {
        td {
          padding-top: 8px;
        }
      }
    }

    td {
      padding: 0;

      &:first-child {
        span {
          padding-right: 4px;
          background-color: var(--color-bg);

          &::after {
            content: '';
            position: absolute;
            bottom: 0.4em;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 3px;
            background: url(../svg/dots.svg) repeat-x left center;
          }
        }
      }

      &:last-child {
        vertical-align: bottom;
        padding: 0;
        text-align: right;

        span {
          display: inline-block;
          padding-left: 4px;
          background-color: var(--color-bg);
          white-space: nowrap;
        }
      }
    }
  }

  .wp-block-gallery.columns-default,
  .wp-block-gallery.columns-3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
