@use '../lib/screen';

.catalog-menu {
  --catalog-menu-topbar-height: 56px;

  @include screen.to(screen.$md) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);

    &.is-transition-enter {
      transition: transform 0.2s var(--ease-in-out-quart);
    }

    &.is-transition-enter-start {
      transform: translateX(-100%);
    }

    &.is-transition-enter-end {
      transform: none;
    }

    &.is-transition-leave {
      transition: transform 0.1s var(--ease-in-out-quart);
    }

    &.is-transition-leave-start {
      transform: none;
    }

    &.is-transition-leave-end {
      transform: translateX(-100%);
    }
  }

  @include screen.from(screen.$sm) {
    --catalog-menu-topbar-height: 64px;
  }

  @include screen.from(screen.$md) {
    position: relative;
    display: block !important; // overrides Alpine's x-show
    border-bottom: 1px solid var(--color-grey-1);
    background-color: var(--color-bg);
  }
}

.catalog-menu__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: var(--size-tabbar-height);

  @include screen.from(screen.$md) {
    display: block;
    width: 100%;
    max-width: var(--size-container-width);
    margin: auto;
    padding: 0 var(--size-container-sidegap);
  }
}

.catalog-menu__mobile-topbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--catalog-menu-topbar-height);
  padding: 8px var(--size-container-sidegap);
  border-bottom: 1px solid var(--color-lines);

  @include screen.from(screen.$md) {
    display: none;
  }
}

.catalog-menu__search {
  width: 100%;
  min-height: 40px;
  margin-right: calc(41px + 8px);
  padding: 9px 16px;
  border: 1px solid var(--color-grey-1);
  border-radius: 999px;
  color: var(--color-grey-3);
  font-size: 14px;
  line-height: 1.3;
  text-align: left;
  cursor: text;
  appearance: none;
}

.catalog-menu__mobile-heading {
  width: 100%;
  font-weight: 550;
  text-align: center;
}

.catalog-menu__back {
  position: absolute;
  top: 8px;
  left: 8px;

  @include screen.from(screen.$sm) {
    top: 12px;
    left: 16px;
  }
}

.catalog-menu__close {
  position: absolute;
  top: 8px;
  right: 8px;

  @include screen.from(screen.$sm) {
    top: 12px;
    right: 16px;
  }
}

.catalog-menu__list {
  @include screen.to(screen.$md) {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    padding: 8px var(--size-container-sidegap);
  }

  @include screen.from(screen.$md) {
    display: flex;
    align-items: center;
    margin-bottom: -1px;
    margin-left: -8px;
  }
}

.catalog-menu__item {
}

.catalog-menu__link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 52px;
  padding: 14px 0;
  line-height: 1.2;
  transition: color var(--transition-in);

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &.catalog-menu__link--is-special {
    padding: 6px 0;
  }

  .i {
    flex-shrink: 0;
    margin-right: 8px;
  }

  @include screen.to(screen.$md) {
    .catalog-menu__item.has-subsections &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -5px;
      width: 20px;
      height: 20px;
      background-color: var(--color-grey-3);
      transform: translateY(-50%);
      mask-image: url(../svg/icons/chevron-right.svg);
    }

    .catalog-menu__item:not(:last-child) & {
      border-bottom: 1px solid var(--color-lines);
    }
  }

  @include screen.from(screen.$md) {
    min-height: 53px;
    padding: 0 8px;
    border-bottom: 2px solid transparent;

    .catalog-menu__item:hover &,
    .catalog-menu__item:focus-within &:not(:focus) {
      color: var(--color-accent);
      outline: none;
    }

    .catalog-menu__item.has-subsections:hover &,
    .catalog-menu__item.has-subsections:focus-within &:not(:focus) {
      border-color: var(--color-accent);
    }

    &.catalog-menu__link--is-special {
      padding: 0 4px;
    }
  }

  @include screen.from(screen.$lg) {
    padding: 0 12px;
  }
}

.catalog-menu__link-inner {
  display: flex;
  align-items: center;

  .catalog-menu__link--is-special & {
    margin-left: -8px;
    padding: 8px;
    border-radius: 999px;
    background-color: var(--color-grey-05);

    @include screen.from(screen.$md) {
      margin-left: 0;
    }
  }
}

.catalog-menu__item-inner {
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 0 8px;
  border-radius: 999px;
  background-color: var(--color-grey-05);
}

.catalog-menu__submenu {
  background: var(--color-bg);

  @include screen.to(screen.$md) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);

    &.is-transition-enter {
      transition: transform 0.2s var(--ease-in-out-quart);
    }

    &.is-transition-enter-start {
      transform: translateX(100%);
    }

    &.is-transition-enter-end {
      transform: none;
    }

    &.is-transition-leave {
      transition: transform 0.1s var(--ease-in-out-quart);
    }

    &.is-transition-leave-start {
      transform: none;
    }

    &.is-transition-leave-end {
      transform: translateX(100%);
    }
  }

  @include screen.from(screen.$md) {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    display: block !important;
    width: 100%;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 50%;
      background-color: white;
      box-shadow: 0 0 12px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 8%);
    }
  }
}

.catalog-menu__overlay {
  @include screen.from(screen.$md) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-color: var(--color-overlay);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s var(--ease-in-out-quart), visibility 0.15s var(--ease-in-out-quart);

    .catalog-menu__item.has-subsections:hover ~ & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.catalog-menu__tabs {
  display: block;
  background: var(--color-grey-05);
}

.catalog-menu__tab {
  position: relative;
  padding: 16px 8px;
  border: 0;
  border-radius: 0;
  background: transparent;
  outline: none;
  white-space: nowrap;
  transition: background-color var(--transition-in);
  appearance: none;

  // for active text not changing tab width
  &::after {
    z-index: 1;
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px 8px;
    color: var(--color-text-main);
    font-weight: 550;
    letter-spacing: -0.02em;
    text-align: center;
  }

  &[aria-selected='true'] {
    background: var(--color-grey-1);
    color: transparent;

    &::after {
      display: flex;
    }
  }

  &:hover,
  &:focus-visible {
    background: var(--color-grey-1);
  }
}

.catalog-menu__tabs-list {
  display: flex;
}

.catalog-menu__submenu-container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding: 16px var(--size-container-sidegap) var(--size-tabbar-height);

  &[hidden] {
    display: none;
  }

  @include screen.from(screen.$md) {
    display: grid;
    grid-template-rows: auto;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 32px;
    overflow-y: visible;
    width: var(--size-container-width);
    margin: 0 auto;
    padding: 24px var(--size-container-sidegap);
  }

  @include screen.from(screen.$lg) {
    gap: 40px;
  }
}

.catalog-menu__sidebar {
  @include screen.from(screen.$md) {
    flex-shrink: 0;
    width: 200px;
  }

  @include screen.from(screen.$lg) {
    width: 240px;
  }
}

.catalog-menu__seeall-mobile {
  width: 100%;
  margin-bottom: 16px;

  @include screen.from(screen.$md) {
    display: none;
  }
}

.catalog-menu__seeall-desktop {
  display: none;

  @include screen.from(screen.$md) {
    display: block;
    padding: 10px;
    border-radius: 10px;
    background: var(--color-grey-05);
    font-size: 16px;
    transition: var(--transition-in);

    &:hover,
    &:focus-visible {
      background: var(--color-grey-1);
    }
  }
}

.catalog-menu__popular {
  @include screen.from(screen.$md) {
    margin-bottom: 16px;
    padding: 10px 10px 6px;
    border-radius: 10px;
    background: #ffeaea;
  }
}

.catalog-menu__group {
  @include screen.to(screen.$md) {
    &.has-empty-heading {
      order: 1;
    }
  }

  @include screen.from(screen.$md) {
    flex: 0 0 auto;
  }
}

.catalog-menu__group-heading {
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid var(--color-lines);
  border-radius: 0;
  background: transparent;
  outline: 0;
  font-weight: 550;
  text-align: left;
  appearance: none;

  @include screen.to(screen.$md) {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -5px;
      width: 20px;
      height: 20px;
      background-color: var(--color-grey-3);
      transform: translateY(-50%);
      mask-image: url(../svg/icons/chevron-right.svg);
    }

    &[aria-expanded='true'] {
      &::after {
        transform: translateY(-50%) rotate(90deg);
      }
    }

    &:not(.is-empty)[aria-expanded='false'] + .catalog-menu__sublist,
    &:not(.is-empty)[aria-expanded='false'] + .catalog-menu__popular-sublist {
      display: none;
    }

    &.is-empty {
      display: none;
    }
  }

  @include screen.from(screen.$md) {
    margin-bottom: 12px;
    padding: 0;
    border-bottom: 0;
    font-size: 16px;
  }
}

.catalog-menu__group-icon {
  margin-left: 8px;
  color: var(--color-accent);
}

.catalog-menu__sublist {
  @include screen.from(screen.$md) {
    display: flex;
    flex-flow: column wrap;
    width: var(--masonry-container-width); // see: components/catalogMenu.ts
    height: 450px;
  }

  @include screen.from(screen.$lg) {
    height: 380px;
  }
}

.catalog-menu__subitem {
  @include screen.from(screen.$md) {
    max-width: 165px;
    margin-bottom: 4px;
    padding-right: 20px;

    .catalog-menu__popular & {
      max-width: 100%;
    }
  }
}

.catalog-menu__sublink {
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 0;
  line-height: 20px;

  &.is-popular {
    display: flex;
    align-items: center;

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin-left: 4px;
      background-color: var(--color-accent);
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
      mask-image: url(../svg/icons/fire-20.svg);
    }
  }

  @include screen.to(screen.$md) {
    border-bottom: 1px solid var(--color-lines);

    .catalog-menu__subsection:last-child .catalog-menu__subitem:last-child & {
      border-bottom: 0;
    }
  }

  @include screen.from(screen.$md) {
    display: inline-block;
    padding: 2px 0;
  }
}

// submenu overrides
// stylelint-disable no-duplicate-selectors
.catalog-menu__submenu-container {
  @include screen.from(screen.$md) {
    &[data-submenu='bukety'] {
      .catalog-menu__sublist {
        height: 378px;
      }
    }

    &[data-submenu='korziny-s-tsvetami'],
    &[data-submenu='vip-uslugi'] {
      .catalog-menu__subitem {
        max-width: 100%;
      }
    }

    &[data-submenu='stabilizirovannye-tsvety'] {
      .catalog-menu__subitem {
        max-width: 200px;
      }
    }
  }
}
