@use '../lib/screen';

.input {
  position: relative;
  display: block;
}

.input__label {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
  color: var(--color-text-aux);
  pointer-events: none;
  user-select: none;
  transition: transform 0.2s var(--ease-in-out-quart);
  transform: none;
  transform-origin: left center;

  .input.is-focused & {
    transform: translate(1px, -14px) scale(0.8);
  }
}

.input__max-length {
  position: absolute;
  right: 16px;
  bottom: 16px;
  color: var(--color-text-aux);
  font-weight: 350;
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
}

.input__control {
  width: 100%;
  min-height: 52px;
  padding: 7px 23px 7px 16px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  background: var(--color-bg);
  font-size: 14px;
  line-height: 1.4;
  resize: none;
  transition: border-color var(--transition-in), box-shadow var(--transition-in),
    opacity var(--transition-in);
  appearance: none;

  &:not(:disabled):hover {
    border-color: var(--color-accent);
  }

  &:not(:disabled):focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 3px rgb(193 35 37 / 16%);
  }

  &:disabled {
    opacity: 0.8;
  }

  &:focus-visible {
    outline: 0;
  }

  &::-ms-clear {
    display: none;
  }

  .input__label + & {
    padding-top: 16px;
  }

  .input.has-error & {
    border-color: var(--color-error);

    &:focus {
      box-shadow: var(--shadow-error);
    }
  }

  .input.is-success & {
    border-color: var(--color-success);

    &:focus {
      box-shadow: var(--shadow-success);
    }
  }

  @include screen.from(screen.$md) {
    font-size: 14px;
  }
}

.input__control--s {
  min-height: 36px;
  padding: 7px 12px;
}

.input__icon {
  position: absolute;
  top: 16px;
  right: 16px;
  pointer-events: none;
  user-select: none;
}

.input__error {
  display: block;
  margin-top: 8px;
  color: var(--color-error);
  font-size: 12px;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-color: var(--color-error);
    mask-image: url(../svg/icons/error.svg);
  }
}

.input__success {
  display: block;
  margin-top: 8px;
  color: var(--color-success);
  font-size: 12px;
}
