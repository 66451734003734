.breadcrumbs {
  overflow: hidden;
}

.breadcrumbs__list {
  display: flex;
  align-items: center;
}

.breadcrumbs__swiper {
  overflow: visible;
}

.breadcrumbs__link {
  position: relative;
  display: block;
  padding: 16px 20px 16px 4px;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 16px;
    height: 16px;
    background-color: var(--color-grey-3);
    transform: translateY(-50%);
    mask-image: url(../svg/icons/chevron-right-16.svg);

  }

  .breadcrumbs__item:first-child & {
    display: flex;
    align-items: center;
    padding-left: 0;
  }

  .breadcrumbs__item:last-child &::after {
    display: none;
  }
}
