:root {
  --ts-pr-clear-button: 0;
  --ts-pr-caret: 0;
  --ts-pr-min: .75rem;
}

.ts-wrapper.single .ts-control, .ts-wrapper.single .ts-control input {
  cursor: pointer;
}

.ts-control {
  padding-right: max(var(--ts-pr-min), var(--ts-pr-clear-button)  + var(--ts-pr-caret)) !important;
}

.ts-wrapper.plugin-drag_drop.multi > .ts-control > div.ui-sortable-placeholder {
  box-shadow: inset 0 0 12px 4px #fff;
  visibility: visible !important;
  background: #0000000f !important;
  border: 0 !important;
}

.ts-wrapper.plugin-drag_drop .ui-sortable-placeholder:after {
  content: "!";
  visibility: hidden;
}

.ts-wrapper.plugin-drag_drop .ui-sortable-helper {
  box-shadow: 0 2px 5px #0003;
}

.plugin-checkbox_options .option input {
  margin-right: .5rem;
}

.plugin-clear_button {
  --ts-pr-clear-button: 1em;
}

.plugin-clear_button .clear-button {
  opacity: 0;
  cursor: pointer;
  transition: opacity .5s;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  background: none !important;
  margin-right: 0 !important;
}

.plugin-clear_button.form-select .clear-button, .plugin-clear_button.single .clear-button {
  right: max(var(--ts-pr-caret), 8px);
}

.plugin-clear_button.focus.has-items .clear-button, .plugin-clear_button:not(.disabled):hover.has-items .clear-button {
  opacity: 1;
}

.ts-wrapper .dropdown-header {
  background: #f8f8f8;
  border-bottom: 1px solid #d0d0d0;
  border-radius: 3px 3px 0 0;
  padding: 10px 8px;
  position: relative;
}

.ts-wrapper .dropdown-header-close {
  color: #303030;
  opacity: .4;
  margin-top: -12px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  right: 8px;
  font-size: 20px !important;
}

.ts-wrapper .dropdown-header-close:hover {
  color: #000;
}

.plugin-dropdown_input.focus.dropdown-active .ts-control {
  box-shadow: none;
  border: 1px solid #d0d0d0;
}

.plugin-dropdown_input .dropdown-input {
  background: none;
  border: 0 solid #d0d0d0;
  border-bottom-width: 1px;
  width: 100%;
  padding: 8px;
  display: block;
  box-shadow: inset 0 1px 1px #0000001a;
}

.plugin-dropdown_input .items-placeholder {
  width: 100%;
  box-shadow: none !important;
  border: 0 !important;
}

.plugin-dropdown_input.has-items .items-placeholder, .plugin-dropdown_input.dropdown-active .items-placeholder {
  display: none !important;
}

.ts-wrapper.plugin-input_autogrow.has-items .ts-control > input {
  min-width: 0;
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input {
  flex: none;
  min-width: 4px;
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::placeholder {
  color: #0000;
}

.ts-dropdown.plugin-optgroup_columns .ts-dropdown-content {
  display: flex;
}

.ts-dropdown.plugin-optgroup_columns .optgroup {
  border-top: 0;
  border-right: 1px solid #f2f2f2;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

.ts-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0;
}

.ts-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}

.ts-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0;
}

.ts-wrapper.plugin-remove_button .item {
  align-items: center;
  display: inline-flex;
  padding-right: 0 !important;
}

.ts-wrapper.plugin-remove_button .item .remove {
  color: inherit;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 0 2px 2px 0;
  padding: 0 6px;
  text-decoration: none;
  display: inline-block;
}

.ts-wrapper.plugin-remove_button .item .remove:hover {
  background: #0000000d;
}

.ts-wrapper.plugin-remove_button.disabled .item .remove:hover {
  background: none;
}

.ts-wrapper.plugin-remove_button .remove-single {
  font-size: 23px;
  position: absolute;
  top: 0;
  right: 0;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
  border-left: 1px solid #0073bb;
  margin-left: 6px;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item.active .remove {
  border-left-color: #00578d;
}

.ts-wrapper.plugin-remove_button:not(.rtl).disabled .item .remove {
  border-left-color: #aaa;
}

.ts-wrapper.plugin-remove_button.rtl .item .remove {
  border-right: 1px solid #0073bb;
  margin-right: 6px;
}

.ts-wrapper.plugin-remove_button.rtl .item.active .remove {
  border-right-color: #00578d;
}

.ts-wrapper.plugin-remove_button.rtl.disabled .item .remove {
  border-right-color: #aaa;
}

.ts-wrapper {
  position: relative;
}

.ts-dropdown, .ts-control, .ts-control input {
  color: #303030;
  font-smoothing: inherit;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
}

.ts-control, .ts-wrapper.single.input-active .ts-control {
  cursor: text;
  background: #fff;
}

.ts-control {
  z-index: 1;
  box-sizing: border-box;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 1px 1px #0000001a;
}

.ts-wrapper.multi.has-items .ts-control {
  padding: 5px 8px 2px;
}

.full .ts-control {
  background-color: #fff;
}

.disabled .ts-control, .disabled .ts-control * {
  cursor: default !important;
}

.focus .ts-control {
  box-shadow: inset 0 1px 2px #00000026;
}

.ts-control > * {
  vertical-align: baseline;
  display: inline-block;
}

.ts-wrapper.multi .ts-control > div {
  cursor: pointer;
  color: #fff;
  background: #1da7ee;
  border: 1px solid #0073bb;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
}

.ts-wrapper.multi .ts-control > div.active {
  color: #fff;
  background: #92c836;
  border: 1px solid #00578d;
}

.ts-wrapper.multi.disabled .ts-control > div, .ts-wrapper.multi.disabled .ts-control > div.active {
  color: #fff;
  background: #d2d2d2;
  border: 1px solid #aaa;
}

.ts-control > input {
  flex: auto;
  min-width: 7rem;
  text-indent: 0 !important;
  line-height: inherit !important;
  -webkit-user-select: auto !important;
  user-select: auto !important;
  box-shadow: none !important;
  background: none !important;
  border: 0 !important;
  max-width: 100% !important;
  min-height: 0 !important;
  max-height: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block !important;
}

.ts-control > input::-ms-clear {
  display: none;
}

.ts-control > input:focus {
  outline: none !important;
}

.has-items .ts-control > input {
  margin: 0 4px !important;
}

.ts-control.rtl {
  text-align: right;
}

.ts-control.rtl.single .ts-control:after {
  left: 15px;
  right: auto;
}

.ts-control.rtl .ts-control > input {
  margin: 0 4px 0 -2px !important;
}

.disabled .ts-control {
  opacity: .5;
  background-color: #fafafa;
}

.input-hidden .ts-control > input {
  opacity: 0;
  position: absolute;
  left: -10000px;
}

.ts-dropdown {
  z-index: 10;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #d0d0d0;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  width: 100%;
  margin: .25rem 0 0;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 1px 3px #0000001a;
}

.ts-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}

.ts-dropdown [data-selectable] .highlight {
  background: #7da8d033;
  border-radius: 1px;
}

.ts-dropdown .option, .ts-dropdown .optgroup-header, .ts-dropdown .no-results, .ts-dropdown .create {
  padding: 5px 8px;
}

.ts-dropdown .option, .ts-dropdown [data-disabled], .ts-dropdown [data-disabled] [data-selectable].option {
  cursor: inherit;
  opacity: .5;
}

.ts-dropdown [data-selectable].option {
  opacity: 1;
  cursor: pointer;
}

.ts-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0;
}

.ts-dropdown .optgroup-header {
  color: #303030;
  cursor: default;
  background: #fff;
}

.ts-dropdown .active {
  color: #495c68;
  background-color: #f5fafd;
}

.ts-dropdown .active.create {
  color: #495c68;
}

.ts-dropdown .create {
  color: #30303080;
}

.ts-dropdown .spinner {
  width: 30px;
  height: 30px;
  margin: 5px 8px;
  display: inline-block;
}

.ts-dropdown .spinner:after {
  content: " ";
  border: 5px solid #d0d0d0;
  border-color: #d0d0d0 #0000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 3px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ts-dropdown-content {
  overflow-scrolling: touch;
  scroll-behavior: smooth;
  max-height: 200px;
  overflow: hidden auto;
}

.ts-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.ts-wrapper.single .ts-control {
  --ts-pr-caret: 2rem;
}

.ts-wrapper.single .ts-control:after {
  content: " ";
  border: 5px solid #0000;
  border-top-color: gray;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-top: -3px;
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
}

.ts-wrapper.single.dropdown-active .ts-control:after {
  border-width: 0 5px 5px;
  border-color: #0000 #0000 gray;
  margin-top: -4px;
}

.ts-wrapper.single.input-active .ts-control, .ts-wrapper.single.input-active .ts-control input {
  cursor: text;
}

.ts-wrapper {
  min-height: 36px;
  display: flex;
}

.ts-wrapper.multi.has-items .ts-control {
  --ts-pr-min: $ padding-x;
  padding-left: 5px;
}

.ts-wrapper.multi .ts-control [data-value] {
  text-shadow: 0 1px #0033534d;
  background-color: #1b9dec;
  background-image: linear-gradient(#1da7ee, #178ee9);
  background-repeat: repeat-x;
  border-radius: 3px;
  box-shadow: 0 1px #0003, inset 0 1px #ffffff08;
}

.ts-wrapper.multi .ts-control [data-value].active {
  background-color: #0085d4;
  background-image: linear-gradient(#008fd8, #0075cf);
  background-repeat: repeat-x;
}

.ts-wrapper.multi.disabled .ts-control [data-value] {
  color: #999;
  text-shadow: none;
  box-shadow: none;
  background: none;
}

.ts-wrapper.multi.disabled .ts-control [data-value], .ts-wrapper.multi.disabled .ts-control [data-value] .remove {
  border-color: #e6e6e6;
}

.ts-wrapper.multi.disabled .ts-control [data-value] .remove {
  background: none;
}

.ts-wrapper.single .ts-control {
  background-color: #f9f9f9;
  background-image: linear-gradient(#fefefe, #f2f2f2);
  background-repeat: repeat-x;
  box-shadow: 0 1px #0000000d, inset 0 1px #fffc;
}

.ts-wrapper.single .ts-control, .ts-dropdown.single {
  border-color: #b8b8b8;
}

.dropdown-active .ts-control {
  border-radius: 3px 3px 0 0;
}

.ts-dropdown .optgroup-header {
  padding-top: 7px;
  font-size: .85em;
  font-weight: bold;
}

.ts-dropdown .optgroup {
  border-top: 1px solid #f0f0f0;
}

.ts-dropdown .optgroup:first-child {
  border-top: 0;
}

@font-face {
  font-weight: 200 1000;
  font-style: normal;
  font-stretch: 100%;
  font-family: Roboto Flex;
  src: url("roboto-flex_cyrillic-ext_variable.48334503.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-weight: 200 1000;
  font-style: normal;
  font-stretch: 100%;
  font-family: Roboto Flex;
  src: url("roboto-flex_latin-ext_variable.52f520dd.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-weight: 200 1000;
  font-style: normal;
  font-stretch: 100%;
  font-family: Roboto Flex;
  src: url("roboto-flex_latin_variable.3297da02.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: IBM Plex Serif;
  src: url("ibm-flex-serif_all_400_subset.5d60bef0.woff2") format("woff2");
  font-display: block;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

:root {
  --font-primary: "Roboto Flex", sans-serif;
  --font-logo: "IBM Plex Serif", serif;
  --color-accent-light: #e6a7a8;
  --color-accent: #c12325;
  --color-accent-active: #a21d1f;
  --color-grey-05: #f6f4f4;
  --color-grey-1: #ebe6e6;
  --color-grey-2: #c4c4c4;
  --color-grey-3: gray;
  --color-grey-4: #333;
  --color-white: #fff;
  --color-white-4: #ffffff0a;
  --color-white-16: #ffffff29;
  --color-white-50: #ffffff80;
  --color-black: #000;
  --color-yellow: #ffa600;
  --color-bg: #fff;
  --color-error: #f3511b;
  --color-marketing: #f3511b;
  --color-marketing-warranty: #1b7ff3;
  --color-success: #298e37;
  --color-success-active: #22762d;
  --color-overlay: #00000080;
  --color-blocks: var(--color-grey-05);
  --color-blocks-loading: #fcfbfb;
  --color-lines: var(--color-grey-1);
  --color-text-main: var(--color-grey-4);
  --color-text-aux: var(--color-grey-3);
  --color-icons: var(--color-grey-4);
  --color-outline: #c1232529;
  --ease-in-out-quart: cubic-bezier(.76, 0, .24, 1);
  --transition-in: .2s ease-out;
  --transition-out: .15s ease-in;
  --transition-sidebar: transform .2s var(--ease-in-out-quart), visibility .2s var(--ease-in-out-quart);
  --focus-visible-shadow: 0 0 0 3px #c1232529;
  --shadow-error: 0 0 0 3px #f3511b29;
  --shadow-success: 0 0 0 3px #71f31b29;
  --shadow-dropdown: 0 0 12px #0000001f, 0 0 0 1px #00000014;
  --size-container-width: 100%;
  --size-container-sidegap: 16px;
  --size-container-offset-left: calc((100vw - var(--size-container-width)) / 2 + var(--size-container-sidegap));
  --size-gap: 8px;
  --size-header-panel-height: 117px;
  --size-tabbar-height: 64px;
  --size-buy-bar-height: 61px;
  --mobile-menu-topbar-height: 56px;
  --z-index-tabbar: 10000;
  --z-index-header: 10170;
  --z-index-fab: 10150;
  --z-index-b24-widget: 10150;
  --z-index-b24-widget-overlay: 10180;
  --z-index-b24-widget-on-top: 10181;
  --z-index-notifications: 10200;
  --z-index-tabbar-on-top: 12000;
  --z-index-dialog: 13000;
  --z-index-dropdown: 13000;
}

@media screen and (width >= 768px) {
  :root {
    --size-container-sidegap: 24px;
    --size-gap: 12px;
    --mobile-menu-topbar-height: 64px;
  }
}

@media screen and (width >= 1024px) {
  :root {
    --size-container-sidegap: 32px;
    --size-gap: 16px;
    --size-header-panel-height: 170px;
  }
}

@media screen and (width >= 1200px) {
  :root {
    --size-container-width: 1200px;
    --size-container-sidegap: 40px;
  }
}

html {
  scroll-padding-top: calc(var(--size-header-panel-height, 200)  + 24px);
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

.is-no-scroll, .is-no-scroll body {
  height: 100% !important;
  max-height: 100% !important;
  overflow: hidden !important;
}

body {
  background-color: var(--color-bg);
  color: var(--color-grey-4);
  overscroll-behavior: none;
  min-height: 100%;
  font-family: Roboto Flex, sans-serif;
  font-size: 14px;
  font-weight: 350;
  font-stretch: 110%;
  line-height: 1.5;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-grey-4);
  border-radius: 999px;
}

::-webkit-scrollbar {
  background-color: #0000;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #00000014;
  border-radius: 999px;
}

::-webkit-scrollbar-track {
  background-color: #0000;
  border-radius: 999px;
}

::selection {
  background-color: var(--color-accent);
  color: #fff;
}

* {
  outline: none;
}

:focus-visible {
  outline: 3px solid var(--color-outline);
  outline-offset: 2px;
}

img:not([src], [srcset]) {
  visibility: hidden;
}

[x-cloak] {
  display: none !important;
}

.container {
  max-width: var(--size-container-width);
  padding: 0 var(--size-container-sidegap);
  width: 100%;
  margin: auto;
  display: block;
}

@keyframes loading-block {
  from {
    background-color: var(--color-blocks);
  }

  to {
    background-color: var(--color-blocks-loading);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}

.i {
  vertical-align: text-bottom;
  transition: background-color var(--transition-in);
  background-color: currentColor;
  width: 20px;
  height: 20px;
  display: inline-block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.i--32 {
  width: 32px;
  height: 32px;
}

.i--anonymous {
  -webkit-mask-image: url("anonymous.0fe03744.svg");
  mask-image: url("anonymous.0fe03744.svg");
}

.i--me {
  -webkit-mask-image: url("me.4e0d08ee.svg");
  mask-image: url("me.4e0d08ee.svg");
}

.i--not-me {
  -webkit-mask-image: url("not-me.b864a899.svg");
  mask-image: url("not-me.b864a899.svg");
}

.i--alarm-exclamation {
  -webkit-mask-image: url("alarm-exclamation.b96edd80.svg");
  mask-image: url("alarm-exclamation.b96edd80.svg");
}

.i--location {
  -webkit-mask-image: url("location.2fa86ba0.svg");
  mask-image: url("location.2fa86ba0.svg");
}

.i--calendar {
  -webkit-mask-image: url("calendar.8db83ff1.svg");
  mask-image: url("calendar.8db83ff1.svg");
}

.i--phone {
  -webkit-mask-image: url("phone.55cc9069.svg");
  mask-image: url("phone.55cc9069.svg");
}

.i--no-phone {
  -webkit-mask-image: url("no-phone.1e202e63.svg");
  mask-image: url("no-phone.1e202e63.svg");
}

.i--catalog {
  -webkit-mask-image: url("catalog.f6b42d00.svg");
  mask-image: url("catalog.f6b42d00.svg");
}

.i--home {
  -webkit-mask-image: url("home.ecc32bad.svg");
  mask-image: url("home.ecc32bad.svg");
}

.i--hamburger {
  -webkit-mask-image: url("hamburger.59082873.svg");
  mask-image: url("hamburger.59082873.svg");
}

.i--close {
  -webkit-mask-image: url("close.37a56065.svg");
  mask-image: url("close.37a56065.svg");
}

.i--upload-file {
  -webkit-mask-image: url("upload-file.e6c8ad91.svg");
  mask-image: url("upload-file.e6c8ad91.svg");
}

.i--upload-image {
  -webkit-mask-image: url("upload-image-20.c94c7eb7.svg");
  mask-image: url("upload-image-20.c94c7eb7.svg");
}

.i--image-32 {
  width: 32px;
  height: 32px;
  -webkit-mask-image: url("image-32.5c9b8c5b.svg");
  mask-image: url("image-32.5c9b8c5b.svg");
}

.i--close-24 {
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("close-24.31bb5f23.svg");
  mask-image: url("close-24.31bb5f23.svg");
}

.i--search {
  -webkit-mask-image: url("search.b035315a.svg");
  mask-image: url("search.b035315a.svg");
}

.i--cart {
  -webkit-mask-image: url("cart.42062538.svg");
  mask-image: url("cart.42062538.svg");
}

.i--cart-24 {
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("cart-24.207b3ace.svg");
  mask-image: url("cart-24.207b3ace.svg");
}

.i--fire {
  -webkit-mask-image: url("fire-20.2af2d1b1.svg");
  mask-image: url("fire-20.2af2d1b1.svg");
}

.i--flower {
  -webkit-mask-image: url("flower.50d60cd9.svg");
  mask-image: url("flower.50d60cd9.svg");
}

.i--flower-2 {
  -webkit-mask-image: url("flower-2.20ab90a0.svg");
  mask-image: url("flower-2.20ab90a0.svg");
}

.i--vip {
  -webkit-mask-image: url("vip.7facc004.svg");
  mask-image: url("vip.7facc004.svg");
}

.i--chevron-left {
  -webkit-mask-image: url("chevron-left.6cd6e149.svg");
  mask-image: url("chevron-left.6cd6e149.svg");
}

.i--chevron-right {
  -webkit-mask-image: url("chevron-right.c39e3ef6.svg");
  mask-image: url("chevron-right.c39e3ef6.svg");
}

.i--chevron-up {
  -webkit-mask-image: url("chevron-up.8115e6d5.svg");
  mask-image: url("chevron-up.8115e6d5.svg");
}

.i--chevron-down {
  -webkit-mask-image: url("chevron-down.82b9bbc2.svg");
  mask-image: url("chevron-down.82b9bbc2.svg");
}

.i--shop {
  -webkit-mask-image: url("shop.3152b9a2.svg");
  mask-image: url("shop.3152b9a2.svg");
}

.i--filter {
  -webkit-mask-image: url("filter.d2452cef.svg");
  mask-image: url("filter.d2452cef.svg");
}

.i--truck {
  -webkit-mask-image: url("truck.827e4019.svg");
  mask-image: url("truck.827e4019.svg");
}

.i--card {
  -webkit-mask-image: url("card.9f7d32a1.svg");
  mask-image: url("card.9f7d32a1.svg");
}

.i--card-terminal {
  -webkit-mask-image: url("card-terminal.a831e5ae.svg");
  mask-image: url("card-terminal.a831e5ae.svg");
}

.i--wallet {
  -webkit-mask-image: url("wallet.f14849ab.svg");
  mask-image: url("wallet.f14849ab.svg");
}

.i--crypto {
  -webkit-mask-image: url("crypto.d787c6b2.svg");
  mask-image: url("crypto.d787c6b2.svg");
}

.i--heart {
  -webkit-mask-image: url("heart.e04f052e.svg");
  mask-image: url("heart.e04f052e.svg");
}

.i--heart-broken {
  -webkit-mask-image: url("heart-broken.0d6f9100.svg");
  mask-image: url("heart-broken.0d6f9100.svg");
}

.i--heart-star {
  -webkit-mask-image: url("heart-star.1bd6550f.svg");
  mask-image: url("heart-star.1bd6550f.svg");
}

.i--comment {
  -webkit-mask-image: url("comment.f3f1f311.svg");
  mask-image: url("comment.f3f1f311.svg");
}

.i--medal {
  -webkit-mask-image: url("medal.dd7f92e3.svg");
  mask-image: url("medal.dd7f92e3.svg");
}

.i--photo {
  -webkit-mask-image: url("photo.c01a19b8.svg");
  mask-image: url("photo.c01a19b8.svg");
}

.i--checkmark-24 {
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("checkmark-24.4623c672.svg");
  mask-image: url("checkmark-24.4623c672.svg");
}

.i--check-fill {
  -webkit-mask-image: url("check-fill.defda8a0.svg");
  mask-image: url("check-fill.defda8a0.svg");
}

.i--clear-fill {
  -webkit-mask-image: url("clear-fill.5a7a2708.svg");
  mask-image: url("clear-fill.5a7a2708.svg");
}

.i--help-fill {
  -webkit-mask-image: url("help-fill.ab34a1cc.svg");
  mask-image: url("help-fill.ab34a1cc.svg");
}

.i--shop-courier {
  -webkit-mask-image: url("shop-courier.6e60a6b6.svg");
  mask-image: url("shop-courier.6e60a6b6.svg");
}

.i--costume-courier {
  -webkit-mask-image: url("costume-courier.bbf13933.svg");
  mask-image: url("costume-courier.bbf13933.svg");
}

.i--service-courier {
  -webkit-mask-image: url("service-courier.db063d67.svg");
  mask-image: url("service-courier.db063d67.svg");
}

.i--florist {
  -webkit-mask-image: url("florist.4faf5214.svg");
  mask-image: url("florist.4faf5214.svg");
}

.i--spruce {
  -webkit-mask-image: url("spruce.cd819f79.svg");
  mask-image: url("spruce.cd819f79.svg");
}

.i--home-16 {
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("home-16.da2c5cbb.svg");
  mask-image: url("home-16.da2c5cbb.svg");
}

.i--star-16 {
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("star-16.02b685ba.svg");
  mask-image: url("star-16.02b685ba.svg");
}

.i--vk {
  width: 32px;
  height: 32px;
  -webkit-mask-image: url("vk-32.4daaf8fa.svg");
  mask-image: url("vk-32.4daaf8fa.svg");
}

.i--instagram {
  width: 32px;
  height: 32px;
  -webkit-mask-image: url("instagram-32.292dbd4b.svg");
  mask-image: url("instagram-32.292dbd4b.svg");
}

.i--user-12 {
  width: 12px;
  height: 12px;
  -webkit-mask-image: url("user-12.71f04ffd.svg");
  mask-image: url("user-12.71f04ffd.svg");
}

.i--eye-16 {
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("eye-16.c3e65bb2.svg");
  mask-image: url("eye-16.c3e65bb2.svg");
}

.i--arrow-top-24 {
  width: 24px;
  height: 24px;
  -webkit-mask-image: url("arrow-top-24.774305d3.svg");
  mask-image: url("arrow-top-24.774305d3.svg");
}

:root {
  --swiper-theme-color: #007aff;
}

.swiper {
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  z-index: 1;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  transition-property: transform;
  display: flex;
  position: relative;
}

.swiper-android .swiper-slide, .swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

.swiper-pointer-events {
  touch-action: pan-y;
}

.swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  transition-property: transform;
  position: relative;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-autoheight, .swiper-autoheight .swiper-slide {
  height: auto;
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-backface-hidden .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.swiper-3d, .swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px;
}

.swiper-3d .swiper-cube-shadow, .swiper-3d .swiper-slide, .swiper-3d .swiper-slide-shadow, .swiper-3d .swiper-slide-shadow-bottom, .swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-top, .swiper-3d .swiper-wrapper {
  transform-style: preserve-3d;
}

.swiper-3d .swiper-slide-shadow, .swiper-3d .swiper-slide-shadow-bottom, .swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-top {
  z-index: 10;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-3d .swiper-slide-shadow {
  background: #00000026;
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, #00000080, #0000);
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, #00000080, #0000);
}

.swiper-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, #00000080, #0000);
}

.swiper-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(#00000080, #0000);
}

.swiper-css-mode > .swiper-wrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;
}

.swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}

.swiper-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-horizontal.swiper-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}

.swiper-vertical.swiper-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}

.swiper-centered > .swiper-wrapper:before {
  content: "";
  flex-shrink: 0;
  order: 9999;
}

.swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}

.swiper-centered.swiper-horizontal > .swiper-wrapper:before {
  width: var(--swiper-centered-offset-after);
  height: 100%;
  min-height: 1px;
}

.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}

.swiper-centered.swiper-vertical > .swiper-wrapper:before {
  height: var(--swiper-centered-offset-after);
  width: 100%;
  min-width: 1px;
}

.swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
}

.swiper-free-mode > .swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out;
}

.swiper-grid > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-grid-column > .swiper-wrapper {
  flex-flow: column wrap;
}

:root {
  --swiper-navigation-size: 44px;
}

.swiper-button-next, .swiper-button-prev {
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-next.swiper-button-hidden, .swiper-button-prev.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.swiper-navigation-disabled .swiper-button-next, .swiper-navigation-disabled .swiper-button-prev {
  display: none !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  text-transform: none !important;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  left: auto;
  right: 10px;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "next";
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  text-align: center;
  z-index: 10;
  transition: opacity .3s;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-disabled > .swiper-pagination, .swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  width: 100%;
  bottom: 10px;
  left: 0;
}

.swiper-pagination-bullets-dynamic {
  font-size: 0;
  overflow: hidden;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  position: relative;
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(.33);
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
  border-radius: 50%;
  display: inline-block;
}

button.swiper-pagination-bullet {
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet:only-child {
  display: none !important;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical > .swiper-pagination-bullets {
  top: 50%;
  right: 10px;
  transform: translate3d(0, -50%, 0);
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  width: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, top .2s;
  display: inline-block;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, left .2s;
}

.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: transform .2s, right .2s;
}

.swiper-pagination-progressbar {
  background: #00000040;
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: 100% 0;
}

.swiper-horizontal > .swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal, .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite, .swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  top: 0;
  left: 0;
}

.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite, .swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite, .swiper-pagination-progressbar.swiper-pagination-vertical, .swiper-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
}

.swiper-pagination-lock {
  display: none;
}

.slider {
  --swiper-theme-color: var(--color-accent);
  --swiper-pagination-bullet-inactive-color: var(--color-grey-2);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 6px;
  --swiper-pagination-bullet-height: 6px;
  --_swiper-pagination-offset: var(--swiper-pagination-offset, 0);
  --_swiper-navigation-offset: var(--swiper-navigation-offset, -18px);
  position: relative;
}

.slider .swiper {
  padding-bottom: var(--_swiper-pagination-offset);
  width: 100%;
}

.slider.has-pagination {
  --swiper-pagination-offset: 16px;
}

.slider .swiper-pagination {
  line-height: 10px;
  bottom: 0;
}

.slider .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev, .slider .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: none;
}

.slider .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev, .slider .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(.9);
}

.slider .swiper-slide {
  width: auto;
}

.slider .swiper-slide.is-rounded {
  border-radius: 8px;
  overflow: hidden;
}

@media screen and (width <= 1199px) {
  .slider--is-mobile-visible .swiper {
    overflow: visible;
  }
}

.slider__next, .slider__prev {
  display: none;
}

@media screen and (width >= 768px) {
  .slider__next, .slider__prev {
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: opacity var(--transition-in), visibility var(--transition-in);
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 12px #0000001f, 0 0 0 1px #00000014;
  }

  .slider__next .i, .slider__prev .i {
    background-color: var(--color-grey-4);
  }

  .slider__next.swiper-button-disabled, .slider__prev.swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }

  .slider__next:hover .i, .slider__prev:hover .i, .slider__next:focus-visible .i, .slider__prev:focus-visible .i {
    background-color: var(--color-accent);
  }

  .slider__next:focus-visible, .slider__prev:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }
}

.slider__prev {
  left: var(--_swiper-navigation-offset);
}

.slider__next {
  right: var(--_swiper-navigation-offset);
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  background-color: var(--color-grey-4);
  color: #fff;
  white-space: normal;
  border-radius: 4px;
  outline: 0;
  font-size: 12px;
  line-height: 16px;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  color: #333;
  width: 16px;
  height: 16px;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

.dropdown {
  position: relative;
}

.dropdown__body {
  animation: .25s both fade-in;
}

.dropdown__body[aria-hidden="true"] {
  display: none;
}

.contacts-popup {
  z-index: var(--z-index-dropdown);
  background: #fff;
  border-radius: 8px;
  width: 256px;
  padding: 12px;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 0 0 1px #00000014, 0 0 12px #0000001f;
}

.contacts-popup__body {
  font-size: 13px;
  font-weight: 350;
  line-height: 16px;
}

.contacts-popup__time {
  color: var(--color-text-aux);
  margin-bottom: 4px;
}

.contacts-popup__address {
  border-bottom: 1px solid var(--color-lines);
  margin-bottom: 8px;
  padding-bottom: 12px;
  display: block;
}

@media screen and (width >= 1024px) {
  .contacts-popup__address {
    padding-bottom: 16px;
  }
}

.contacts-popup__contact {
  grid-template: "icon label"
                 "icon value"
                 / 36px 1fr;
  gap: 4px 8px;
  padding: 8px 0;
  display: grid;
}

@media screen and (width >= 1024px) {
  .contacts-popup__contact {
    padding: 4px 0;
  }
}

.contacts-popup__icon {
  grid-area: icon;
}

.contacts-popup__icon img {
  width: 100%;
}

.contacts-popup__label {
  color: var(--color-text-aux);
  grid-area: label;
}

.contacts-popup__value {
  grid-area: value;
  transition: color .3s ease-out;
}

.contacts-popup__contact:hover .contacts-popup__value, .contacts-popup__contact:focus-visible .contacts-popup__value {
  color: var(--color-accent);
}

.details, .wp-block-details {
  border-top: 1px solid var(--color-lines);
}

.details[open], .wp-block-details[open] {
  padding-bottom: 12px;
}

.details:last-child, .wp-block-details:last-child {
  border-bottom: 1px solid var(--color-lines);
}

.details summary, .wp-block-details summary {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 32px;
  font-weight: 550;
  list-style: none;
  position: relative;
}

.details summary::-webkit-details-marker {
  display: none;
}

.wp-block-details summary::-webkit-details-marker {
  display: none;
}

.details summary:after, .wp-block-details summary:after {
  content: "";
  background-color: var(--color-grey-3);
  transition: transform var(--transition-in);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-mask-image: url("chevron-down.82b9bbc2.svg");
  mask-image: url("chevron-down.82b9bbc2.svg");
}

details[open] summary:after {
  transform: translateY(-50%)scaleY(-1);
}

.details-group {
  border: 1px solid var(--color-lines);
  border-radius: 8px;
}

.details-group details {
  padding: 0 16px;
}

.details-group details[open] {
  padding-bottom: 16px;
}

.details-group details:not(:last-child) {
  border-bottom: 1px solid var(--color-lines);
}

.details-group summary {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
  font-weight: 550;
  list-style: none;
  position: relative;
}

.details-group summary::-webkit-details-marker {
  display: none;
}

.details-group summary:after {
  content: "";
  background-color: var(--color-grey-3);
  transition: transform var(--transition-in);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  -webkit-mask-image: url("chevron-down.82b9bbc2.svg");
  mask-image: url("chevron-down.82b9bbc2.svg");
}

.details-group details[open] summary:after {
  transform: translateY(-50%)scaleY(-1);
}

.page {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

.page__main {
  flex: 1;
}

.page__main.has-padding {
  padding-top: 16px;
  padding-bottom: 96px;
}

@media screen and (width >= 768px) {
  .page__main.has-padding {
    padding-top: 24px;
  }
}

.dialog {
  z-index: var(--z-index-dialog);
  display: flex;
  position: fixed;
  inset: 0;
}

.dialog[aria-hidden="true"] {
  display: none;
}

.dialog__overlay {
  background: #00000080;
  animation: .2s both fade-in;
  position: fixed;
  inset: 0;
}

.dialog__body {
  z-index: 2;
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  animation: .4s .2s both fade-in, .4s .2s both slide-up;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (width >= 1024px) {
  .dialog__body {
    filter: drop-shadow(0 0 12px #0000001f);
    border-radius: 16px;
    width: 384px;
    max-width: 90%;
    margin: auto;
    box-shadow: 0 0 0 1px #00000014;
  }
}

.dialog__fieldset {
  opacity: 1;
  transition: opacity var(--transition-in);
}

.dialog__fieldset.is-submitting {
  opacity: .5;
}

.dialog__content {
  width: var(--size-container-width);
  padding: 16px var(--size-container-sidegap);
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  overflow-y: auto;
}

@media screen and (width >= 768px) {
  .dialog__content {
    padding: 24px var(--size-container-sidegap);
  }
}

@media screen and (width >= 1024px) {
  .dialog__content {
    width: 100%;
    margin: 0;
    padding: 24px;
  }
}

.dialog__panel {
  padding: 16px var(--size-container-sidegap);
  border-top: 1px solid var(--color-lines);
}

.dialog__panel.has-buttons {
  justify-content: space-between;
  display: flex;
}

@media screen and (width >= 768px) {
  .dialog__panel {
    padding: 24px var(--size-container-sidegap);
  }
}

@media screen and (width >= 1024px) {
  .dialog__panel {
    padding: 16px 24px;
  }
}

.dialog__success, .dialog__failure {
  z-index: 1;
  background-color: var(--color-bg);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.dialog__title {
  border-bottom: 1px solid var(--color-lines);
  align-items: center;
  min-height: 56px;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  display: flex;
}

@media screen and (width >= 768px) {
  .dialog__title {
    padding-left: 24px;
  }
}

@media screen and (width >= 1024px) {
  .dialog__title {
    min-height: 64px;
  }
}

.dialog__close {
  position: absolute;
  top: 8px;
  right: 8px;
}

@media screen and (width >= 768px) {
  .dialog__close {
    right: 16px;
  }
}

@media screen and (width >= 1024px) {
  .dialog__close {
    top: 12px;
    right: 12px;
  }
}

@media screen and (width <= 1023px) {
  .dialog--is-mobile-panel .dialog__body {
    border-radius: 16px 16px 0 0;
    height: max-content;
    margin-top: auto;
  }
}

.dialog--has-small-heading .dialog__title {
  border-bottom: none;
}

.dialog--has-small-heading .dialog__content {
  padding-top: 0;
}

@media screen and (width >= 1024px) {
  .dialog--medium .dialog__body {
    width: 512px;
  }
}

.gallery-dialog {
  z-index: var(--z-index-dialog);
  display: flex;
  position: fixed;
  inset: 0;
}

.gallery-dialog[aria-hidden="true"] {
  display: none;
}

.gallery-dialog__overlay {
  background: #00000080;
  animation: .2s both fade-in;
  position: fixed;
  inset: 0;
}

.gallery-dialog__body {
  z-index: 2;
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  animation: .4s .2s both fade-in;
  display: flex;
  position: relative;
  overflow: hidden;
}

.gallery-dialog__fieldset {
  opacity: 1;
  transition: opacity var(--transition-in);
}

.gallery-dialog__fieldset.is-submitting {
  opacity: .5;
}

.gallery-dialog__content {
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - 65px);
  margin: 0 auto;
  padding: 16px 0;
  display: flex;
  position: relative;
}

@media screen and (width >= 768px) {
  .gallery-dialog__content {
    padding: 24px var(--size-container-sidegap);
  }
}

@media screen and (width >= 1024px) {
  .gallery-dialog__content {
    margin: 0;
    padding: 24px;
  }
}

.gallery-dialog__swiper {
  flex: auto;
  margin: 0;
  overflow: visible;
}

.gallery-dialog__swiper .swiper-button-prev, .gallery-dialog__swiper .swiper-button-next {
  background: var(--color-grey-05);
  color: var(--color-text-main);
  transition: color var(--transition-in), opacity var(--transition-in);
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.gallery-dialog__swiper .swiper-button-prev:after, .gallery-dialog__swiper .swiper-button-next:after {
  content: "";
  background-color: currentColor;
  width: 20px;
  height: 20px;
  font-family: serif;
  display: block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.gallery-dialog__swiper .swiper-button-prev:hover, .gallery-dialog__swiper .swiper-button-next:hover {
  color: var(--color-accent);
}

@media screen and (width <= 1023px) {
  .gallery-dialog__swiper .swiper-button-prev, .gallery-dialog__swiper .swiper-button-next {
    display: none;
  }
}

.gallery-dialog__swiper .swiper-button-prev {
  left: -10px;
}

.gallery-dialog__swiper .swiper-button-prev:after {
  -webkit-mask-image: url("chevron-left.6cd6e149.svg");
  mask-image: url("chevron-left.6cd6e149.svg");
}

.gallery-dialog__swiper .swiper-button-next {
  right: -10px;
}

.gallery-dialog__swiper .swiper-button-next:after {
  -webkit-mask-image: url("chevron-right.c39e3ef6.svg");
  mask-image: url("chevron-right.c39e3ef6.svg");
}

.gallery-dialog__item {
  justify-content: center;
  align-items: center;
  height: auto;
  display: flex;
}

.gallery-dialog__picture {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.gallery-dialog__picture img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-dialog__thumbnail-swiper {
  --swiper-navigation-size: 32px;
  flex-shrink: 0;
  width: 100%;
  margin-top: 24px;
  padding: 0 16px;
  overflow: visible;
}

.gallery-dialog__thumbnail-swiper .swiper-button-prev, .gallery-dialog__thumbnail-swiper .swiper-button-next {
  background: var(--color-white);
  color: var(--color-text-main);
  transition: color var(--transition-in), opacity var(--transition-in);
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  box-shadow: 0 0 0 1px #00000014, 0 0 12px #0000001f;
}

.gallery-dialog__thumbnail-swiper .swiper-button-prev:after, .gallery-dialog__thumbnail-swiper .swiper-button-next:after {
  content: "";
  background-color: currentColor;
  width: 20px;
  height: 20px;
  font-family: serif;
  display: block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.gallery-dialog__thumbnail-swiper .swiper-button-prev:hover, .gallery-dialog__thumbnail-swiper .swiper-button-next:hover {
  color: var(--color-accent);
}

@media screen and (width <= 1023px) {
  .gallery-dialog__thumbnail-swiper .swiper-button-prev, .gallery-dialog__thumbnail-swiper .swiper-button-next {
    display: none;
  }
}

.gallery-dialog__thumbnail-swiper .swiper-button-prev {
  left: -10px;
}

.gallery-dialog__thumbnail-swiper .swiper-button-prev:after {
  -webkit-mask-image: url("chevron-left.6cd6e149.svg");
  mask-image: url("chevron-left.6cd6e149.svg");
}

.gallery-dialog__thumbnail-swiper .swiper-button-next {
  right: -10px;
}

.gallery-dialog__thumbnail-swiper .swiper-button-next:after {
  -webkit-mask-image: url("chevron-right.c39e3ef6.svg");
  mask-image: url("chevron-right.c39e3ef6.svg");
}

@media screen and (width >= 1024px) {
  .gallery-dialog__thumbnail-swiper {
    padding: 0;
  }
}

.gallery-dialog__thumbnail-item {
  border: 1px solid var(--color-grey-1);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: border-color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 8px;
  width: 64px;
  height: 64px;
  padding: 4px;
  position: relative;
  overflow: hidden;
}

.gallery-dialog__thumbnail-item:hover, .gallery-dialog__thumbnail-item:focus-visible {
  border-color: var(--color-grey-2);
  outline: none;
}

.gallery-dialog__thumbnail-item.swiper-slide-thumb-active {
  border-color: var(--color-accent);
}

.gallery-dialog__thumbnail-badge {
  content: "";
  z-index: 1;
  background: var(--color-white);
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 2px;
}

.gallery-dialog__thumbnail-badge:after {
  content: "";
  background: var(--color-grey-2);
  width: 12px;
  height: 12px;
  -webkit-mask-image: url("user-12.71f04ffd.svg");
  mask-image: url("user-12.71f04ffd.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.gallery-dialog__thumbnail-picture {
  background-color: var(--color-blocks);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.gallery-dialog__thumbnail-picture.is-video:after {
  content: "";
  background: url("video-play.5d15f1da.svg") center / contain no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery-dialog__thumbnail-picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.gallery-dialog__title {
  border-bottom: 1px solid var(--color-lines);
  align-items: center;
  min-height: 56px;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  display: flex;
}

@media screen and (width >= 768px) {
  .gallery-dialog__title {
    padding-left: 24px;
  }
}

@media screen and (width >= 1024px) {
  .gallery-dialog__title {
    min-height: 64px;
  }
}

.gallery-dialog__close {
  position: absolute;
  top: 8px;
  right: 8px;
}

@media screen and (width >= 768px) {
  .gallery-dialog__close {
    right: 16px;
  }
}

@media screen and (width >= 1024px) {
  .gallery-dialog__close {
    top: 12px;
    right: 12px;
  }
}

.link {
  transition: color .3s ease-out;
}

.link:hover, .link:focus-visible {
  color: var(--color-accent);
}

.link:focus-visible {
  outline-width: 2px;
}

.link-standalone {
  color: var(--color-accent);
  transition: color .3s ease-out;
}

.link-standalone:hover, .link-standalone:focus-visible {
  text-decoration: underline;
}

.link-standalone:focus-visible {
  outline-width: 2px;
}

.links-in-text a {
  color: var(--color-accent);
  transition: color .3s ease-out;
}

.links-in-text a:hover, .links-in-text a:focus-visible {
  text-decoration: underline;
}

.links-in-text a:focus-visible {
  outline-width: 2px;
}

.icon-button {
  color: var(--color-grey-4);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: color var(--transition-in), border-color var(--transition-in), background-color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: inline-flex;
}

.icon-button:hover, .icon-button:focus-visible {
  background-color: var(--color-grey-05);
}

.icon-button:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: none;
}

.icon-button svg {
  width: 16px;
  height: auto;
}

.icon-button--accent {
  background-color: var(--color-accent);
  color: #fff;
}

.icon-button--accent:hover, .icon-button--accent:focus-visible {
  background-color: var(--color-accent-active);
}

.icon-button--secondary-outline {
  border-color: var(--color-grey-1);
  background-color: var(--color-bg);
  color: var(--color-grey-3);
}

.icon-button--secondary-outline:hover, .icon-button--secondary-outline:focus-visible {
  background-color: var(--color-grey-05);
}

.icon-button--s {
  width: 36px;
  height: 36px;
}

.micro-icon-button {
  vertical-align: text-bottom;
  color: var(--color-grey-2);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: inline-flex;
}

.micro-icon-button:hover, .micro-icon-button:active, .micro-icon-button:focus-visible {
  color: var(--color-grey-3);
}

.micro-icon-button:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: none;
}

.toolbar-button {
  transition: color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  display: inline-flex;
  position: relative;
}

.toolbar-button:hover, .toolbar-button.is-active, .toolbar-button:focus-visible {
  color: var(--color-accent);
}

.toolbar-button__inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.toolbar-button__badge {
  z-index: 1;
  background-color: var(--color-accent);
  color: #fff;
  border: 2px solid #fff;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  height: 18px;
  padding: 0 2px;
  font-size: 12px;
  font-weight: 550;
  line-height: 1;
  display: flex;
  position: absolute;
  top: -4px;
  right: 8px;
}

.contact-button {
  border: 1px solid var(--color-lines);
  transition: color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 8px;
  justify-content: center;
  align-self: center;
  padding: 12px 16px;
  display: flex;
}

.contact-button img {
  flex-shrink: 0;
  margin-right: 8px;
}

.contact-button:hover, .contact-button:focus-visible {
  color: var(--color-accent);
}

.contact-button:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: 0;
}

.radio-group {
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  overflow: hidden;
}

.radio-group__item {
  cursor: pointer;
  padding: 16px;
  display: block;
  position: relative;
}

.radio-group__item:not(:last-of-type) {
  border-bottom: 1px solid var(--color-lines);
}

.radio-group__input {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.radio-group__icon {
  color: var(--color-accent);
  display: block;
  position: absolute;
  top: 16px;
  left: 16px;
}

.radio-group__label {
  padding-right: 40px;
  display: flex;
}

.radio-group__label span {
  display: flex;
}

.radio-group__icon ~ .radio-group__label {
  padding-left: 32px;
}

.radio-group__description {
  color: var(--color-text-aux);
  margin-top: 4px;
  padding-right: 40px;
  display: block;
}

.radio-group__icon ~ .radio-group__description {
  padding-left: 32px;
}

.radio-group__button {
  color: var(--color-accent);
  margin-top: 4px;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}

.radio-group__button:hover, .radio-group__button:focus-visible {
  text-decoration: underline;
}

.radio-group__button:focus-visible {
  outline-width: 2px;
}

.radio-group__icon ~ .radio-group__button {
  padding-left: 32px;
}

.radio-group__radio {
  border: 1px solid var(--color-lines);
  transition: border-color var(--transition-in), background-color var(--transition-in);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
}

.radio-group__item:hover .radio-group__radio {
  border-color: var(--color-accent);
}

.radio-group__input:focus + .radio-group__radio {
  border-color: var(--color-accent);
  box-shadow: var(--focus-visible-shadow);
}

.radio-group__input:checked + .radio-group__radio {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}

.radio-group__input:checked + .radio-group__radio:after {
  content: "";
  background-color: #fff;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toggle {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  position: relative;
}

.toggle__input {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.toggle__icon {
  color: var(--color-accent);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.toggle__label {
  cursor: pointer;
  align-items: center;
  gap: 4px;
  padding-right: 44px;
  display: flex;
}

.toggle__label:before {
  content: "";
  border: 1px solid var(--color-lines);
  transition: background-color var(--transition-in), border-color var(--transition-in);
  background-color: #fff;
  border-radius: 999px;
  width: 36px;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.toggle__label:after {
  content: "";
  background-color: var(--color-grey-2);
  transition: background-color var(--transition-in), transform .3s var(--ease-in-out-quart);
  border-radius: 100%;
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  top: 3px;
  right: 19px;
}

.toggle__icon ~ .toggle__label {
  padding-left: 32px;
}

.toggle__label:hover:before {
  border-color: var(--color-accent);
}

.toggle__input:checked + .toggle__label:before {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}

.toggle__input:checked + .toggle__label:after {
  background-color: #fff;
  transform: translateX(16px);
}

.toggle__input:focus + .toggle__label:before, .toggle__input:active + .toggle__label:before {
  border-color: var(--color-accent);
  box-shadow: var(--focus-visible-shadow);
}

.toggle__description {
  color: var(--color-text-aux);
  margin-top: 4px;
  padding-right: 40px;
  display: block;
}

.toggle__icon ~ .toggle__description {
  padding-left: 32px;
}

.toggle__link {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}

.toggle__icon ~ .toggle__link {
  padding-left: 32px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -124px;
  }
}

.button {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--transition-in), border-color var(--transition-in), color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #0000;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  padding: 7px 11px;
  font-size: 14px;
  font-weight: 550;
  line-height: 1.4px;
  display: inline-flex;
}

.button:not(:disabled):focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: none;
}

.button:disabled {
  box-shadow: none;
  cursor: not-allowed;
  outline: none;
}

.button--has-loader {
  justify-items: center;
  display: inline-grid;
}

.button--has-loader > * {
  grid-area: 1 / 1 / -1 / -1;
}

.button--primary {
  background-color: var(--color-accent);
  color: #fff;
}

.button--primary:not(:disabled):hover, .button--primary:not(:disabled):active, .button--primary:not(:disabled):focus-visible {
  background-color: var(--color-accent-active);
}

.button--primary:disabled {
  border-color: var(--color-grey-2);
  background-color: var(--color-grey-2);
  color: #fff;
}

.button--primary-outline {
  border-color: var(--color-accent-active);
  background-color: var(--color-bg);
  color: var(--color-accent-active);
}

.button--primary-outline:not(:disabled):hover, .button--primary-outline:not(:disabled):active, .button--primary-outline:not(:disabled):focus-visible {
  background-color: var(--color-accent-active);
  color: #fff;
}

.button--primary-outline:disabled {
  border-color: var(--color-grey-2);
  background-color: var(--color-grey-2);
  color: #fff;
}

.button--secondary-outline {
  border-color: var(--color-grey-1);
  background-color: var(--color-bg);
  color: var(--color-text-main);
}

.button--secondary-outline:not(:disabled):hover, .button--secondary-outline:not(:disabled):active, .button--secondary-outline:not(:disabled):focus-visible {
  background-color: var(--color-grey-05);
}

.button--secondary-outline:disabled {
  border-color: var(--color-grey-2);
  background-color: var(--color-grey-2);
  color: #fff;
}

.button--inverse {
  border-color: var(--color-white-16);
  color: #fff;
  background-color: #0000;
}

.button--inverse:not(:disabled):hover, .button--inverse:not(:disabled):active, .button--inverse:not(:disabled):focus-visible {
  background-color: var(--color-white-4);
}

.button--inverse:disabled {
  border-color: var(--color-white-16);
  color: var(--color-white-50);
  background-color: #0000;
}

.button--xs {
  border-radius: 4px;
  min-height: 24px;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 550;
  line-height: 16px;
}

.button--m {
  min-height: 44px;
  padding: 11px 15px;
}

@media screen and (width >= 1024px) {
  .md\:button--m {
    min-height: 44px;
    padding: 11px 15px;
  }
}

.button--l {
  min-height: 52px;
  padding: 15px;
}

.button__content {
  opacity: 1;
  transition: opacity var(--transition-in);
}

.button.is-loading .button__content {
  opacity: 0;
}

.button__loader {
  --loader-speed: 2s;
  vertical-align: middle;
  opacity: 0;
  transition: opacity var(--transition-in);
  transform-origin: center;
  animation: rotate var(--loader-speed) linear infinite;
  width: 20px;
  height: 20px;
}

.button__loader circle {
  fill: none;
  stroke: currentColor;
  animation: stretch calc(var(--loader-speed) * .75) ease-in-out infinite;
  stroke-dasharray: 1 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

.button.is-loading .button__loader {
  opacity: 1;
}

.button__left-icon {
  margin-right: 8px;
}

.button__right-icon {
  margin-left: 4px;
}

.input {
  display: block;
  position: relative;
}

.input__label {
  z-index: 1;
  color: var(--color-text-aux);
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  transition: transform .2s var(--ease-in-out-quart);
  transform-origin: 0;
  position: absolute;
  top: 16px;
  left: 16px;
  transform: none;
}

.input.is-focused .input__label {
  transform: translate(1px, -14px)scale(.8);
}

.input__max-length {
  color: var(--color-text-aux);
  font-size: 11px;
  font-style: normal;
  font-weight: 350;
  line-height: 16px;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.input__control {
  border: 1px solid var(--color-lines);
  background: var(--color-bg);
  resize: none;
  transition: border-color var(--transition-in), box-shadow var(--transition-in), opacity var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 8px;
  width: 100%;
  min-height: 52px;
  padding: 7px 23px 7px 16px;
  font-size: 14px;
  line-height: 1.4;
}

.input__control:not(:disabled):hover {
  border-color: var(--color-accent);
}

.input__control:not(:disabled):focus {
  border-color: var(--color-accent);
  box-shadow: 0 0 0 3px #c1232529;
}

.input__control:disabled {
  opacity: .8;
}

.input__control:focus-visible {
  outline: 0;
}

.input__control::-ms-clear {
  display: none;
}

.input__label + .input__control {
  padding-top: 16px;
}

.input.has-error .input__control {
  border-color: var(--color-error);
}

.input.has-error .input__control:focus {
  box-shadow: var(--shadow-error);
}

.input.is-success .input__control {
  border-color: var(--color-success);
}

.input.is-success .input__control:focus {
  box-shadow: var(--shadow-success);
}

@media screen and (width >= 1024px) {
  .input__control {
    font-size: 14px;
  }
}

.input__control--s {
  min-height: 36px;
  padding: 7px 12px;
}

.input__icon {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 16px;
  right: 16px;
}

.input__error {
  color: var(--color-error);
  margin-top: 8px;
  font-size: 12px;
  display: block;
}

.input__error:before {
  content: "";
  vertical-align: bottom;
  background-color: var(--color-error);
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-block;
  -webkit-mask-image: url("error.bb75903d.svg");
  mask-image: url("error.bb75903d.svg");
}

.input__success {
  color: var(--color-success);
  margin-top: 8px;
  font-size: 12px;
  display: block;
}

.checkbox {
  cursor: pointer;
  padding: 4px 0;
  display: block;
}

.checkbox__input {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.checkbox__label {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding-left: 28px;
  line-height: 20px;
  display: flex;
  position: relative;
}

.checkbox__label:before {
  content: "";
  border: 1px solid var(--color-lines);
  transition: background-color var(--transition-in), border-color var(--transition-in), box-shadow var(--transition-in);
  background-color: #fff;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox__label:after {
  content: "";
  background: url("checkbox-mark.074c5aa1.svg") center no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox__label:hover:before, .checkbox__label:focus:before {
  border-color: var(--color-accent);
}

.checkbox__input:focus-visible + .checkbox__label:before {
  border-color: var(--color-accent);
  box-shadow: var(--focus-visible-shadow);
}

.checkbox__input:checked + .checkbox__label:before {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}

.checkbox__label .checkbox__aux-label {
  color: var(--color-text-aux);
  margin-left: 4px;
  display: inline-block;
}

.tabs__tab {
  border: 1px solid var(--color-lines);
  transition: background-color var(--transition-in);
  border-radius: 999px;
  min-height: 36px;
  margin-right: 8px;
  padding: 7px 16px;
  line-height: 20px;
  display: block;
}

.tabs__tab[aria-selected="true"], .tabs__tab:hover, .tabs__tab:focus-visible {
  background: var(--color-grey-05);
}

.tabs__tab-counter {
  color: var(--color-grey-3);
}

.tabs__slider {
  position: relative;
}

.tabs__item {
  width: auto;
}

.tabs__nav {
  display: none;
}

@media screen and (width >= 1024px) {
  .tabs__nav {
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: opacity var(--transition-in), visibility var(--transition-in);
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 12px #0000001f, 0 0 0 1px #00000014;
  }

  .tabs__nav .i {
    background-color: var(--color-grey-4);
  }

  .tabs__nav.swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }

  .tabs__nav:hover .i, .tabs__nav:focus-visible .i {
    background-color: var(--color-accent);
  }

  .tabs__nav:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }
}

.tabs__nav--prev {
  left: -15px;
}

.tabs__nav--next {
  right: -15px;
}

.favorite-button {
  border: 1px solid var(--color-lines);
  cursor: pointer;
  transition: background-color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 8px;
  outline: none;
  flex: none;
  width: 36px;
  height: 36px;
  margin-left: 4px;
  display: block;
  position: relative;
}

.favorite-button:before, .favorite-button:after {
  content: "";
  transition: background-color var(--transition-in);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 7px;
  left: 7px;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.favorite-button:before {
  background-color: var(--color-white);
  -webkit-mask-image: url("favorite-button-heart-bg.87fdd950.svg");
  mask-image: url("favorite-button-heart-bg.87fdd950.svg");
}

.favorite-button:after {
  background-color: var(--color-black);
  -webkit-mask-image: url("favorite-button-heart-outline.b0db89a8.svg");
  mask-image: url("favorite-button-heart-outline.b0db89a8.svg");
}

.favorite-button:hover, .favorite-button:focus-visible, .favorite-button:hover:before, .favorite-button:focus-visible:before {
  background: var(--color-grey-05);
}

.favorite-button.is-active:before, .favorite-button.is-active:after {
  background-color: var(--color-accent);
}

@media screen and (width >= 1024px) {
  .favorite-button {
    width: 44px;
    height: 44px;
    margin-left: 8px;
    padding: 12px;
  }

  .favorite-button:before, .favorite-button:after {
    top: 11px;
    left: 11px;
  }
}

.card-favorite-button {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: url("favorite-button-bg.8974a905.svg") center no-repeat;
  outline: none;
  width: 36px;
  height: 36px;
  display: block;
  position: relative;
}

.card-favorite-button:before, .card-favorite-button:after {
  content: "";
  transition: background-color var(--transition-in);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.card-favorite-button:after {
  background-color: var(--color-black);
  -webkit-mask-image: url("favorite-button-heart-outline.b0db89a8.svg");
  mask-image: url("favorite-button-heart-outline.b0db89a8.svg");
}

.card-favorite-button:before {
  background-color: var(--color-white);
  -webkit-mask-image: url("favorite-button-heart-bg.87fdd950.svg");
  mask-image: url("favorite-button-heart-bg.87fdd950.svg");
}

.card-favorite-button:hover:after, .card-favorite-button:focus-visible:after, .card-favorite-button.is-active:after, .card-favorite-button.is-active:before {
  background-color: var(--color-accent);
}

.suggestions-nowrap {
  white-space: nowrap;
}

.suggestions-wrapper {
  vertical-align: top;
  margin: 0;
  padding: 0;
  position: relative;
}

.suggestions-suggestions {
  z-index: 9999;
  box-sizing: border-box;
  box-shadow: var(--shadow-dropdown);
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  min-width: 100%;
  padding: 8px 0;
  position: absolute;
  left: 0;
}

.suggestions-suggestions strong {
  color: var(--color-accent);
  font-weight: 550;
}

.suggestions-suggestions.suggestions-mobile {
  border-style: none;
}

.suggestions-suggestion {
  transition: color var(--transition-in);
  padding: 8px 12px;
  overflow: hidden;
}

.suggestions-suggestion:hover {
  color: var(--color-accent);
}

.suggestions-selected, .suggestions-selected:hover {
  background: #f0f0f0;
}

.suggestions-hint {
  color: #777;
  white-space: nowrap;
  padding: 4px;
  font-size: 85%;
  line-height: 20px;
  overflow: hidden;
}

.suggestions-subtext {
  color: #777;
}

.suggestions-subtext_inline {
  vertical-align: bottom;
  min-width: 6em;
  margin: 0 .5em 0 0;
  display: inline-block;
}

.suggestions-subtext-delimiter {
  width: 2px;
  display: inline-block;
}

.suggestions-subtext_label {
  background: #f5f5f5;
  border-radius: 3px;
  margin: 0 0 0 .25em;
  padding: 0 3px;
  font-size: 85%;
}

.suggestions-value[data-suggestion-status="LIQUIDATED"] {
  position: relative;
}

.suggestions-value[data-suggestion-status="LIQUIDATED"]:after {
  content: "";
  border-top: 1px solid #0006;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.suggestions-promo {
  display: none;
}

.select {
  display: block;
  position: relative;
}

.select__label {
  z-index: 1;
  color: var(--color-text-aux);
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  transition: transform .2s var(--ease-in-out-quart);
  transform-origin: 0;
  position: absolute;
  top: 16px;
  left: 16px;
  transform: none;
}

.select.is-focused .select__label {
  transform: translate(1px, -14px)scale(.8);
}

.select__wrapper {
  min-height: 52px;
  display: flex;
  position: relative;
}

.select__wrapper.disabled {
  opacity: .7;
}

select.select__select {
  border: 1px solid var(--color-lines);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: url("chevron-down.82b9bbc2.svg") right 16px center no-repeat;
  border-radius: 8px;
  flex-wrap: wrap;
  width: 100%;
  min-height: 52px;
  padding: 16px 23px 7px 16px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.select__control {
  border: 1px solid var(--color-lines);
  cursor: pointer;
  transition: border-color var(--transition-in), box-shadow var(--transition-in);
  border-radius: 8px;
  flex-wrap: wrap;
  width: 100%;
  min-height: 52px;
  padding: 16px 23px 7px 16px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.select__wrapper:not(.disabled):hover .select__control {
  border-color: var(--color-accent);
}

.select__wrapper.focus .select__control {
  border-color: var(--color-accent);
  box-shadow: 0 0 0 3px #c1232529;
}

.select__wrapper.single .select__control:after {
  content: "";
  background-color: var(--color-grey-3);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  -webkit-mask-image: url("chevron-down.82b9bbc2.svg");
  mask-image: url("chevron-down.82b9bbc2.svg");
}

.select--sm .select__wrapper.single .select__control:after {
  background-color: var(--color-icons);
  transition: transform var(--transition-in);
  right: 12px;
}

.select.has-error .select__control {
  border-color: var(--color-error);
}

.select.has-error .select__control:focus {
  box-shadow: var(--shadow-error);
}

.select__wrapper.single.dropdown-active .select__control:after {
  transform: translateY(-50%)scaleY(-1);
}

.select__control > * {
  vertical-align: baseline;
  display: inline-block;
}

.select__control > input {
  flex: auto;
  min-width: 7rem;
  font-size: 14px;
  line-height: 20px;
  box-shadow: none !important;
  text-indent: 0 !important;
  -webkit-user-select: auto !important;
  user-select: auto !important;
  background: none !important;
  border: 0 !important;
  max-width: 100% !important;
  min-height: 0 !important;
  max-height: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block !important;
}

.select__control > input:focus {
  outline: none !important;
}

.select__control > input::-ms-clear {
  display: none;
}

.select__wrapper.has-items .select__control > input {
  margin: 0 4px !important;
}

.select__wrapper:not(.has-items) .select__control > input, .select__wrapper.input-hidden .select__control > input {
  opacity: 0;
  position: absolute;
  left: -10000px;
}

.select--sm .select__wrapper {
  min-height: 36px;
}

.select--sm .select__label {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.select--sm .select__control, .select--sm select.select__select {
  background-position: right 12px center;
  min-height: 36px;
  padding: 7px 40px 7px 11px;
}

.select__item {
  align-items: center;
  display: flex;
}

.select__dropdown[data-selectable] {
  cursor: pointer;
  overflow: hidden;
}

@media screen and (width <= 1023px) {
  .select__dropdown {
    z-index: var(--z-index-dialog);
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .select__dropdown:before {
    content: "";
    z-index: -1;
    background-color: var(--color-overlay);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .select__dropdown:after {
    content: "";
    z-index: -1;
    background: var(--color-bg);
    border-radius: 16px 16px 0 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 20px #0000001f, 0 0 0 1px #00000014;
  }
}

@media screen and (width >= 1024px) {
  .select__dropdown {
    z-index: 10;
    box-sizing: border-box;
    background: #fff;
    border-top: 0;
    border-radius: 8px;
    min-width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 0 12px #0000001f, 0 0 0 1px #00000014;
  }
}

.select__dropdown-heading {
  content: var(--select-label, "");
  padding: 16px;
  font-size: 16px;
  font-weight: 450;
  display: block;
  position: relative;
}

.select__dropdown-heading:after {
  content: "";
  background-color: var(--color-icons);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  -webkit-mask-image: url("close.37a56065.svg");
  mask-image: url("close.37a56065.svg");
}

@media screen and (width >= 1024px) {
  .select__dropdown-heading {
    display: none;
  }
}

.select__dropdown-content {
  overflow-scrolling: touch;
  scroll-behavior: smooth;
  max-height: 216px;
  padding: 8px 0;
  overflow: hidden auto;
}

.select__option {
  white-space: nowrap;
  cursor: pointer;
  transition: color var(--transition-in);
  padding: 12px 44px 12px 16px;
  position: relative;
}

.select__option:hover, .select__option:focus-visible, .select__option.selected {
  color: var(--color-accent);
}

.select__option.selected:after {
  content: "";
  background-color: var(--color-accent);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  -webkit-mask-image: url("checkmark.bc11b3a4.svg");
  mask-image: url("checkmark.bc11b3a4.svg");
}

@media screen and (width >= 1024px) {
  .select__option {
    padding: 8px 40px 8px 12px;
  }

  .select__option.selected:after {
    right: 8px;
  }
}

.stepper {
  border: 1px solid var(--color-grey-1);
  transition: border-color var(--transition-in);
  border-radius: 8px;
  min-width: 110px;
  display: flex;
  overflow: hidden;
}

.stepper:hover, .stepper:focus-within {
  border-color: var(--color-accent);
}

.stepper:focus-within {
  box-shadow: var(--focus-visible-shadow);
}

.stepper__minus, .stepper__plus {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  outline: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 34px;
  display: flex;
}

.stepper__minus:before, .stepper__plus:before {
  content: "";
  background-color: var(--color-icons);
  transition: background-color var(--transition-in);
  width: 20px;
  height: 20px;
  display: block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.stepper__minus:hover:before, .stepper__plus:hover:before, .stepper__minus:focus-visible:before, .stepper__plus:focus-visible:before {
  background-color: var(--color-accent);
}

.stepper__minus[disabled], .stepper__plus[disabled] {
  cursor: not-allowed;
}

.stepper__minus[disabled]:before, .stepper__plus[disabled]:before {
  background-color: var(--color-grey-2);
}

.stepper__minus:before {
  -webkit-mask-image: url("minus.36b63fc5.svg");
  mask-image: url("minus.36b63fc5.svg");
}

.stepper__plus:before {
  -webkit-mask-image: url("plus.70695ff1.svg");
  mask-image: url("plus.70695ff1.svg");
}

.stepper__input {
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  flex: 1;
  width: 0;
  font-size: 14px;
  font-weight: 450;
  display: block;
}

@media screen and (width >= 1024px) {
  .md\:stepper--m {
    min-width: 132px;
  }

  .md\:stepper--m .stepper__minus, .md\:stepper--m .stepper__plus {
    width: 43px;
    height: 42px;
  }
}

.round-icon {
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  display: flex;
}

.round-icon--accent {
  color: var(--color-accent);
  background-color: #c1232514;
}

.round-icon--success {
  color: var(--color-success);
  background-color: #298e3714;
}

.h1 {
  font-size: 22px;
  font-weight: 550;
  line-height: 1.25;
}

@media screen and (width >= 768px) {
  .h1 {
    font-size: 24px;
    line-height: 1.35;
  }
}

.h2 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 550;
  line-height: 1.25;
}

@media screen and (width >= 768px) {
  .h2 {
    font-size: 22px;
    line-height: 1.35;
  }
}

.h3 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 550;
  line-height: 1.25;
}

@media screen and (width >= 768px) {
  .h3 {
    font-size: 20px;
    line-height: 1.35;
  }
}

.h-panel {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.h-counter {
  vertical-align: 10%;
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 16px;
}

.hamburger {
  color: inherit;
  font: inherit;
  text-transform: none;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: flex;
  overflow: visible;
}

.hamburger-box {
  width: 16px;
  height: 12px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  margin-top: -1px;
  display: block;
  top: 50%;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  background-color: #000;
  border-radius: 0;
  width: 16px;
  height: 2px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.hamburger-inner:before, .hamburger-inner:after {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -5px;
}

.hamburger-inner:after {
  bottom: -5px;
}

.hamburger--collapse .hamburger-inner {
  transition-duration: .13s;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--collapse .hamburger-inner:after {
  transition: top .2s cubic-bezier(.3333, .6666, .6666, 1) .2s, opacity .1s linear;
  top: -10px;
}

.hamburger--collapse .hamburger-inner:before {
  transition: top .12s cubic-bezier(.3333, .6666, .6666, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -5px, 0)rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.3333, 0, .6666, .3333), opacity .1s linear .22s;
  top: 0;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.3333, 0, .6666, .3333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s;
  top: 0;
  transform: rotate(-90deg);
}

.searchbar {
  z-index: 1;
  background-color: var(--color-bg);
  position: relative;
}

@media screen and (width <= 1023px) {
  .searchbar.is-open {
    z-index: 2;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }
}

@media screen and (width >= 1024px) {
  .searchbar {
    border-radius: 28px;
    width: 100%;
    padding: 8px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .searchbar.is-open {
    box-shadow: 0 0 12px #0000001f, 0 0 0 1px #00000014;
  }
}

.searchbar__form-wrap {
  align-items: center;
  display: flex;
}

.searchbar.is-open .searchbar__form-wrap {
  border-bottom: 1px solid var(--color-lines);
  padding: 12px 16px;
}

@media screen and (width >= 768px) {
  .searchbar.is-open .searchbar__form-wrap {
    padding: 12px calc((100vw - var(--size-container-width)) / 2 + 24px);
  }
}

@media screen and (width >= 1024px) {
  .searchbar.is-open .searchbar__form-wrap {
    border-bottom: 0;
    padding: 0;
  }
}

.searchbar__form {
  flex: 1;
  position: relative;
}

.searchbar__cancel {
  color: var(--color-accent);
  -webkit-appearance: none;
  appearance: none;
  flex-shrink: 0;
  margin-left: 8px;
  padding: 10px 0;
}

.searchbar__cancel:hover, .searchbar__cancel:focus-visible {
  color: var(--color-accent-active);
}

.searchbar:not(.is-open) .searchbar__cancel {
  display: none;
}

@media screen and (width >= 1024px) {
  .searchbar__cancel {
    display: none;
  }
}

.searchbar__input {
  border: 1px solid var(--color-grey-1);
  transition: border-color var(--transition-in), box-shadow var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 999px;
  width: 100%;
  min-height: 40px;
  padding: 9px 16px;
  font-size: 16px;
  line-height: 1.3;
}

.searchbar__input:hover {
  border-color: var(--color-accent);
}

.searchbar__input:focus {
  border-color: var(--color-accent);
  box-shadow: 0 0 0 3px #c1232529;
}

.searchbar__input:focus-visible {
  outline: 0;
}

.searchbar__input::placeholder {
  color: var(--color-grey-3);
  opacity: 1;
}

.searchbar.is-open .searchbar__input {
  min-height: 44px;
}

@media screen and (width >= 1024px) {
  .searchbar__input {
    min-height: 44px;
    padding: 11px 16px;
    font-size: 14px;
  }
}

.searchbar:hover .searchbar__input {
  border-color: var(--color-accent);
}

.searchbar__submit {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}

@media screen and (width <= 1023px) {
  .searchbar:not(.is-open) .searchbar__submit {
    display: none;
  }
}

.searchbar__result {
  flex-grow: 1;
  padding: 0 16px;
  overflow-y: auto;
}

.searchbar:not(.is-open) .searchbar__result {
  display: none;
}

@media screen and (width >= 768px) {
  .searchbar__result {
    padding: 0 calc((100vw - var(--size-container-width)) / 2 + 24px);
  }
}

@media screen and (width >= 1024px) {
  .searchbar__result {
    padding: 0 16px;
  }
}

.searchbar__section {
  margin-top: 16px;
  padding-bottom: 8px;
}

.searchbar__section:not(:first-of-type) {
  margin-top: 24px;
}

.searchbar__heading {
  margin-bottom: 8px;
  font-weight: 550;
}

.searchbar__link {
  padding: 8px 0;
  display: block;
}

.searchbar__link mark {
  color: inherit;
  background: none;
  font-weight: 550;
}

@media screen and (width >= 1024px) {
  .searchbar__link {
    padding: 4px 0;
  }
}

.searchbar__link--icon {
  padding-left: 32px;
  position: relative;
}

.searchbar__link--icon:before {
  content: "";
  background-color: var(--color-grey-3);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-mask-image: url("search.b035315a.svg");
  mask-image: url("search.b035315a.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media screen and (width >= 768px) {
  .searchbar__products-list {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 12px;
    display: grid;
  }
}

@media screen and (width <= 767px) {
  .searchbar__product-item {
    margin-bottom: 12px;
  }

  .searchbar__product {
    display: flex;
  }
}

.searchbar__product-image {
  background: var(--color-blocks) url("product-bg-sm.06184246.svg") no-repeat center;
  border-radius: 8px;
  flex-shrink: 0;
  width: 25%;
  margin-right: 8px;
  display: block;
  position: relative;
  overflow: hidden;
}

.searchbar__product-image:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.searchbar__product-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width >= 768px) {
  .searchbar__product-image {
    width: 100%;
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.searchbar__product-title {
  transition: color var(--transition-in);
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 350;
  line-height: 16px;
}

.searchbar__product-title mark {
  color: inherit;
  background: none;
  font-weight: 550;
}

.searchbar__product:hover .searchbar__product-title, .searchbar__product:focus-visible .searchbar__product-title {
  color: var(--color-accent);
}

.searchbar__product-price {
  font-size: 14px;
  font-weight: 550;
  line-height: 20px;
}

.searchbar__all-button {
  width: 100%;
  margin-top: 16px;
}

.catalog-menu {
  --catalog-menu-topbar-height: 56px;
}

@media screen and (width <= 1023px) {
  .catalog-menu {
    z-index: 1;
    background-color: var(--color-bg);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .catalog-menu.is-transition-enter {
    transition: transform .2s var(--ease-in-out-quart);
  }

  .catalog-menu.is-transition-enter-start {
    transform: translateX(-100%);
  }

  .catalog-menu.is-transition-enter-end {
    transform: none;
  }

  .catalog-menu.is-transition-leave {
    transition: transform .1s var(--ease-in-out-quart);
  }

  .catalog-menu.is-transition-leave-start {
    transform: none;
  }

  .catalog-menu.is-transition-leave-end {
    transform: translateX(-100%);
  }
}

@media screen and (width >= 768px) {
  .catalog-menu {
    --catalog-menu-topbar-height: 64px;
  }
}

@media screen and (width >= 1024px) {
  .catalog-menu {
    border-bottom: 1px solid var(--color-grey-1);
    background-color: var(--color-bg);
    position: relative;
    display: block !important;
  }
}

.catalog-menu__inner {
  padding-bottom: var(--size-tabbar-height);
  flex-direction: column;
  height: 100%;
  display: flex;
}

@media screen and (width >= 1024px) {
  .catalog-menu__inner {
    max-width: var(--size-container-width);
    padding: 0 var(--size-container-sidegap);
    width: 100%;
    margin: auto;
    display: block;
  }
}

.catalog-menu__mobile-topbar {
  height: var(--catalog-menu-topbar-height);
  padding: 8px var(--size-container-sidegap);
  border-bottom: 1px solid var(--color-lines);
  align-items: center;
  width: 100%;
  display: flex;
}

@media screen and (width >= 1024px) {
  .catalog-menu__mobile-topbar {
    display: none;
  }
}

.catalog-menu__search {
  border: 1px solid var(--color-grey-1);
  color: var(--color-grey-3);
  text-align: left;
  cursor: text;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 999px;
  width: 100%;
  min-height: 40px;
  margin-right: 49px;
  padding: 9px 16px;
  font-size: 14px;
  line-height: 1.3;
}

.catalog-menu__mobile-heading {
  text-align: center;
  width: 100%;
  font-weight: 550;
}

.catalog-menu__back {
  position: absolute;
  top: 8px;
  left: 8px;
}

@media screen and (width >= 768px) {
  .catalog-menu__back {
    top: 12px;
    left: 16px;
  }
}

.catalog-menu__close {
  position: absolute;
  top: 8px;
  right: 8px;
}

@media screen and (width >= 768px) {
  .catalog-menu__close {
    top: 12px;
    right: 16px;
  }
}

@media screen and (width <= 1023px) {
  .catalog-menu__list {
    padding: 8px var(--size-container-sidegap);
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
}

@media screen and (width >= 1024px) {
  .catalog-menu__list {
    align-items: center;
    margin-bottom: -1px;
    margin-left: -8px;
    display: flex;
  }
}

.catalog-menu__link {
  transition: color var(--transition-in);
  align-items: center;
  width: 100%;
  min-height: 52px;
  padding: 14px 0;
  line-height: 1.2;
  display: flex;
  position: relative;
}

.catalog-menu__link:hover, .catalog-menu__link:focus-visible {
  color: var(--color-accent);
}

.catalog-menu__link.catalog-menu__link--is-special {
  padding: 6px 0;
}

.catalog-menu__link .i {
  flex-shrink: 0;
  margin-right: 8px;
}

@media screen and (width <= 1023px) {
  .catalog-menu__item.has-subsections .catalog-menu__link:after {
    content: "";
    background-color: var(--color-grey-3);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
    -webkit-mask-image: url("chevron-right.c39e3ef6.svg");
    mask-image: url("chevron-right.c39e3ef6.svg");
  }

  .catalog-menu__item:not(:last-child) .catalog-menu__link {
    border-bottom: 1px solid var(--color-lines);
  }
}

@media screen and (width >= 1024px) {
  .catalog-menu__link {
    border-bottom: 2px solid #0000;
    min-height: 53px;
    padding: 0 8px;
  }

  .catalog-menu__item:hover .catalog-menu__link, .catalog-menu__item:focus-within .catalog-menu__link:not(:focus) {
    color: var(--color-accent);
    outline: none;
  }

  .catalog-menu__item.has-subsections:hover .catalog-menu__link, .catalog-menu__item.has-subsections:focus-within .catalog-menu__link:not(:focus) {
    border-color: var(--color-accent);
  }

  .catalog-menu__link.catalog-menu__link--is-special {
    padding: 0 4px;
  }
}

@media screen and (width >= 1200px) {
  .catalog-menu__link {
    padding: 0 12px;
  }
}

.catalog-menu__link-inner {
  align-items: center;
  display: flex;
}

.catalog-menu__link--is-special .catalog-menu__link-inner {
  background-color: var(--color-grey-05);
  border-radius: 999px;
  margin-left: -8px;
  padding: 8px;
}

@media screen and (width >= 1024px) {
  .catalog-menu__link--is-special .catalog-menu__link-inner {
    margin-left: 0;
  }
}

.catalog-menu__item-inner {
  background-color: var(--color-grey-05);
  border-radius: 999px;
  align-items: center;
  min-height: 36px;
  padding: 0 8px;
  display: flex;
}

.catalog-menu__submenu {
  background: var(--color-bg);
}

@media screen and (width <= 1023px) {
  .catalog-menu__submenu {
    z-index: 1;
    background-color: var(--color-bg);
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .catalog-menu__submenu.is-transition-enter {
    transition: transform .2s var(--ease-in-out-quart);
  }

  .catalog-menu__submenu.is-transition-enter-start {
    transform: translateX(100%);
  }

  .catalog-menu__submenu.is-transition-enter-end {
    transform: none;
  }

  .catalog-menu__submenu.is-transition-leave {
    transition: transform .1s var(--ease-in-out-quart);
  }

  .catalog-menu__submenu.is-transition-leave-start {
    transform: none;
  }

  .catalog-menu__submenu.is-transition-leave-end {
    transform: translateX(100%);
  }
}

@media screen and (width >= 1024px) {
  .catalog-menu__submenu {
    width: 100%;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    display: block !important;
  }

  .catalog-menu__submenu:after {
    content: "";
    z-index: -1;
    background-color: #fff;
    width: 100%;
    height: 50%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 12px #0000001f, 0 0 0 1px #00000014;
  }

  .catalog-menu__overlay {
    z-index: -1;
    background-color: var(--color-overlay);
    opacity: 0;
    visibility: hidden;
    transition: opacity .15s var(--ease-in-out-quart), visibility .15s var(--ease-in-out-quart);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .catalog-menu__item.has-subsections:hover ~ .catalog-menu__overlay {
    opacity: 1;
    visibility: visible;
  }
}

.catalog-menu__tabs {
  background: var(--color-grey-05);
  display: block;
}

.catalog-menu__tab {
  white-space: nowrap;
  transition: background-color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  outline: none;
  padding: 16px 8px;
  position: relative;
}

.catalog-menu__tab:after {
  z-index: 1;
  content: attr(data-text);
  color: var(--color-text-main);
  letter-spacing: -.02em;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px 8px;
  font-weight: 550;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.catalog-menu__tab[aria-selected="true"] {
  background: var(--color-grey-1);
  color: #0000;
}

.catalog-menu__tab[aria-selected="true"]:after {
  display: flex;
}

.catalog-menu__tab:hover, .catalog-menu__tab:focus-visible {
  background: var(--color-grey-1);
}

.catalog-menu__tabs-list {
  display: flex;
}

.catalog-menu__submenu-container {
  padding: 16px var(--size-container-sidegap) var(--size-tabbar-height);
  flex-direction: column;
  height: 100%;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.catalog-menu__submenu-container[hidden] {
  display: none;
}

@media screen and (width >= 1024px) {
  .catalog-menu__submenu-container {
    width: var(--size-container-width);
    padding: 24px var(--size-container-sidegap);
    grid-template-rows: auto;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 32px;
    margin: 0 auto;
    display: grid;
    overflow-y: visible;
  }
}

@media screen and (width >= 1200px) {
  .catalog-menu__submenu-container {
    gap: 40px;
  }
}

@media screen and (width >= 1024px) {
  .catalog-menu__sidebar {
    flex-shrink: 0;
    width: 200px;
  }
}

@media screen and (width >= 1200px) {
  .catalog-menu__sidebar {
    width: 240px;
  }
}

.catalog-menu__seeall-mobile {
  width: 100%;
  margin-bottom: 16px;
}

@media screen and (width >= 1024px) {
  .catalog-menu__seeall-mobile {
    display: none;
  }
}

.catalog-menu__seeall-desktop {
  display: none;
}

@media screen and (width >= 1024px) {
  .catalog-menu__seeall-desktop {
    background: var(--color-grey-05);
    transition: var(--transition-in);
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    display: block;
  }

  .catalog-menu__seeall-desktop:hover, .catalog-menu__seeall-desktop:focus-visible {
    background: var(--color-grey-1);
  }

  .catalog-menu__popular {
    background: #ffeaea;
    border-radius: 10px;
    margin-bottom: 16px;
    padding: 10px 10px 6px;
  }
}

@media screen and (width <= 1023px) {
  .catalog-menu__group.has-empty-heading {
    order: 1;
  }
}

@media screen and (width >= 1024px) {
  .catalog-menu__group {
    flex: none;
  }
}

.catalog-menu__group-heading {
  border-bottom: 1px solid var(--color-lines);
  text-align: left;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border-radius: 0;
  outline: 0;
  width: 100%;
  padding: 16px 0;
  font-weight: 550;
  display: block;
  position: relative;
}

@media screen and (width <= 1023px) {
  .catalog-menu__group-heading:after {
    content: "";
    background-color: var(--color-grey-3);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
    -webkit-mask-image: url("chevron-right.c39e3ef6.svg");
    mask-image: url("chevron-right.c39e3ef6.svg");
  }

  .catalog-menu__group-heading[aria-expanded="true"]:after {
    transform: translateY(-50%)rotate(90deg);
  }

  .catalog-menu__group-heading:not(.is-empty)[aria-expanded="false"] + .catalog-menu__sublist, .catalog-menu__group-heading:not(.is-empty)[aria-expanded="false"] + .catalog-menu__popular-sublist, .catalog-menu__group-heading.is-empty {
    display: none;
  }
}

@media screen and (width >= 1024px) {
  .catalog-menu__group-heading {
    border-bottom: 0;
    margin-bottom: 12px;
    padding: 0;
    font-size: 16px;
  }
}

.catalog-menu__group-icon {
  color: var(--color-accent);
  margin-left: 8px;
}

@media screen and (width >= 1024px) {
  .catalog-menu__sublist {
    width: var(--masonry-container-width);
    flex-flow: column wrap;
    height: 450px;
    display: flex;
  }
}

@media screen and (width >= 1200px) {
  .catalog-menu__sublist {
    height: 380px;
  }
}

@media screen and (width >= 1024px) {
  .catalog-menu__subitem {
    max-width: 165px;
    margin-bottom: 4px;
    padding-right: 20px;
  }

  .catalog-menu__popular .catalog-menu__subitem {
    max-width: 100%;
  }
}

.catalog-menu__sublink {
  width: 100%;
  padding: 16px 0;
  line-height: 20px;
  display: block;
  position: relative;
}

.catalog-menu__sublink.is-popular {
  align-items: center;
  display: flex;
}

.catalog-menu__sublink.is-popular:after {
  content: "";
  background-color: var(--color-accent);
  width: 20px;
  height: 20px;
  margin-left: 4px;
  display: block;
  -webkit-mask-image: url("fire-20.2af2d1b1.svg");
  mask-image: url("fire-20.2af2d1b1.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media screen and (width <= 1023px) {
  .catalog-menu__sublink {
    border-bottom: 1px solid var(--color-lines);
  }

  .catalog-menu__subsection:last-child .catalog-menu__subitem:last-child .catalog-menu__sublink {
    border-bottom: 0;
  }
}

@media screen and (width >= 1024px) {
  .catalog-menu__sublink {
    padding: 2px 0;
    display: inline-block;
  }

  .catalog-menu__submenu-container[data-submenu="bukety"] .catalog-menu__sublist {
    height: 378px;
  }

  .catalog-menu__submenu-container[data-submenu="korziny-s-tsvetami"] .catalog-menu__subitem, .catalog-menu__submenu-container[data-submenu="vip-uslugi"] .catalog-menu__subitem {
    max-width: 100%;
  }

  .catalog-menu__submenu-container[data-submenu="stabilizirovannye-tsvety"] .catalog-menu__subitem {
    max-width: 200px;
  }
}

.toast {
  z-index: var(--z-index-notifications);
  background-color: var(--color-bg);
  white-space: nowrap;
  border-radius: 8px;
  align-items: center;
  width: max-content;
  padding: 7px 12px;
  font-weight: 450;
  display: flex;
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 12px #0000001f, 0 0 0 1px #00000014;
}

.toast.is-transition-enter {
  transition: transform .3s var(--ease-in-out-quart), opacity .3s var(--ease-in-out-quart);
}

.toast.is-transition-enter-start {
  opacity: 0;
  transform: translate(-50%, -100%);
}

.toast.is-transition-enter-end {
  opacity: 1;
  transform: translate(-50%);
}

.toast.is-transition-leave {
  transition: opacity .3s var(--ease-in-out-quart);
}

.toast.is-transition-leave-start {
  opacity: 1;
}

.toast.is-transition-leave-end {
  opacity: 0;
}

.toast__icon {
  margin-right: 8px;
}

.toast__text {
  padding: 1px 0;
  display: inline-block;
}

.notification-banner {
  color: #fff;
  text-align: center;
  background-color: #333;
  padding: 10px 0;
}

.notification-banner p + p {
  margin-top: 1em;
}

.notification-banner a {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-grey-3);
  text-decoration-color: var(--color-grey-3);
}

.notification-banner a:hover, .notification-banner a:focus-visible {
  text-decoration-color: #fff;
}

.notification-banner a:focus-visible {
  outline-color: var(--color-grey-3);
}

.notification-banner--is-theme-gray {
  color: #fff;
  background-color: #333;
}

.notification-banner--is-theme-red {
  color: #fff;
  background-color: #a21d1f;
  font-weight: 500;
}

.header {
  z-index: var(--z-index-header);
  position: relative;
}

.header__panel {
  padding-top: var(--size-header-panel-height);
  position: relative;
}

.header__panel-inner {
  background-color: var(--color-bg);
  width: 100%;
  transition: none;
  position: absolute;
  top: 0;
  left: 0;
}

.header__panel-inner.is-detached {
  position: fixed;
  transform: translateY(-100%);
}

.header__panel-inner.is-shown {
  transition: transform .5s var(--ease-in-out-quart);
  transform: none;
}

@media screen and (width >= 1024px) {
  .top-header {
    background-color: var(--color-grey-05);
  }
}

.top-header__inner {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 12px 0;
  display: flex;
}

@media screen and (width >= 1024px) {
  .top-header__inner {
    padding: 8px 0;
  }
}

.top-header__logo {
  text-align: center;
  flex: 1;
  display: block;
}

@media screen and (width >= 1024px) {
  .top-header__logo {
    display: none;
  }
}

.top-header__logo-img {
  max-width: 88px;
  height: auto;
  margin: 0 auto 4px;
}

.top-header__logo-text {
  color: var(--color-grey-3);
  font-size: 12px;
  font-family: var(--font-logo);
  text-align: center;
  line-height: 1.33;
}

@media screen and (width >= 1024px) {
  .top-header__hamburger {
    display: none;
  }
}

@media screen and (width <= 1023px) {
  .top-header__menu-inner {
    z-index: 2;
    background-color: var(--color-bg);
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .top-header__menu-inner.is-transition-enter {
    transition: transform .2s var(--ease-in-out-quart);
  }

  .top-header__menu-inner.is-transition-enter-start {
    transform: translateX(-100%);
  }

  .top-header__menu-inner.is-transition-enter-end {
    transform: none;
  }

  .top-header__menu-inner.is-transition-leave {
    transition: transform .1s var(--ease-in-out-quart);
  }

  .top-header__menu-inner.is-transition-leave-start {
    transform: none;
  }

  .top-header__menu-inner.is-transition-leave-end {
    transform: translateX(-100%);
  }
}

@media screen and (width >= 1024px) {
  .top-header__menu-inner {
    display: block !important;
  }
}

.top-header__menu-heading {
  border-bottom: 1px solid var(--color-lines);
  text-align: center;
  padding: 16px;
  font-size: 16px;
  font-weight: 450;
}

@media screen and (width >= 1024px) {
  .top-header__menu-heading {
    display: none;
  }
}

.top-header__close {
  position: absolute;
  top: 8px;
  right: 16px;
}

@media screen and (width >= 1024px) {
  .top-header__close {
    display: none;
  }
}

@media screen and (width <= 1023px) {
  .top-header__menu-list {
    padding: 8px var(--size-container-sidegap);
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
}

@media screen and (width >= 1024px) {
  .top-header__menu-list {
    align-items: center;
    margin-left: -6px;
    font-size: 13px;
    font-weight: 350;
    display: flex;
  }
}

.top-header__menu-link {
  text-align: left;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  outline: none;
  width: 100%;
  padding: 16px 0;
  line-height: 1.2;
  display: block;
}

.top-header__menu-link.is-special {
  padding: 8px 0;
}

.top-header__menu-link.is-special span {
  background-color: var(--color-grey-05);
  border-radius: 999px;
  margin-left: -8px;
  padding: 8px;
  display: inline-block;
}

@media screen and (width <= 1023px) {
  .top-header__menu-item:not(:last-child) .top-header__menu-link {
    border-bottom: 1px solid var(--color-lines);
  }
}

@media screen and (width >= 1024px) {
  .top-header__menu-link {
    padding: 4px 6px;
    display: inline-block;
  }

  .top-header__menu-link.is-special {
    padding: 0 6px;
  }

  .top-header__menu-link.is-special span {
    background-color: var(--color-grey-1);
    margin-left: 0;
    padding: 4px 8px;
  }
}

.top-header__menu-buttons {
  padding: 8px var(--size-container-sidegap);
  width: 100%;
  display: flex;
}

.top-header__menu-buttons .contact-button {
  flex: 1;
}

.top-header__menu-buttons .contact-button:first-child {
  margin-right: 8px;
}

@media screen and (width >= 1024px) {
  .top-header__menu-buttons {
    display: none;
  }
}

.top-header__contacts {
  display: none;
}

@media screen and (width >= 1024px) {
  .top-header__contacts {
    display: block;
    position: relative;
  }
}

.top-header__contacts-button {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  padding: 4px 24px 4px 4px;
  font-size: 13px;
  font-weight: 350;
  line-height: 16px;
  transition: color .3s ease-out;
  position: relative;
}

.top-header__contacts-button:after {
  content: "";
  transition: transform var(--transition-in);
  background-color: currentColor;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)scaleY(-1);
  -webkit-mask-image: url("chevron-up-16.c1be3416.svg");
  mask-image: url("chevron-up-16.c1be3416.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.top-header__contacts-button:hover, .top-header__contacts-button:focus-visible {
  color: var(--color-accent);
}

.top-header__contacts-button:focus-visible {
  outline-width: 2px;
}

.top-header__contacts-button[aria-expanded="true"]:after {
  transform: translateY(-50%);
}

.top-header__time {
  color: var(--color-grey-3);
  margin-left: 8px;
  display: inline-block;
}

.top-header__time:before {
  content: "|";
  margin-right: 8px;
  display: inline-block;
}

@media screen and (width >= 1024px) {
  .top-header__call-button {
    display: none;
  }
}

.mid-header {
  border-bottom: 1px solid var(--color-grey-1);
  background-color: var(--color-bg);
  padding-bottom: 12px;
}

@media screen and (width >= 1024px) {
  .mid-header {
    padding: 8px 0;
  }
}

.mid-header__inner {
  align-items: center;
  width: 100%;
  display: flex;
}

.mid-header__logo {
  border-radius: 4px;
  display: none;
}

@media screen and (width >= 1024px) {
  .mid-header__logo {
    display: block;
  }
}

.mid-header__logo-text {
  color: var(--color-grey-3);
  font-size: 12px;
  font-family: var(--font-logo);
  line-height: 1.3;
}

.mid-header__catalog-button {
  margin-right: 8px;
}

@media screen and (width >= 1024px) {
  .mid-header__catalog-button {
    display: none;
  }
}

.mid-header__searchbar {
  flex: 1;
  align-self: stretch;
  position: relative;
}

@media screen and (width >= 1024px) {
  .mid-header__searchbar {
    min-height: 60px;
    margin: 0 16px;
  }
}

.mid-header__button {
  min-width: 70px;
}

.mid-header__button:not(:last-of-type) {
  margin-right: 8px;
}

@media screen and (width <= 1023px) {
  .mid-header__button {
    display: none;
  }
}

.footer {
  background-color: var(--color-blocks);
}

@media screen and (width <= 1023px) {
  .footer {
    padding-bottom: var(--size-tabbar-height);
  }

  .product-page-body .footer {
    padding-bottom: calc(var(--size-tabbar-height)  + var(--size-buy-bar-height)  + 0px);
  }
}

.footer__inner {
  padding-top: 24px;
  padding-bottom: 16px;
}

.footer__top {
  margin-bottom: 24px;
}

@media screen and (width >= 768px) {
  .footer__top {
    justify-content: space-between;
    align-self: center;
    display: flex;
  }
}

@media screen and (width >= 1024px) {
  .footer__top {
    border-bottom: 1px solid var(--color-lines);
    padding-bottom: 24px;
  }
}

.footer__logo {
  color: var(--color-text-aux);
  font-size: 12px;
  font-family: var(--font-logo);
  line-height: 1.3;
  display: block;
  position: relative;
}

@media screen and (width <= 767px) {
  .footer__logo {
    text-align: center;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  .footer__logo:after {
    content: "";
    background-color: var(--color-lines);
    width: 64px;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .footer__logo-img {
    margin: 0 auto;
  }
}

.footer__location {
  text-align: center;
}

@media screen and (width >= 768px) {
  .footer__location {
    text-align: right;
  }
}

.footer__address {
  line-height: 1.4;
}

.footer__time {
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 1.3;
}

.footer__navigation {
  border-bottom: 1px solid var(--color-lines);
  margin-bottom: 16px;
  padding-bottom: 16px;
}

@media screen and (width >= 768px) {
  .footer__navigation {
    padding-bottom: 24px;
  }
}

@media screen and (width >= 1024px) {
  .footer__navigation {
    gap: var(--size-gap);
    grid-template: "communication buyers company"
    / 6fr 3fr 3fr;
    display: grid;
  }

  .footer__menu[data-footer-menu="#buyers"] {
    grid-area: buyers;
  }

  .footer__menu[data-footer-menu="#company"] {
    border-bottom: 1px solid var(--color-lines);
    margin-bottom: 24px;
  }
}

@media screen and (width >= 1024px) and (width >= 1024px) {
  .footer__menu[data-footer-menu="#company"] {
    border-bottom: 0;
    grid-area: company;
    margin-bottom: 0;
  }
}

.footer__menu--company {
  border-bottom: 1px solid var(--color-lines);
  margin-bottom: 24px;
}

@media screen and (width >= 1024px) {
  .footer__menu--company {
    border-bottom: 0;
    grid-area: company;
    margin-bottom: 0;
  }
}

@media screen and (width <= 1023px) {
  .footer__menu-heading {
    border-top: 1px solid var(--color-lines);
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: color var(--transition-in);
    padding: 16px 36px 16px 0;
    font-weight: 550;
    position: relative;
  }

  .footer__menu-heading:hover {
    color: var(--color-accent);
  }

  .footer__menu-heading:after {
    content: "";
    background: url("chevron-down.82b9bbc2.svg") center no-repeat;
    width: 20px;
    height: 20px;
    transition: transform .1s ease-out;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .footer__menu.is-open .footer__menu-heading:after {
    transform: translateY(-50%)rotate(180deg);
  }
}

@media screen and (width >= 1024px) {
  .footer__menu-heading {
    color: var(--color-text-aux);
    margin-bottom: 6px;
  }
}

@media screen and (width <= 1023px) {
  .footer__menu:not(.is-open) .footer__menu-list {
    clip: rect(0 0 0 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
  }
}

.footer__menu-link {
  border-top: 1px solid var(--color-lines);
  padding: 16px 36px 16px 0;
  display: block;
}

@media screen and (width >= 1024px) {
  .footer__menu-link {
    border-top: 0;
    padding: 6px 0;
  }
}

.footer__communication {
  border-top: 1px solid var(--color-lines);
  padding-top: 24px;
}

@media screen and (width >= 767px) and (width <= 1023px) {
  .footer__communication {
    justify-content: space-between;
    display: flex;
  }
}

@media screen and (width >= 1024px) {
  .footer__communication {
    border-top: 0;
    grid-area: communication;
    padding-top: 0;
  }
}

.footer__heading {
  color: var(--color-text-aux);
  margin-bottom: 8px;
}

.footer__contacts {
  text-align: center;
  margin-bottom: 16px;
}

@media screen and (width >= 768px) {
  .footer__contacts {
    text-align: left;
    margin-bottom: 0;
  }
}

@media screen and (width >= 1024px) {
  .footer__contacts {
    margin-bottom: 24px;
  }
}

@media screen and (width >= 768px) {
  .footer__contacts-list {
    gap: 8px;
    display: flex;
  }
}

.footer__contact {
  border: 1px solid var(--color-lines);
  transition: color var(--transition-in);
  border-radius: 7px 8px;
  justify-content: center;
  align-self: center;
  padding: 12px;
  line-height: 20px;
  display: flex;
}

.footer__contact img {
  flex-shrink: 0;
  margin-right: 8px;
}

.footer__contact:hover, .footer__contact:focus-visible {
  color: var(--color-accent);
}

.footer__contact:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: 0;
}

@media screen and (width <= 767px) {
  .footer__contact {
    margin-bottom: 8px;
  }
}

.footer__socials {
  text-align: center;
}

@media screen and (width >= 768px) {
  .footer__socials {
    text-align: right;
  }
}

@media screen and (width >= 1024px) {
  .footer__socials {
    text-align: left;
  }
}

.footer__socials-list {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

@media screen and (width >= 768px) {
  .footer__socials-list {
    justify-content: flex-end;
  }
}

@media screen and (width >= 1024px) {
  .footer__socials-list {
    justify-content: flex-start;
  }
}

.footer__social {
  background-color: var(--color-grey-1);
  transition: color var(--transition-in);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  display: inline-flex;
}

.footer__social:hover, .footer__social:focus-visible {
  color: var(--color-accent);
}

.footer__social:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: 0;
}

.footer__socials-tip {
  color: var(--color-text-aux);
  margin-top: 8px;
  font-size: 10px;
}

.footer__bottom {
  border-bottom: 1px solid var(--color-lines);
  color: var(--color-text-aux);
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  font-size: 13px;
  line-height: 1.2;
  display: flex;
}

@media screen and (width >= 768px) {
  .footer__bottom {
    flex-direction: row;
  }
}

.footer__legal {
  text-align: center;
}

@media screen and (width >= 768px) {
  .footer__legal {
    text-align: left;
  }
}

.footer__copyright {
  margin-bottom: 8px;
}

@media screen and (width >= 1024px) {
  .footer__copyright {
    margin-bottom: 0;
    display: inline-block;
  }
}

.footer__policy {
  margin-bottom: 8px;
  display: block;
}

@media screen and (width >= 768px) {
  .footer__policy {
    margin-bottom: 0;
  }
}

@media screen and (width >= 1024px) {
  .footer__policy {
    margin-left: 12px;
    display: inline-block;
  }
}

.footer__paysystems {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

@media screen and (width >= 768px) {
  .footer__paysystems {
    margin-left: auto;
  }
}

.footer__partners {
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  font-size: 13px;
  line-height: 16px;
  display: flex;
}

@media screen and (width >= 768px) {
  .footer__partners {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__dvebukvi {
  color: var(--color-text-aux);
  align-items: center;
  margin-bottom: 6px;
  display: inline-flex;
}

.footer__dvebukvi img {
  margin-left: 8px;
}

@media screen and (width >= 768px) {
  .footer__dvebukvi {
    margin-bottom: 0;
  }

  .footer__dvebukvi img {
    order: -1;
    margin-left: 0;
    margin-right: 8px;
  }
}

.footer__altera {
  color: var(--color-text-aux);
  align-items: center;
  display: inline-flex;
}

.footer__altera img {
  margin-top: 2px;
  margin-left: 8px;
}

.tabbar {
  z-index: var(--z-index-tabbar);
  min-height: var(--size-tabbar-height);
  border-top: 1px solid var(--color-lines);
  background: var(--color-bg);
  grid-template-columns: repeat(4, minmax(0, 1fr));
  width: 100%;
  display: grid;
  position: fixed;
  bottom: 0;
  left: 0;
}

.tabbar.is-on-top {
  z-index: var(--z-index-tabbar-on-top);
}

@media screen and (width >= 1024px) {
  .tabbar {
    display: none;
  }
}

.main-banner {
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 48px;
  padding-bottom: 53px;
  position: relative;
  overflow: hidden;
}

.main-banner__inner {
  -webkit-user-select: none;
  user-select: none;
  height: 400px;
  position: relative;
}

@media screen and (width >= 1024px) {
  .main-banner__inner {
    height: 480px;
  }
}

.main-banner__img {
  pointer-events: none;
  display: block;
}

.main-banner__img img {
  width: auto;
  max-width: none;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.main-banner__link {
  height: 100%;
  display: block;
}

.main-banner__container {
  height: 100%;
  padding-top: 48px;
  display: block;
}

@media screen and (width >= 1024px) {
  .main-banner__container {
    padding-top: 96px;
  }
}

.main-banner__heading {
  font-family: Spectral, serif;
  font-size: 24px;
  line-height: 28px;
  position: relative;
}

@media screen and (width >= 768px) {
  .main-banner__heading {
    font-size: 40px;
    line-height: 44px;
  }
}

.main-banner__badge-wrap {
  bottom: 0;
  left: var(--size-container-offset-left);
  position: absolute;
  transform: translateY(50%);
}

.main-banner__badge {
  border: 1px solid var(--color-lines);
  background-color: var(--color-bg);
  border-radius: 16px;
  width: 100%;
  padding: 12px;
  display: inline-block;
}

@media screen and (width >= 380px) {
  .main-banner__badge {
    width: auto;
  }
}

.category-cards__swiper {
  margin: 0 -3px;
  padding: 3px;
  overflow: visible;
}

@media screen and (width >= 1024px) {
  .category-cards__swiper {
    overflow: hidden;
  }
}

.category-cards__list {
  justify-content: space-between;
  display: flex;
}

.category-cards__item {
  width: 136px;
  height: 192px;
  margin-right: 8px;
}

@media screen and (width >= 768px) {
  .category-cards__item {
    width: 168px;
    height: 224px;
    margin-right: 12px;
  }
}

@media screen and (width >= 1024px) {
  .category-cards__item {
    flex: 1;
    width: auto;
    height: 244px;
    margin-right: 16px;
  }

  .category-cards__item:last-child {
    margin-right: 0;
  }
}

.category-cards__card {
  background: var(--color-grey-05);
  -webkit-user-select: none;
  user-select: none;
  isolation: isolate;
  border-radius: 8px;
  outline: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: block;
  position: relative;
  overflow: hidden;
}

.category-cards__card:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: none;
}

.category-cards__img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.category-cards__title {
  transition: color var(--transition-in);
  font-size: 16px;
  font-weight: 550;
  line-height: 1.25;
  position: relative;
}

.category-cards__card:hover .category-cards__title, .category-cards__card:focus-visible .category-cards__title {
  color: var(--color-accent);
}

.badge {
  background-color: var(--color-accent);
  color: #fff;
  border-radius: 4px;
  min-height: 20px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 550;
  line-height: 1.3;
  display: inline-block;
}

.badge--slim {
  min-height: 16px;
  padding: 0 4px;
  font-size: 11px;
  line-height: 16px;
}

.badge--black {
  color: #ffea00;
  background-color: #000;
}

.badge--is-new {
  background-color: #298e37;
}

.badge--is-preorder {
  background-color: #ffa600;
}

.badge--is-discount {
  background-color: var(--color-marketing);
}

.badge--is-warranty {
  background-color: var(--color-marketing-warranty);
  pointer-events: auto;
}

.badge--is-archived {
  --bg-main-color: var(--color-yellow);
  background: linear-gradient(to bottom, transparent 0, transparent 3px, var(--bg-main-color) 3px, var(--bg-main-color) calc(100% - 3px), transparent calc(100% - 3px)), repeating-linear-gradient(45deg, var(--bg-main-color), var(--bg-main-color) 3px, var(--color-text-main) 3px, var(--color-text-main) 6px);
  color: var(--color-text-main);
}

.badge-counter {
  background-color: var(--color-accent);
  color: #fff;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: 550;
  line-height: 16;
  display: inline-flex;
}

.badge-counter--md {
  width: 20px;
  height: 20px;
}

.feature-card {
  background-color: var(--color-grey-05);
  border-radius: 8px;
  flex-direction: column;
  min-height: 144px;
  padding: 12px;
  font-size: 13px;
  font-weight: 450;
  line-height: 1.4;
  display: flex;
  position: relative;
}

@media screen and (width >= 1024px) {
  .feature-card {
    padding: 16px;
  }

  .feature-card:before {
    top: 16px;
    left: 16px;
  }
}

.feature-card__icon {
  background-color: var(--color-accent);
  width: 20px;
  height: 20px;
  margin-bottom: 8px;
  display: block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.feature-card__text {
  margin-bottom: 22px;
}

.feature-card__more {
  align-self: flex-start;
  margin-top: auto;
}

.product-card {
  color: var(--color-text-main);
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.product-card--has-border {
  background: #fff;
  border-radius: 8px;
  padding: 4px;
  overflow: hidden;
}

@media screen and (width >= 450px) {
  .product-card--is-horizontal {
    flex-direction: row;
    align-items: stretch;
  }
}

.product-card__info {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.product-card--has-border .product-card__info {
  padding: 0 8px 8px;
}

@media screen and (width >= 450px) {
  .product-card--is-horizontal .product-card__info {
    flex: 0 0 62%;
    padding: 8px 8px 8px 12px;
  }
}

.product-card__link {
  transition: color var(--transition-in);
  width: 100%;
  display: block;
  overflow: hidden;
}

.product-card__link:hover, .product-card__link:focus-visible {
  color: var(--color-accent);
}

.product-card__link:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: none;
}

.product-card__slider {
  --swiper-theme-color: var(--color-accent);
  --swiper-pagination-bullet-inactive-color: var(--color-grey-2);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 6px;
  --swiper-pagination-bullet-height: 6px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  width: 100%;
  margin-bottom: 8px;
  padding-bottom: 16px;
  display: block;
  position: relative;
  overflow: hidden;
}

.product-card__slider .swiper-pagination {
  justify-content: center;
  line-height: 8px;
  display: flex;
  bottom: 0;
}

.product-card__slide {
  -webkit-user-select: none;
  user-select: none;
  display: block;
  width: 100% !important;
}

.product-card__image-wrap {
  width: 100%;
  margin-bottom: 8px;
  position: relative;
}

@media screen and (width >= 450px) {
  .product-card--is-horizontal .product-card__image-wrap {
    margin-bottom: 0;
  }
}

.product-card__image {
  background: var(--color-blocks) url("product-bg-md.02e18dd6.svg") no-repeat center;
  background-size: contain;
  border-radius: 8px;
  display: block;
  position: relative;
  overflow: hidden;
}

.product-card__image:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.product-card__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.product-card__favorite {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
}

.product-card__title {
  font-size: 14px;
  line-height: 20px;
}

.product-card--is-slim .product-card__title {
  font-size: 12px;
  line-height: 16px;
}

@media screen and (width <= 767px) {
  .product-card__title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
    line-height: 16px;
    display: -webkit-box;
    overflow: hidden;
  }
}

@media screen and (width >= 450px) {
  .product-card__title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.product-card__badges {
  z-index: 1;
  pointer-events: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.product-card__badges:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.product-card__badges-list {
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding: 8px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (width >= 768px) {
  .product-card__badges-list {
    padding: 12px;
  }

  .product-card--is-slim .product-card__badges-list {
    padding: 8px;
  }
}

.product-card__prices {
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.product-card__price {
  font-size: 14px;
  font-weight: 550;
  line-height: 20px;
}

.product-card__price.is-discount {
  color: var(--color-marketing);
}

.product-card--is-slim .product-card__price {
  font-size: 14px;
  line-height: 20px;
}

@media screen and (width >= 768px) {
  .product-card__price {
    font-size: 16px;
    line-height: 24px;
  }
}

.product-card__old-price {
  color: var(--color-grey-3);
  margin-left: 4px;
  padding-top: 2px;
  font-size: 11px;
  font-weight: 450;
  line-height: 16px;
  text-decoration: line-through;
}

@media screen and (width >= 768px) {
  .product-card__old-price {
    font-size: 12px;
    line-height: 16px;
  }
}

.product-card__bundle-content {
  color: var(--color-accent);
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.product-card--is-slim .product-card__bundle-content .i {
  width: 16px;
  height: 16px;
}

.product-card__bundle-content-text {
  border-bottom: 1px solid #0000;
  margin-left: 8px;
  padding-top: 2px;
  line-height: 20px;
}

.product-card__bundle-content:hover .product-card__bundle-content-text, .product-card__bundle-content-text .product-card__bundle-content:focus-visible {
  border-color: var(--color-accent);
}

.product-card--is-slim .product-card__bundle-content-text {
  margin-left: 4px;
  font-size: 13px;
  line-height: 18px;
}

.product-card__buy {
  width: 100%;
  margin-top: 16px;
}

.product-card--is-slim .product-card__buy {
  margin-top: 12px;
}

@media screen and (width >= 450px) {
  .product-card--is-horizontal .product-card__buy {
    width: 110px;
  }
}

.products-slider {
  position: relative;
}

.products-slider__swiper {
  margin: 0 -3px;
  padding: 3px;
  overflow: visible;
}

@media screen and (width >= 1024px) {
  .products-slider__swiper {
    overflow: hidden;
  }
}

.products-slider__slide {
  width: 138px;
  max-width: 138px;
  height: auto;
  margin-right: 8px;
  display: flex;
}

@media screen and (width >= 768px) {
  .products-slider__slide {
    width: 228px;
    max-width: 228px;
    margin-right: 12px;
  }
}

@media screen and (width >= 1024px) {
  .products-slider__slide {
    max-width: 100%;
    margin-right: 16px;
  }
}

@media screen and (width >= 1200px) {
  .products-slider__slide {
    width: 268px;
  }
}

.products-slider__slim-slide {
  max-width: 112px;
  height: auto;
  display: flex;
}

@media screen and (width >= 768px) {
  .products-slider__slim-slide {
    max-width: 132px;
  }
}

@media screen and (width >= 1024px) {
  .products-slider__slim-slide {
    max-width: 100%;
  }
}

.products-slider__card {
  width: 100%;
  height: 100%;
}

.products-slider__nav {
  display: none;
}

@media screen and (width >= 1024px) {
  .products-slider__nav {
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: opacity var(--transition-in), visibility var(--transition-in);
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    display: flex;
    position: absolute;
    top: 96px;
    box-shadow: 0 0 12px #0000001f, 0 0 0 1px #00000014;
  }

  .products-slider__nav.products-slider__nav--slim {
    top: 50%;
    transform: translateY(-50%);
  }

  .products-slider__nav .i {
    background-color: var(--color-grey-4);
  }

  .products-slider__nav.swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }

  .products-slider__nav:hover .i, .products-slider__nav:focus-visible .i {
    background-color: var(--color-accent);
  }

  .products-slider__nav:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }
}

@media screen and (width >= 1200px) {
  .products-slider__nav {
    top: 112px;
  }
}

.products-slider__nav--prev {
  left: -15px;
}

.products-slider__nav--next {
  right: -15px;
}

.products-slider-preview {
  display: flex;
  position: relative;
  overflow: visible;
}

@media screen and (width >= 1024px) {
  .products-slider-preview {
    overflow: hidden;
  }
}

.products-slider-preview a {
  color: var(--color-grey-2);
  width: 138px;
  height: 309px;
  margin-right: 8px;
  display: block;
  position: relative;
}

.products-slider-preview a:before {
  content: "";
  background: linear-gradient(90deg, var(--color-grey-1) 0, var(--color-grey-05) 40%, var(--color-grey-1) 60%, var(--color-grey-1) 100%);
  aspect-ratio: 1;
  background-size: 200% 200%;
  border-radius: 8px;
  width: 100%;
  animation: 3s infinite placeholder;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.products-slider-preview a:after {
  content: "";
  background: linear-gradient(90deg, var(--color-grey-1) 0, var(--color-grey-05) 40%, var(--color-grey-1) 60%, var(--color-grey-1) 100%);
  background-size: 200% 200%;
  border-radius: 8px;
  width: 100%;
  height: 36px;
  animation: 3s infinite placeholder;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (width >= 768px) {
  .products-slider-preview a {
    width: 228px;
    height: 391px;
    margin-right: 12px;
  }
}

@media screen and (width >= 1024px) {
  .products-slider-preview a {
    margin-right: 16px;
  }
}

@media screen and (width >= 1200px) {
  .products-slider-preview a {
    width: 268px;
    height: 431px;
    padding-top: 334px;
    padding-bottom: 86px;
  }
}

@keyframes placeholder {
  0% {
    background-position: 100% 0;
  }

  50% {
    background-position: 0 0;
  }

  100% {
    background-position: -100% 0;
  }
}

@media screen and (width >= 1024px) {
  .product-gallery {
    grid-template-columns: 64px minmax(0, 1fr);
    align-items: flex-start;
    gap: 16px;
    display: grid;
  }
}

@media screen and (width >= 1200px) {
  .product-gallery {
    grid-template-rows: 567px;
  }
}

.product-gallery__thumbnails {
  display: none;
}

@media screen and (width >= 1024px) {
  .product-gallery__thumbnails {
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    max-height: 468px;
    display: flex;
    position: relative;
  }

  .product-gallery__thumbnails .swiper {
    height: 100%;
  }
}

.product-gallery__thumbnail {
  border: 1px solid var(--color-grey-1);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: border-color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 8px;
  width: 64px;
  height: 64px;
  padding: 4px;
  position: relative;
  overflow: hidden;
}

.product-gallery__thumbnail:hover, .product-gallery__thumbnail:focus-visible {
  border-color: var(--color-grey-2);
  outline: none;
}

.product-gallery__thumbnail.swiper-slide-thumb-active {
  border-color: var(--color-accent);
}

.product-gallery__thumbnail-img {
  background-color: var(--color-blocks);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.product-gallery__thumbnail-img.is-video:after {
  content: "";
  background: url("video-play.5d15f1da.svg") center / contain no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-gallery__thumbnail-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.product-gallery__thumbnails-prev, .product-gallery__thumbnails-next {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  place-content: center;
  width: 64px;
  height: 28px;
  display: flex;
}

.product-gallery__thumbnails-prev:hover, .product-gallery__thumbnails-next:hover, .product-gallery__thumbnails-prev:focus-visible, .product-gallery__thumbnails-next:focus-visible {
  color: var(--color-accent);
}

.product-gallery__thumbnails-prev.swiper-button-disabled, .product-gallery__thumbnails-next.swiper-button-disabled {
  color: var(--color-grey-2);
}

.product-gallery__thumbnails-prev {
  margin-bottom: 4px;
}

.product-gallery__thumbnails-next {
  margin-top: 4px;
}

.product-gallery__prev, .product-gallery__next {
  display: none;
}

@media screen and (width >= 1024px) {
  .product-gallery__prev, .product-gallery__next {
    z-index: 1;
    color: #fff;
    opacity: 0;
    visibility: visible;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: opacity var(--transition-in), visibility var(--transition-in);
    -webkit-appearance: none;
    appearance: none;
    place-content: center;
    width: 44px;
    height: 44px;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .product-gallery__prev .i, .product-gallery__next .i {
    width: 32px;
    height: 32px;
  }

  .product-gallery__slider:hover .product-gallery__prev, .product-gallery__slider:hover .product-gallery__next {
    opacity: 1;
  }

  .product-gallery__slider:hover .product-gallery__prev.swiper-button-disabled, .product-gallery__slider:hover .product-gallery__next.swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }
}

.product-gallery__prev {
  left: 8px;
}

.product-gallery__next {
  right: 8px;
}

@media screen and (width >= 768px) {
  .product-gallery__slider .swiper {
    border-radius: 8px;
    overflow: hidden;
  }
}

@media screen and (width >= 1024px) {
  .product-gallery__slider .swiper {
    padding-bottom: 0;
  }

  .product-gallery__slider .swiper-pagination {
    display: none;
  }
}

.product-gallery__img {
  background: url("product-bg.aae4b732.svg") center no-repeat;
  max-width: 576px;
  max-height: 576px;
  margin: 0 auto;
  display: block;
  position: relative;
  overflow: hidden;
}

.product-gallery__img:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.product-gallery__img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.product-gallery__img.is-small img {
  object-fit: scale-down;
}

@media screen and (width >= 768px) {
  .product-gallery__img {
    border-radius: 8px;
  }
}

.product-params__row:not(:last-child) {
  margin-bottom: 8px;
}

.product-params__name {
  float: left;
  color: var(--color-text-aux);
  margin-right: 8px;
}

.bundle-contents {
  border-collapse: collapse;
  width: 100%;
}

.bundle-contents__row {
  position: relative;
}

.bundle-contents__name-cell {
  padding: 0;
}

.bundle-contents__value-cell {
  vertical-align: bottom;
  text-align: right;
  padding: 0;
}

.bundle-contents__row:not(:first-child) .bundle-contents__name-cell, .bundle-contents__row:not(:first-child) .bundle-contents__value-cell {
  padding-top: 8px;
}

.bundle-contents__name {
  background-color: var(--color-bg);
  padding-right: 4px;
}

.bundle-contents__name:after {
  content: "";
  z-index: -1;
  background: url("dots.fbc4682f.svg") 0 repeat-x;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: .5em;
  left: 0;
}

.bundle-contents__value {
  background-color: var(--color-bg);
  white-space: nowrap;
  padding-left: 4px;
  display: inline-block;
}

.breadcrumbs {
  overflow: hidden;
}

.breadcrumbs__list {
  align-items: center;
  display: flex;
}

.breadcrumbs__swiper {
  overflow: visible;
}

.breadcrumbs__link {
  white-space: nowrap;
  padding: 16px 20px 16px 4px;
  display: block;
  position: relative;
}

.breadcrumbs__link:after {
  content: "";
  background-color: var(--color-grey-3);
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-mask-image: url("chevron-right-16.38652074.svg");
  mask-image: url("chevron-right-16.38652074.svg");
}

.breadcrumbs__item:first-child .breadcrumbs__link {
  align-items: center;
  padding-left: 0;
  display: flex;
}

.breadcrumbs__item:last-child .breadcrumbs__link:after {
  display: none;
}

.filter-chips__swiper {
  overflow: visible;
}

@media screen and (width <= 1023px) {
  .filter-chips__swiper {
    overflow: initial;
  }

  .filter-chips__swiper .swiper-slide {
    width: auto;
  }
}

.filter-chips__list {
  align-items: center;
  display: flex;
}

.filter-chips__list > * {
  margin-right: 4px;
}

@media screen and (width >= 768px) {
  .filter-chips__list {
    flex-wrap: wrap;
  }

  .filter-chips__list > * {
    margin-bottom: 4px;
  }
}

.filter-chips__item {
  border: 1px solid var(--color-lines);
  white-space: nowrap;
  border-radius: 999px;
  align-items: center;
  min-height: 36px;
  padding: 6px 8px;
  display: flex;
}

.filter-chips__name {
  color: var(--color-text-aux);
}

.filter-chips__value {
  margin-left: 4px;
}

.filter-chips__clear {
  background-color: var(--color-grey-2);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 9px;
  display: inline-flex;
  -webkit-mask-image: url("clear-fill.5a7a2708.svg");
  mask-image: url("clear-fill.5a7a2708.svg");
}

.filter-chips__clear:hover, .filter-chips__clear:active, .filter-chips__clear:focus-visible {
  background-color: var(--color-grey-3);
}

.filter-chips__clear:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: none;
}

@media screen and (width <= 1023px) {
  .filter {
    z-index: var(--z-index-dialog);
    background-color: var(--color-bg);
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .filter.is-transition-enter {
    transition: transform .2s var(--ease-in-out-quart);
  }

  .filter.is-transition-enter-start {
    transform: translateX(-100%);
  }

  .filter.is-transition-enter-end {
    transform: none;
  }

  .filter.is-transition-leave {
    transition: transform .1s var(--ease-in-out-quart);
  }

  .filter.is-transition-leave-start {
    transform: none;
  }

  .filter.is-transition-leave-end {
    transform: translateX(-100%);
  }
}

@media screen and (width >= 1024px) {
  .filter {
    display: block !important;
  }
}

.filter__mobile-topbar {
  height: var(--mobile-menu-topbar-height);
  padding: 0 var(--size-container-sidegap);
  border-bottom: 1px solid var(--color-lines);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

@media screen and (width >= 1024px) {
  .filter__mobile-topbar {
    display: none;
  }
}

.filter__mobile-heading {
  text-align: center;
  font-weight: 450;
}

.filter__mobile-clear {
  color: var(--color-accent);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  min-height: 44px;
  padding: 0 8px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.filter__mobile-clear:hover, .filter__mobile-clear:focus-visible {
  color: var(--color-accent-active);
}

.filter__mobile-back {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

@media screen and (width >= 768px) {
  .filter__mobile-back {
    left: 16px;
  }
}

.filter__mobile-close {
  position: absolute;
  top: 8px;
  right: 8px;
}

@media screen and (width >= 768px) {
  .filter__mobile-close {
    top: 12px;
    right: 16px;
  }
}

@media screen and (width <= 1023px) {
  .filter__content {
    padding: 0 var(--size-container-sidegap);
    flex-grow: 1;
    overflow: hidden auto;
  }

  .filter__content-inner {
    padding: 16px 0;
  }
}

.filter__footer {
  padding: 12px var(--size-container-sidegap);
  border-top: 1px solid var(--color-lines);
  background-color: var(--color-bg);
}

@media screen and (width >= 1024px) {
  .filter__footer {
    display: none;
  }
}

.filter__applied-filters {
  margin-bottom: 8px;
  overflow: visible;
}

@media screen and (width >= 1024px) {
  .filter__applied-filters {
    display: none;
  }
}

.filter__section {
  border-bottom: 1px solid var(--color-lines);
}

.filter__section-title {
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 16px 24px 16px 0;
  display: flex;
  position: relative;
}

.filter__section-title:after {
  content: "";
  background-color: var(--color-grey-3);
  transition: transform var(--transition-in);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-mask-image: url("chevron-right.c39e3ef6.svg");
  mask-image: url("chevron-right.c39e3ef6.svg");
}

@media screen and (width >= 1024px) {
  .filter__section-title {
    padding: 12px 24px 12px 0;
    font-weight: 550;
  }

  .filter__section-title:after {
    -webkit-mask-image: url("chevron-down.82b9bbc2.svg");
    mask-image: url("chevron-down.82b9bbc2.svg");
  }

  .filter__section-title[aria-expanded="true"]:after {
    transform: translateY(-50%)scaleY(-1);
  }
}

@media screen and (width <= 1023px) {
  .filter__section-title-inner {
    align-items: center;
    display: flex;
  }

  .filter__section-title-inner:after {
    content: "";
    background-color: var(--color-accent);
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin-left: 8px;
  }

  .filter__section-body {
    z-index: 1;
    background-color: var(--color-bg);
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .filter__section-body.is-transition-enter {
    transition: transform .2s var(--ease-in-out-quart);
  }

  .filter__section-body.is-transition-enter-start {
    transform: translateX(100%);
  }

  .filter__section-body.is-transition-enter-end {
    transform: none;
  }

  .filter__section-body.is-transition-leave {
    transition: transform .1s var(--ease-in-out-quart);
  }

  .filter__section-body.is-transition-leave-start {
    transform: none;
  }

  .filter__section-body.is-transition-leave-end {
    transform: translateX(100%);
  }
}

@media screen and (width >= 1024px) {
  .filter__section-body {
    padding-bottom: 16px;
    display: block;
  }
}

.filter__category-item:not(:last-child) {
  margin-bottom: 4px;
}

@media screen and (width >= 1024px) {
  .filter__categories {
    max-height: 288px;
    overflow: hidden auto;
  }
}

.filter__category-group-button + .filter__category-list {
  margin-top: 4px;
}

.filter__category-group-button {
  padding: 16px 28px 16px calc(8px * var(--depth, 0));
  border-bottom: 1px solid var(--color-lines);
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  width: 100%;
  position: relative;
}

.filter__category-group-button:after {
  content: "";
  background-color: var(--color-grey-4);
  transition: transform var(--transition-in);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-mask-image: url("chevron-down.82b9bbc2.svg");
  mask-image: url("chevron-down.82b9bbc2.svg");
}

.filter__category-group-button[aria-expanded="true"]:after {
  transform: translateY(-50%)scaleY(-1);
}

@media screen and (width >= 1024px) {
  .filter__category-group-button {
    border: 0;
    padding: 4px 8px;
  }

  .filter__category-group-button:after {
    right: 8px;
  }
}

.filter__category-link {
  display: block;
  position: relative;
}

@media screen and (width <= 1023px) {
  .filter__category-link {
    padding: 16px 28px 16px calc(8px * var(--depth, 0));
  }

  .filter__category-link:before {
    content: "";
    border: 1px solid var(--color-lines);
    transition: border-color var(--transition-in), background-color var(--transition-in);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .filter__category-link:after {
    content: "";
    background-color: #fff;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }

  .filter__category-link:hover, .filter__category-link:focus-visible {
    outline: none;
  }

  .filter__category-link:hover:before, .filter__category-link:focus-visible:before {
    border-color: var(--color-accent);
  }

  .filter__category-link[aria-current="page"]:before {
    border-color: var(--color-accent);
    background-color: var(--color-accent);
  }

  .filter__category-item:not(:last-child) .filter__category-link {
    border-bottom: 1px solid var(--color-lines);
  }
}

@media screen and (width >= 1024px) {
  .filter__category-link {
    padding: 4px 8px;
  }

  .filter__category-link[aria-current="page"] {
    background-color: var(--color-grey-05);
    border-radius: 4px;
  }

  .filter__category-link.is-grouped-link {
    padding-left: 16px;
  }

  .filter__category-link.is-back {
    padding-left: 24px;
    position: relative;
  }

  .filter__category-link.is-back:before {
    content: "";
    background-color: var(--color-icons);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-mask-image: url("chevron-left.6cd6e149.svg");
    mask-image: url("chevron-left.6cd6e149.svg");
  }

  .filter__checkboxes > :not(:last-child) {
    margin-bottom: 4px;
  }
}

.filter__checkbox {
  cursor: pointer;
  display: block;
}

@media screen and (width <= 1023px) {
  .filter__checkbox {
    padding: 16px 0;
  }

  .filter__checkbox:not(:last-child) {
    border-bottom: 1px solid var(--color-lines);
  }
}

@media screen and (width >= 1024px) {
  .filter__checkbox {
    padding: 4px 0;
  }
}

.filter__checkbox-input {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.filter__checkbox-label {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding-right: 28px;
  line-height: 20px;
  display: flex;
  position: relative;
}

.filter__checkbox-label:before {
  content: "";
  border: 1px solid var(--color-lines);
  transition: background-color var(--transition-in), border-color var(--transition-in), box-shadow var(--transition-in);
  background-color: #fff;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.filter__checkbox-label:after {
  content: "";
  background: url("checkbox-mark.074c5aa1.svg") center no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.filter__checkbox-label:hover:before, .filter__checkbox-label:focus:before {
  border-color: var(--color-accent);
}

.filter__checkbox-input:focus-visible ~ .filter__checkbox-label:before {
  border-color: var(--color-accent);
  box-shadow: var(--focus-visible-shadow);
}

.filter__checkbox-input:checked ~ .filter__checkbox-label:before {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}

@media screen and (width >= 1024px) {
  .filter__checkbox-label {
    padding-left: 28px;
  }

  .filter__checkbox-label:before, .filter__checkbox-label:after {
    left: 0;
    right: auto;
  }
}

.filter__checkbox-count {
  color: var(--color-text-aux);
  margin-left: 4px;
  display: inline-block;
}

@media screen and (width >= 1024px) {
  .filter__radios > :not(:last-child) {
    margin-bottom: 4px;
  }
}

.filter__radio {
  cursor: pointer;
  display: block;
}

@media screen and (width <= 1023px) {
  .filter__radio {
    padding: 16px 0;
  }

  .filter__radio:not(:last-child) {
    border-bottom: 1px solid var(--color-lines);
  }
}

@media screen and (width >= 1024px) {
  .filter__radio {
    padding: 4px 0;
  }
}

.filter__radio-input {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.filter__radio-label {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding-right: 28px;
  line-height: 20px;
  display: flex;
  position: relative;
}

.filter__radio-label:before {
  content: "";
  border: 1px solid var(--color-lines);
  transition: background-color var(--transition-in), border-color var(--transition-in), box-shadow var(--transition-in);
  background-color: #fff;
  border-radius: 999px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.filter__radio-label:after {
  content: "";
  background: #fff;
  border-radius: 999px;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
}

.filter__radio-label:hover:before, .filter__radio-label:focus:before {
  border-color: var(--color-accent);
}

.filter__radio-input:focus-visible ~ .filter__radio-label:before {
  border-color: var(--color-accent);
  box-shadow: var(--focus-visible-shadow);
}

.filter__radio-input:checked ~ .filter__radio-label:before {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}

@media screen and (width >= 1024px) {
  .filter__radio-label {
    padding-left: 28px;
  }

  .filter__radio-label:before {
    left: 0;
    right: auto;
  }

  .filter__radio-label:after {
    left: 5px;
    right: auto;
  }
}

.filter__toggle {
  margin: 12px 0;
}

.filter__prices {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  margin-bottom: 8px;
  display: grid;
}

.filter__price {
  position: relative;
}

.filter__price-label {
  z-index: 1;
  color: var(--color-text-aux);
  text-transform: lowercase;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.filter__price-input {
  border: 1px solid var(--color-lines);
  resize: none;
  transition: border-color var(--transition-in), box-shadow var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 8px;
  width: 100%;
  min-height: 10px;
  padding: 11px 0 11px 32px;
  font-size: 16px;
  line-height: 1.4;
}

.filter__price-input:hover {
  border-color: var(--color-accent);
}

.filter__price-input:focus {
  border-color: var(--color-accent);
  box-shadow: 0 0 0 3px #c1232529;
}

.filter__price-input:focus-visible {
  outline: 0;
}

.filter__price-input::-ms-clear {
  display: none;
}

.filter__price-input.is-not-empty {
  padding-right: 32px;
}

@media screen and (width >= 1024px) {
  .filter__price-input {
    font-size: 14px;
  }
}

.filter__price-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

@media screen and (width >= 1024px) {
  .filter__colors {
    flex-wrap: wrap;
    display: flex;
  }
}

.filter__color {
  cursor: pointer;
  display: block;
  position: relative;
}

@media screen and (width <= 1023px) {
  .filter__color {
    padding: 16px 36px 16px 28px;
  }

  .filter__color:not(:last-child) {
    border-bottom: 1px solid var(--color-lines);
  }
}

@media screen and (width >= 1024px) {
  .filter__color {
    margin-bottom: 4px;
    margin-right: 4px;
  }
}

.filter__color-input {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

@media screen and (width >= 1024px) {
  .filter__color-label {
    clip: rect(0 0 0 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
  }
}

.filter__color-count {
  color: var(--color-text-aux);
  margin-left: 4px;
  display: inline-block;
}

.filter__color-checkbox {
  border: 1px solid var(--color-lines);
  transition: background-color var(--transition-in), border-color var(--transition-in), box-shadow var(--transition-in);
  background-color: #fff;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.filter__color-checkbox:after {
  content: "";
  background: url("checkbox-mark.074c5aa1.svg") center no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: -1px;
  right: -1px;
}

.filter__color:hover .filter__color-checkbox {
  border-color: var(--color-accent);
}

.filter__color-input:focus-visible ~ .filter__color-checkbox {
  border-color: var(--color-accent);
  box-shadow: var(--focus-visible-shadow);
}

.filter__color-input:checked ~ .filter__color-checkbox {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}

@media screen and (width >= 1024px) {
  .filter__color-checkbox {
    display: none;
  }
}

.filter__color-color {
  border: 1px solid var(--color-lines);
  transition: border-color var(--transition-in);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  overflow: hidden;
  transform: translateY(-50%);
}

.filter__color-color:before {
  content: "";
  background-color: var(--color-grey-05);
  border-radius: 100%;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filter__color-color.is-red:before {
  background-color: #e50000;
}

.filter__color-color.is-white:before {
  background-color: #fff;
}

.filter__color-color.is-brown:before {
  background-color: #964b00;
}

.filter__color-color.is-black:before {
  background-color: #000;
}

.filter__color-color.is-pink:before {
  background-color: pink;
}

.filter__color-color.is-purple:before {
  background-color: #8b00ff;
}

.filter__color-color.is-blue:before {
  background-color: #00f;
}

.filter__color-color.is-beige:before {
  background-color: beige;
}

.filter__color-color.is-orange:before {
  background-color: orange;
}

.filter__color-color.is-yellow:before {
  background-color: #ff0;
}

.filter__color-color.is-green:before {
  background-color: green;
}

.filter__color-color.is-very-pery:before {
  background-color: #6467ac;
}

.filter__color-color.is-bordeaux:before {
  background-color: #b00000;
}

.filter__color-color.is-peach:before {
  background-color: #ffdab9;
}

.filter__color-color.is-coral:before {
  background-color: #f36f63;
}

.filter__color-color.is-classic-blue:before {
  background-color: #35558a;
}

.filter__color-color.is-mto:before {
  background: linear-gradient(314.99deg, red 5.21%, #ffe600 27.74%, #00a3ff 49.37%, #ad00ff 72.26%, red 94.61%);
}

.filter__color-color.is-grey-yellow:before {
  background: linear-gradient(314.99deg, #939597 0% 50%, #f8e00e 50% 100%);
}

.filter__color-color.is-multi:before {
  background: #0708fa url("multicolor.33ca38b5.png") center no-repeat;
}

.filter__color-color.is-rainbow:before {
  background: linear-gradient(314.99deg, red 5.21%, #ffe600 27.74%, #00a3ff 49.37%, #ad00ff 72.26%, red 94.61%);
}

@media screen and (width >= 1024px) {
  .filter__color-color {
    width: 36px;
    height: 36px;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }

  .filter__color-color:before {
    width: 28px;
    height: 28px;
  }

  .filter__color-color:after {
    content: "";
    opacity: 0;
    transition: opacity var(--transition-in);
    background: url("filter-color-check.717a2922.svg") center no-repeat;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .filter__color:hover .filter__color-color {
    border-color: var(--color-accent);
  }

  .filter__color-input:focus-visible ~ .filter__color-color {
    border-color: var(--color-accent);
    box-shadow: var(--focus-visible-shadow);
  }

  .filter__color-input:checked ~ .filter__color-color {
    border-color: var(--color-accent);
  }

  .filter__color-input:checked ~ .filter__color-color:after {
    opacity: 1;
  }
}

.pagination {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.pagination > * {
  margin: 0 2px;
}

.pagination > :nth-last-child(9), .pagination > :nth-last-child(9) ~ * {
  width: 28px;
}

.pagination__ellipsis {
  text-align: center;
  width: 36px;
  height: 20px;
  font-weight: 450;
}

.pagination__link {
  border: 1px solid var(--color-lines);
  transition: color var(--transition-in), background-color var(--transition-in), border-color var(--transition-in), box-shadow var(--transition-in);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-weight: 450;
  display: inline-flex;
}

.pagination__link:hover, .pagination__link:active, .pagination__link:focus-visible {
  background-color: var(--color-grey-05);
}

.pagination__link:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: 0;
}

.pagination__link:not([href]) {
  color: var(--color-grey-2);
}

.pagination__link[aria-current="page"] {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
  color: #fff;
}

.catalog-grid {
  --row-gap: 16px;
  row-gap: var(--row-gap);
  column-gap: var(--size-gap);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  position: relative;
  overflow: hidden;
}

.catalog-grid:after {
  content: "";
  background-color: var(--color-bg);
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (width >= 768px) {
  .catalog-grid {
    --row-gap: 24px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.catalog-grid--has-4-items {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media screen and (width >= 768px) {
  .catalog-grid--has-4-items {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (width >= 1024px) {
  .catalog-grid--has-4-items {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.catalog-grid--has-6-items {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media screen and (width >= 768px) {
  .catalog-grid--has-6-items {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media screen and (width >= 1024px) {
  .catalog-grid--has-6-items {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.catalog-grid--wide {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media screen and (width >= 768px) {
  .catalog-grid--wide {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (width >= 1024px) {
  .catalog-grid--wide {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.catalog-grid__item {
  padding-bottom: var(--row-gap);
  display: flex;
  position: relative;
}

.catalog-grid__item > * {
  width: 100%;
}

.catalog-grid__item:after {
  content: "";
  width: calc(100% + var(--size-gap));
  background-color: var(--color-lines);
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.catalog-grid__item.is-full {
  grid-column: 1 / -1;
}

.partners-slider__slide {
  justify-content: center;
  align-items: center;
  height: 145px;
  display: flex;
}

.partners-slider__slide img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.partners-slider__slide a {
  width: 100%;
  height: 100%;
  display: block;
}

.message {
  display: flex;
}

.message__icon {
  flex-shrink: 0;
  margin-right: 16px;
}

.message__title {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 550;
  line-height: 24px;
}

.cookie-consent {
  right: 8px;
  bottom: calc(var(--size-tabbar-height)  + 8px);
  z-index: var(--z-index-notifications);
  background-color: var(--color-grey-4);
  color: #fff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 224px;
  padding: 12px;
  display: flex;
  position: fixed;
  left: 8px;
}

@media screen and (width <= 1023px) {
  .product-page-body .cookie-consent {
    bottom: calc(var(--size-tabbar-height)  + var(--size-buy-bar-height)  + 8px);
  }
}

@media screen and (width >= 450px) {
  .cookie-consent {
    width: 328px;
  }
}

@media screen and (width >= 1024px) {
  .cookie-consent {
    bottom: var(--size-container-sidegap);
  }
}

.cookie-consent__text {
  font-size: 12px;
  line-height: 16px;
}

.cookie-consent__button {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: border-color var(--transition-in), box-shadow var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #ffffff80;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 32px;
  margin-left: 8px;
  padding: 7px;
  font-size: 12px;
  font-weight: 550;
  line-height: 16px;
  display: inline-flex;
}

.cookie-consent__button:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px #ffffff29;
}

.cookie-consent__button:hover, .cookie-consent__button:focus-visible {
  border-color: #fff;
}

@media screen and (width >= 450px) {
  .cookie-consent__button {
    margin-left: 24px;
  }
}

.b24-widget-button-wrapper {
  --_offset-right: 16px;
  --_offset-bottom: calc(var(--size-tabbar-height)  + 16px);
  isolation: isolate;
  position: relative;
  z-index: var(--z-index-b24-widget) !important;
}

.b24-widget-button-wrapper.b24-widget-button-bottom {
  z-index: var(--z-index-b24-widget-on-top) !important;
}

@media screen and (width >= 1024px) {
  .b24-widget-button-wrapper {
    --_offset-right: 24px;
    --_offset-bottom: 24px;
  }
}

.b24-widget-button-shadow {
  z-index: var(--z-index-b24-widget-overlay) !important;
  background-color: var(--color-overlay) !important;
}

.b24-widget-button-position-bottom-right {
  right: var(--_offset-right) !important;
  bottom: var(--_offset-bottom) !important;
}

@media screen and (width <= 1023px) {
  body.has-mobile-panel .b24-widget-button-position-bottom-right {
    bottom: calc(var(--size-buy-bar-height)  + var(--_offset-bottom)) !important;
  }
}

.b24-widget-button-inner-container {
  transform: none !important;
}

.b24-widget-button-block {
  width: 48px !important;
  height: 48px !important;
}

.b24-widget-button-inner-block {
  background-color: var(--color-accent) !important;
  height: 48px !important;
}

.b24-widget-button-inner-mask, .b24-widget-button-pulse {
  display: none !important;
}

.b24-crm-button-icon, .b24-widget-button-close-item {
  transform-origin: center;
  transform: scale(.82);
}

.b24-widget-button-social-item {
  margin-right: 0 !important;
}

.editor-content {
  grid-template-columns: 100%;
  display: grid;
}

.editor-content b, .editor-content strong {
  font-weight: 550;
}

.editor-content a {
  color: var(--color-accent);
  transition: color var(--transition-in);
}

.editor-content a:hover, .editor-content a:focus-visible {
  text-decoration: underline;
}

.editor-content a:focus-visible {
  outline-width: 2px;
}

.editor-content p {
  margin-top: 8px;
  margin-bottom: 4px;
}

.editor-content ul, .editor-content ol {
  margin-top: 4px;
  margin-bottom: 4px;
}

.editor-content ul:not([class]) li {
  padding-left: 24px;
  position: relative;
}

.editor-content ul:not([class]) li:before {
  content: "";
  background: var(--color-accent);
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  top: 7px;
  left: 6px;
}

.editor-content ol:not([class]) {
  counter-reset: list;
}

.editor-content ol:not([class]) li {
  counter-increment: list;
  padding-left: 24px;
  position: relative;
}

.editor-content ol:not([class]) li:before {
  content: counters(list, ".");
  color: var(--color-accent);
  padding: 0 6px;
  font-weight: 550;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.editor-content h1 {
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 550;
  line-height: 1.25;
}

@media screen and (width >= 768px) {
  .editor-content h1 {
    font-size: 24px;
    line-height: 1.35;
  }
}

.editor-content h2 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 550;
  line-height: 1.25;
}

@media screen and (width >= 768px) {
  .editor-content h2 {
    font-size: 22px;
    line-height: 1.35;
  }
}

.editor-content h3 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 550;
  line-height: 1.25;
}

@media screen and (width >= 768px) {
  .editor-content h3 {
    font-size: 20px;
    line-height: 1.35;
  }
}

.editor-content h4 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 550;
  line-height: 1.25;
}

@media screen and (width >= 768px) {
  .editor-content h4 {
    font-size: 18px;
    line-height: 1.35;
  }
}

.editor-content iframe {
  border-radius: 8px;
  margin: 16px 0;
  overflow: hidden;
}

.editor-content figure {
  margin: 16px 0;
}

.editor-content figure img {
  border-radius: 8px;
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
}

.editor-content figcaption {
  color: var(--color-text-aux);
  margin-top: 12px;
  font-size: 12px;
}

.editor-content figure > *, .editor-content picture > * {
  margin-top: 0;
  margin-bottom: 0;
}

.editor-content [class="wp-block-table"] table {
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
}

.editor-content [class="wp-block-table"] table td, .editor-content [class="wp-block-table"] table th {
  vertical-align: top;
  border-right: 1px solid var(--color-lines);
  border-bottom: 1px solid var(--color-lines);
  padding: 8px;
}

.editor-content [class="wp-block-table"] table td > :first-child, .editor-content [class="wp-block-table"] table th > :first-child {
  margin-top: 0;
}

.editor-content [class="wp-block-table"] table td > :last-child, .editor-content [class="wp-block-table"] table th > :last-child {
  margin-bottom: 0;
}

.editor-content [class="wp-block-table"] table th {
  background-color: var(--color-blocks);
  text-align: center;
  font-weight: 550;
}

.editor-content [class="wp-block-table"] table td:first-child, .editor-content [class="wp-block-table"] table th:first-child {
  border-left: 1px solid var(--color-lines);
}

.editor-content [class="wp-block-table"] table tr:first-child th, .editor-content [class="wp-block-table"] table tr:first-child td {
  border-top: 1px solid var(--color-lines);
}

.editor-content [class="wp-block-table"] table tr:first-child th {
  text-align: center;
  padding: 16px 8px;
}

.editor-content [class="wp-block-table"] table tr:first-child th:first-child, .editor-content [class="wp-block-table"] table tr:first-child td:first-child {
  border-top-left-radius: 8px;
}

.editor-content [class="wp-block-table"] table tr:first-child th:last-child, .editor-content [class="wp-block-table"] table tr:first-child td:last-child {
  border-top-right-radius: 8px;
}

.editor-content [class="wp-block-table"] table tr:not(:first-child) th {
  text-align: right;
  width: max-content;
}

.editor-content [class="wp-block-table"] table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.editor-content [class="wp-block-table"] table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.editor-content [class="wp-block-table"] table caption {
  color: var(--color-text-aux);
  caption-side: bottom;
  text-align: left;
  margin-top: 12px;
  font-size: 12px;
}

@media screen and (width <= 541px) {
  .editor-content [class="wp-block-table"] {
    width: 100vw;
    margin-left: 50%;
    display: flex;
    overflow-x: auto;
    transform: translateX(-50%);
  }

  .editor-content [class="wp-block-table"]:before, .editor-content [class="wp-block-table"]:after {
    content: "";
    width: var(--size-container-sidegap);
    flex-shrink: 0;
    display: block;
  }

  .editor-content [class="wp-block-table"] table {
    min-width: 510px;
  }
}

.editor-content .wp-block-table.is-style-with-line table {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
}

.editor-content .wp-block-table.is-style-with-line table tr {
  position: relative;
}

.editor-content .wp-block-table.is-style-with-line table tr:not(:first-child) td {
  padding-top: 8px;
}

.editor-content .wp-block-table.is-style-with-line table td {
  padding: 0;
}

.editor-content .wp-block-table.is-style-with-line table td:first-child span {
  background-color: var(--color-bg);
  padding-right: 4px;
}

.editor-content .wp-block-table.is-style-with-line table td:first-child span:after {
  content: "";
  z-index: -1;
  background: url("dots.fbc4682f.svg") 0 repeat-x;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: .4em;
  left: 0;
}

.editor-content .wp-block-table.is-style-with-line table td:last-child {
  vertical-align: bottom;
  text-align: right;
  padding: 0;
}

.editor-content .wp-block-table.is-style-with-line table td:last-child span {
  background-color: var(--color-bg);
  white-space: nowrap;
  padding-left: 4px;
  display: inline-block;
}

.editor-content .wp-block-gallery.columns-default, .editor-content .wp-block-gallery.columns-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
}

.editor-content > :first-child {
  margin-top: 0;
}

.editor-content > :last-child {
  margin-bottom: 0;
}

.search-query-card {
  border: 1px solid var(--color-lines);
  transition: background-color var(--transition-in);
  border-radius: 999px;
  padding: 6px 16px;
  display: inline-block;
}

.search-query-card:hover, .search-query-card:focus-visible {
  background-color: var(--color-grey-05);
  outline: 0;
}

.search-query-card:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: none;
}

.letters {
  overflow: hidden;
}

.letters__swiper {
  overflow: visible;
}

@media screen and (width >= 1024px) {
  .letters__swiper {
    overflow: hidden;
  }
}

.letters__slide {
  width: 136px;
  height: 80px;
}

.letters__link {
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
}

.letters__logo {
  width: 100%;
  height: 100%;
  display: block;
}

.letters__logo img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.cards-slider {
  overflow: hidden;
}

.cards-slider__swiper {
  overflow: visible;
}

@media screen and (width >= 1024px) {
  .cards-slider__swiper {
    overflow: hidden;
  }
}

.cards-slider__slide {
  width: 280px;
}

@media screen and (width >= 1024px) {
  .cards-slider__slide {
    width: auto;
  }
}

.blog-card {
  display: block;
  position: relative;
}

.blog-card__category {
  color: var(--color-white);
  background: #0006;
  border-radius: 999px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 550;
  line-height: 16px;
  display: block;
  position: absolute;
  top: 16px;
  left: 16px;
}

.blog-card__views {
  color: var(--color-white);
  background: #0006;
  border-radius: 999px;
  align-items: center;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 550;
  line-height: 16px;
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
}

.blog-card__views .i {
  margin-right: 4px;
}

.blog-card__link {
  display: block;
}

.blog-card__picture {
  background: var(--color-blocks);
  isolation: isolate;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  display: block;
  position: relative;
  overflow: hidden;
}

.blog-card__picture:before {
  content: "";
  padding-top: 75%;
  display: block;
}

.blog-card__picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform .5s ease-in;
  position: absolute;
  inset: 0;
}

.blog-card:hover .blog-card__picture img, .blog-card:focus-visible .blog-card__picture img {
  transform: scale(1.05);
}

.blog-card__time {
  color: var(--color-text-aux);
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
}

.blog-card__title {
  transition: color var(--transition-in);
  margin-bottom: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: 20px;
}

.blog-card:hover .blog-card__title, .blog-card:focus-visible .blog-card__title {
  color: var(--color-accent);
}

.post-card__description {
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
}

.blog-bg-card {
  display: block;
  position: relative;
}

.blog-bg-card__category {
  color: var(--color-white);
  background: #0006;
  border-radius: 999px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 550;
  line-height: 16px;
  display: block;
  position: absolute;
  top: 16px;
  left: 16px;
}

.blog-bg-card__views {
  color: var(--color-white);
  background: #0006;
  border-radius: 999px;
  align-items: center;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 550;
  line-height: 16px;
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
}

.blog-bg-card__views .i {
  margin-right: 4px;
}

.blog-bg-card__link {
  background: var(--color-blocks);
  isolation: isolate;
  border-radius: 8px;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.blog-bg-card__link:before {
  content: "";
  padding-top: 73.7654%;
  display: block;
}

.blog-bg-card__bg {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-bg-card__bg:after {
  content: "";
  z-index: 1;
  opacity: .75;
  background: linear-gradient(#0000 0%, #00000003 13%, #0000000a 23.69%, #00000016 32.36%, #00000025 39.32%, #00000037 44.86%, #0000004b 49.29%, #00000061 52.92%, #00000078 56.03%, #00000090 58.94%, #000000a7 61.94%, #000000bc 65.33%, #000000d1 69.42%, #000000e3 74.51%, #000000f3 80.9%, #000 88.89%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-bg-card__bg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform .5s ease-in;
}

.blog-bg-card:hover .blog-bg-card__bg img, .blog-bg-card:focus-visible .blog-bg-card__bg img {
  transform: scale(1.05);
}

.blog-bg-card__body {
  z-index: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-bg-card__time {
  color: var(--color-white);
  margin-top: auto;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 450;
  line-height: 16px;
  display: block;
}

.blog-bg-card__title {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 550;
  line-height: 20px;
  display: block;
}

.stars-select {
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  display: flex;
}

.stars-select__input {
  background-color: var(--color-grey-1);
  cursor: pointer;
  transition: background-color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  width: 56px;
  height: 48px;
  padding: 0 4px;
  display: block;
  -webkit-mask-image: url("star-48.af27512d.svg");
  mask-image: url("star-48.af27512d.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.stars-select__input:not(:disabled):hover, .stars-select__input:checked, .stars-select__input:not(:disabled):focus-visible, .stars-select__input:not(:disabled):hover ~ .stars-select__input, .stars-select__input:checked ~ .stars-select__input, .stars-select__input:not(:disabled):focus-visible ~ .stars-select__input {
  background-color: var(--color-yellow);
}

.rating-stars {
  align-items: center;
  display: flex;
}

.rating-stars__star {
  color: var(--color-grey-2);
}

.rating-stars__star.is-active {
  color: var(--color-yellow);
}

.review-card {
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  grid-template: "avatar name"
                 "avatar rating"
                 "text text"
                 "gallery gallery" 1fr
                 / 48px minmax(0, 1fr);
  column-gap: 12px;
  padding: 16px;
  display: grid;
  overflow: hidden;
}

.review-card.is-dark {
  background: var(--color-grey-05);
}

.review-card__avatar {
  background: var(--color-grey-05) url("review-avatar.457a33be.svg") no-repeat center;
  background-size: contain;
  border-radius: 999px;
  grid-area: avatar;
  width: 48px;
  height: 48px;
  display: block;
}

.review-card__avatar.is-yandex {
  background-image: url("review-avatar-yandex.098f804e.svg");
}

.review-card__avatar.is-google {
  background-image: url("review-avatar-google.aee2b6d3.svg");
}

.review-card.is-dark .review-card__avatar {
  background-color: var(--color-grey-1);
}

.review-card__name {
  grid-area: name;
  margin-bottom: 4px;
  font-weight: 550;
}

.review-card__rating {
  grid-area: rating;
  align-items: center;
  display: flex;
}

.review-card__date {
  color: var(--color-text-aux);
  display: flex;
}

@media screen and (width <= 767px) {
  .review-card.is-narrow .review-card__date span {
    display: none;
  }
}

.review-card__text {
  grid-area: text;
  margin-top: 12px;
}

.review-card__gallery-swiper {
  grid-area: gallery;
  margin: 12px 0 0;
  overflow: visible;
}

.review-card__slide {
  width: 96px;
  height: 96px;
  margin-right: 8px;
}

.reviews-card__link {
  border-radius: 8px;
  display: block;
  overflow: hidden;
}

.reviews-card__photo {
  width: 100%;
  display: block;
  position: relative;
}

.reviews-card__photo:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.reviews-card__photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.reviews-summary {
  background: var(--color-blocks);
  border-radius: 8px;
  padding: 16px;
}

@media screen and (width >= 768px) {
  .reviews-summary {
    padding: 24px;
  }
}

.reviews-summary__heading {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 550;
  line-height: 24px;
}

.reviews-summary__info {
  border-bottom: 1px solid var(--color-lines);
  grid-template: "rating stars" 1fr
                 "rating text" 1fr
                 / max-content minmax(0, 1fr);
  gap: 6px 12px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: grid;
}

.reviews-summary__rating {
  letter-spacing: -1.92px;
  grid-area: rating;
  font-size: 48px;
  font-style: normal;
  font-weight: 550;
  line-height: 100%;
}

.reviews-summary__stars {
  grid-area: stars;
  align-self: end;
  display: flex;
}

.reviews-summary__star {
  color: var(--color-grey-2);
}

.reviews-summary__star.is-active {
  color: var(--color-yellow);
}

.reviews-summary__text {
  color: var(--color-text-aux);
  grid-area: text;
  align-self: start;
}

.reviews-summary__bars {
  border-bottom: 1px solid var(--color-lines);
  color: var(--color-text-aux);
  grid-template-columns: max-content minmax(0, 1fr) max-content;
  gap: 8px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: grid;
}

.reviews-summary__bar {
  position: relative;
}

.reviews-summary__bar:before, .reviews-summary__bar:after {
  content: "";
  background: var(--color-grey-1);
  border-radius: 999px;
  width: 100%;
  height: 4px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.reviews-summary__bar:after {
  background: var(--color-yellow);
  clip-path: polygon(0 0, var(--fraction) 0, var(--fraction) 100%, 0 100%);
}

.reviews-slider {
  overflow: hidden;
}

.reviews-slider__summary {
  align-items: center;
  display: flex;
}

.reviews-slider__rating {
  margin-right: 4px;
  font-size: 16px;
  font-weight: 550;
  line-height: 20px;
}

.reviews-slider__count {
  color: var(--color-text-aux);
  align-items: center;
  display: flex;
}

.reviews-slider__slide {
  grid-template-columns: 100%;
  width: 280px;
  height: auto;
  display: grid;
}

@media screen and (width >= 768px) {
  .reviews-slider__slide {
    width: 340px;
  }
}

.image-uploader {
  align-items: center;
  display: flex;
}

.image-uploader__input {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-appearance: none;
  appearance: none;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  word-wrap: normal !important;
  position: absolute !important;
}

.image-uploader__label {
  border: 1px dashed var(--color-grey-1);
  text-align: center;
  cursor: pointer;
  transition: border-color var(--transition-in);
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  display: flex;
}

.image-uploader__label:hover, .image-uploader__label:focus-visible {
  border-color: var(--color-accent);
}

.image-uploader__uploaded {
  border: 1px dashed var(--color-grey-1);
  border-radius: 8px;
  width: 96px;
  height: 96px;
  overflow: hidden;
}

.image-uploader__uploaded img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-uploader__uploaded:not(:last-child) {
  margin-right: 8px;
}

.cart-panel {
  bottom: var(--size-tabbar-height);
  z-index: var(--z-index-tabbar);
  pointer-events: none;
  width: 100%;
  position: fixed;
  left: 0;
}

@media screen and (width >= 1024px) {
  .cart-panel {
    right: var(--size-container-offset-left);
    bottom: 0;
    left: var(--size-container-offset-left);
    column-gap: var(--size-gap);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: auto;
    display: grid;
  }
}

.cart-panel__inner {
  min-height: var(--size-buy-bar-height);
  padding: 12px var(--size-container-sidegap);
  border-top: 1px solid var(--color-lines);
  background-color: var(--color-bg);
  filter: drop-shadow(0 0 12px #0000001f);
  pointer-events: auto;
  align-items: center;
  display: flex;
}

@media screen and (width >= 1024px) {
  .cart-panel__inner {
    border-radius: 16px 16px 0 0;
    padding: 12px 16px;
  }
}

.cart-panel__submit {
  min-width: 150px;
  margin-left: auto;
}

.cart-panel__count {
  color: var(--color-text-aux);
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
}

.cart-panel__price {
  font-size: 13px;
  font-weight: 550;
  line-height: 16px;
}

.separator-text {
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
  gap: 8px;
  display: grid;
}

.separator-text:before, .separator-text:after {
  content: "";
  background: var(--color-lines);
  height: 1px;
}

.image-attach__preview {
  border: 1px solid var(--color-lines);
  transition: border-color var(--color-error);
  border-radius: 8px;
  grid-template: "image title"
                 "image info"
                 / 48px minmax(0, 1fr);
  align-items: center;
  gap: 4px 12px;
  padding: 12px;
  display: grid;
  position: relative;
}

.image-attach__preview.has-error {
  border-color: var(--color-error);
}

.image-attach__picture {
  border: 1px solid #00000014;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  display: block;
  overflow: hidden;
}

.image-attach__picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-attach__title {
  transition: color var(--color-error);
  grid-area: title;
  font-size: 12px;
  font-weight: 450;
  line-height: 16px;
}

.image-attach__preview.has-error .image-attach__title {
  color: var(--color-error);
}

.image-attach__info {
  color: var(--color-text-aux);
  grid-area: info;
  font-size: 12px;
  line-height: 16px;
}

.image-attach__type {
  text-transform: uppercase;
}

.image-attach__image {
  grid-area: image;
  grid-template-columns: 100%;
  place-items: center;
  display: grid;
}

.image-attach__picture {
  grid-area: 1 / 1 / -1 / -1;
}

.image-attach__loader {
  --loader-speed: 2s;
  transition: opacity var(--transition-in);
  transform-origin: center;
  animation: rotate var(--loader-speed) linear infinite;
  grid-area: 1 / 1 / -1 / -1;
  width: 32px;
  height: 32px;
}

.image-attach__loader circle {
  fill: none;
  stroke: var(--color-accent);
  animation: stretch calc(var(--loader-speed) * .75) ease-in-out infinite;
  stroke-dasharray: 1 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

.image-attach__unknown-icon {
  color: var(--color-error);
  grid-area: 1 / 1 / -1 / -1;
}

.image-attach__error {
  color: var(--color-error);
  margin-top: 8px;
  font-size: 12px;
  display: block;
}

.image-attach__error:before {
  content: "";
  vertical-align: bottom;
  background-color: var(--color-error);
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-block;
  -webkit-mask-image: url("error.bb75903d.svg");
  mask-image: url("error.bb75903d.svg");
}

.image-attach__remove {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
}

.image-attach__uploader {
  border: 1px dashed var(--color-lines);
  transition: border-color var(--transition-in);
  border-radius: 8px;
  padding: 16px;
}

.image-attach__uploader:hover, .image-attach__uploader:focus-within {
  border-color: var(--color-accent);
}

.image-attach__label {
  text-align: center;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.image-attach__icon {
  color: var(--color-accent);
  margin-bottom: 8px;
}

.image-attach__input {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-appearance: none;
  appearance: none;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  word-wrap: normal !important;
  position: absolute !important;
}

.image-attach__control-group {
  grid-template-columns: 1fr 94px;
  align-items: center;
  gap: 8px;
  display: grid;
}

.small-gallery {
  align-items: center;
  display: flex;
}

.small-gallery__item:not(:last-of-type) {
  margin-right: 8px;
}

.small-gallery__preview {
  border: 1px solid #00000014;
  border-radius: 8px;
  grid-template-columns: 100%;
  place-items: center;
  width: 90px;
  height: 90px;
  display: grid;
  overflow: hidden;
}

@media screen and (width >= 768px) {
  .small-gallery__preview {
    width: 113px;
    height: 113px;
  }
}

@media screen and (width >= 1200px) {
  .small-gallery__preview {
    width: 133px;
    height: 133px;
  }
}

.small-gallery__picture {
  grid-area: 1 / 1 / -1 / -1;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.small-gallery__picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.small-gallery__preview.is-counter .small-gallery__picture:after {
  content: "";
  backdrop-filter: blur(4px);
  background: #0003;
  position: absolute;
  inset: 0;
}

.small-gallery__counter {
  color: #fff;
  grid-area: 1 / 1 / -1 / -1;
  font-size: 24px;
  font-style: normal;
  font-weight: 550;
  line-height: 32px;
  display: block;
  position: relative;
}

.promotions {
  background: var(--color-grey-4);
  color: #fff;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.promotions__bg {
  opacity: .4;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.promotions__bg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.promotions__layout {
  border-bottom: 1px solid var(--color-white-16);
  padding: 16px;
  position: relative;
}

@media screen and (width >= 768px) {
  .promotions__layout {
    grid-template-columns: minmax(0, 5fr) minmax(0, 7fr);
    align-items: start;
    gap: 16px;
    display: grid;
  }
}

@media screen and (width >= 1024px) {
  .promotions__layout {
    grid-template-columns: minmax(0, 1fr) 400px;
  }

  .promotions.is-wide .promotions__layout {
    grid-template-columns: minmax(0, 1fr) 530px;
  }
}

.promotions__left {
  margin-bottom: 12px;
}

.promotions__heading {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 550;
  line-height: 24px;
}

@media screen and (width >= 768px) {
  .promotions__heading {
    font-size: 22px;
    line-height: 28px;
  }
}

.promotions__heading-icon {
  vertical-align: text-bottom;
  margin-right: 2px;
  display: inline-block;
}

.promotions__heading-text {
  display: inline;
}

.promotions__description {
  color: var(--color-white-50);
  max-width: 350px;
  font-size: 14px;
  line-height: 20px;
}

.promotions__description > p + p {
  margin-top: 1em;
}

.promotions__slider {
  --swiper-navigation-offset: -8px;
}

.promotions__swiper {
  width: 100%;
}

@media screen and (width <= 767px) {
  .promotions__swiper {
    overflow: visible;
  }
}

.promotions__slide {
  height: auto;
  display: flex;
}

.promotions__slide > * {
  width: 100%;
  height: 100%;
}

.promotions__countdown {
  margin-top: 24px;
  margin-bottom: 24px;
}

.promotions__telegram {
  color: var(--color-white-50);
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

@media screen and (width >= 600px) {
  .promotions__telegram {
    align-items: center;
    padding: 12px 16px;
    display: flex;
  }
}

.promotions__more {
  margin-bottom: 12px;
}

@media screen and (width >= 600px) {
  .promotions__more {
    margin-bottom: 0;
  }
}

.promotions__subscribe {
  width: 100%;
}

@media screen and (width >= 600px) {
  .promotions__subscribe {
    width: auto;
    margin-left: auto;
  }
}

.subcategories {
  overflow: hidden;
}

.subcategories__swiper {
  overflow: visible;
}

@media screen and (width >= 1024px) {
  .subcategories__list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 8px;
    display: grid;
  }
}

.subcategories__item {
  width: 136px;
  height: 128px;
  margin-right: 8px;
}

@media screen and (width >= 768px) {
  .subcategories__item {
    width: 172px;
    margin-right: 12px;
  }
}

@media screen and (width >= 1024px) {
  .subcategories__item {
    height: 96px;
    width: auto !important;
    margin-right: 0 !important;
  }
}

.subcategories__card {
  background: var(--color-grey-05);
  -webkit-user-select: none;
  user-select: none;
  transition: color var(--transition-in);
  isolation: isolate;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 16px;
  font-size: 14px;
  font-weight: 550;
  line-height: 20px;
  display: block;
  position: relative;
  overflow: hidden;
}

.subcategories__card:hover, .subcategories__card:focus-visible {
  color: var(--color-accent);
}

.subcategories__picture {
  width: 96px;
  height: 96px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.subcategories__picture img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.totop {
  z-index: var(--z-index-fab);
  background: var(--color-accent);
  color: var(--color-white);
  opacity: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--transition-in), opacity var(--transition-in), transform var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  display: inline-flex;
  position: fixed;
  bottom: 24px;
  left: 24px;
  transform: translateY(10px);
}

.totop:hover, .totop:focus-visible {
  background-color: var(--color-accent-active);
}

.totop.is-shown {
  opacity: 1;
  transform: none;
}

@media screen and (width <= 1023px) {
  .totop {
    display: none;
  }
}

.p404 {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 24px 0 96px;
  display: flex;
}

@media screen and (width >= 768px) {
  .p404 {
    padding-top: 32px;
  }
}

.p404__404 {
  text-align: center;
  margin-bottom: 16px;
  font-family: Spectral, serif;
  font-size: 60px;
  line-height: 1.2;
  position: relative;
}

@media screen and (width >= 768px) {
  .p404__404 {
    margin-bottom: 32px;
    font-size: 90px;
  }
}

.p404__buttons {
  grid-template-columns: 100%;
  gap: 16px;
  width: 100%;
  display: grid;
}

@media screen and (width >= 450px) {
  .p404__buttons {
    grid-template-columns: repeat(2, 1fr);
  }
}

.countdown {
  color: #eee;
  text-align: center;
  font-size: 14px;
}

.countdown__number-wrapper {
  box-shadow: var(--shadow-z3);
  border-radius: 7px;
  margin: 6px;
  display: inline;
  position: relative;
}

.countdown__number {
  border: 1px solid var(--color-white-16);
  background: var(--color-grey-4);
  text-align: center;
  text-shadow: 0 3px 3px #000;
  background-clip: padding-box;
  border-radius: 7px;
  height: 60px;
  padding: 0 8px;
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  display: inline-block;
  box-shadow: inset 0 4px #ffffff1a;
}

.countdown__line {
  background: #000;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 52%;
  left: 0;
  box-shadow: 0 1px #fff3;
}

.countdown__caption {
  color: var(--color-white-50);
  text-align: center;
  width: 100%;
  font-weight: 400;
  position: absolute;
  bottom: -30px;
  left: 0;
}

.countdown__title {
  color: #fff;
  text-align: center;
}

.landing-features__list {
  gap: var(--size-gap);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

@media screen and (width >= 768px) {
  .landing-features__list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (width >= 1024px) {
  .landing-features__list {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.landing-features__text {
  color: var(--color-text-aux);
  margin-top: 24px;
  font-size: 12px;
  font-weight: 350;
}

.landing-features__text ul {
  list-style: inside;
}

.landing-features__text ul li {
  padding-left: .5em;
}

.product-page {
  margin-bottom: 48px;
}

@media screen and (width >= 768px) {
  .product-page {
    width: var(--size-container-width);
    padding: 0 var(--size-container-sidegap);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: start;
    gap: 8px;
    margin: 0 auto 48px;
    display: grid;
  }
}

@media screen and (width >= 1024px) {
  .product-page {
    grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
  }
}

.product-page__gallery {
  margin-bottom: 16px;
}

@media screen and (width >= 768px) {
  .product-page__gallery {
    margin-bottom: 0;
    position: sticky;
    top: 24px;
  }

  .global-is-header-shown .product-page__gallery {
    top: calc(var(--size-header-panel-height)  + 16px);
    transition: top .5s var(--ease-in-out-quart);
  }
}

.product-page__info {
  padding: 0 var(--size-container-sidegap);
}

@media screen and (width >= 768px) {
  .product-page__info {
    padding: 0 0 0 12px;
  }
}

@media screen and (width >= 1024px) {
  .product-page__info {
    padding-left: 16px;
  }
}

@media screen and (width >= 1200px) {
  .product-page__info {
    padding-left: 22px;
  }
}

.product-page__title {
  font-size: 18px;
  line-height: 24px;
}

.product-page__prices {
  display: none;
}

@media screen and (width >= 1024px) {
  .product-page__prices {
    align-items: center;
    display: flex;
  }
}

.product-page__price {
  font-size: 16px;
  font-weight: 550;
  line-height: 20px;
}

.product-page__price.is-discount {
  color: var(--color-marketing);
}

@media screen and (width >= 1024px) {
  .product-page__price {
    font-size: 18px;
    font-weight: 550;
  }
}

.product-page__old-price {
  color: var(--color-grey-3);
  font-size: 12px;
  font-weight: 450;
  line-height: 16px;
  text-decoration: line-through;
}

@media screen and (width >= 1024px) {
  .product-page__old-price {
    margin-left: 6px;
    padding-top: 4px;
    font-size: 13px;
    font-weight: 350;
  }
}

.product-page__client {
  background-color: var(--color-blocks);
  border-radius: 8px;
  padding: 12px;
  font-size: 13px;
  line-height: 18px;
}

.product-page__client-price {
  margin-right: 4px;
  font-size: 16px;
  font-weight: 550;
  display: inline-block;
}

.product-page__client-link {
  margin-top: 2px;
  font-weight: 450;
  display: block;
}

.product-page__delivery {
  font-size: 13px;
  line-height: 18px;
}

.product-page__delivery-item {
  display: flex;
}

.product-page__delivery-item:not(:last-child) {
  margin-bottom: 16px;
}

.product-page__delivery-list {
  list-style: inside;
}

.product-page__delivery-list li {
  padding-left: .25em;
}

.product-page__delivery-icon {
  color: var(--color-accent);
  flex-shrink: 0;
  margin-right: 12px;
}

.product-page__delivery-title {
  margin-bottom: 4px;
  font-weight: 550;
}

.product-page__delivery-link {
  margin-top: 4px;
  font-weight: 450;
  display: inline-block;
}

.product-page__pay-systems {
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 8px;
  display: grid;
}

.product-page__pay-system {
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (width <= 1023px) {
  .product-page__buy {
    bottom: var(--size-tabbar-height);
    z-index: var(--z-index-tabbar);
    min-height: var(--size-buy-bar-height);
    border-top: 1px solid var(--color-lines);
    background-color: var(--color-bg);
    filter: drop-shadow(0 0 12px #0000001f);
    width: 100%;
    padding: 12px 0;
    position: fixed;
    left: 0;
  }
}

@media screen and (width >= 1024px) {
  .product-page__buy {
    margin-bottom: 32px;
  }
}

.product-page__buy-inner {
  align-items: center;
  display: flex;
}

@media screen and (width <= 1023px) {
  .product-page__buy-inner {
    width: var(--size-container-width);
    padding: 0 var(--size-container-sidegap);
    margin: 0 auto;
  }
}

.product-page__buy-prices {
  margin-right: auto;
}

@media screen and (width >= 1024px) {
  .product-page__buy-prices {
    display: none;
  }
}

.product-page__buy-button {
  min-width: 108px;
}

@media screen and (width >= 1024px) {
  .product-page__buy-button {
    width: 100%;
  }
}

.product-page__tocart {
  background-color: var(--color-success);
  color: #fff;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--transition-in), border-color var(--transition-in), color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #0000;
  border-radius: 8px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  margin-right: 8px;
  padding: 3px 15px;
  display: flex;
}

.product-page__tocart:hover, .product-page__tocart:focus-visible {
  background-color: var(--color-success-active);
}

.product-page__tocart:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px #2ec12329;
}

@media screen and (width <= 1023px) {
  .product-page__tocart {
    display: none;
  }
}

.product-page__tocart-incart {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}

.product-page__tocart-goto {
  font-size: 12px;
  line-height: 16px;
}

.product-page__features {
  grid-template-columns: repeat(2, minmax(0, 2fr));
  gap: 8px;
  display: grid;
}

.product-page__feature {
  background-color: var(--color-blocks);
  border-radius: 8px;
  padding: 12px;
  font-size: 13px;
  line-height: 18px;
}

.product-page__feature:last-child {
  grid-column: span 2;
}

@media screen and (width >= 768px) {
  .product-page__feature {
    padding: 16px;
  }
}

.product-page__feature-icon {
  color: var(--color-accent);
  margin-bottom: 12px;
}

.product-page__feature-title {
  margin-bottom: 4px;
  font-weight: 550;
}

.product-page__contact-button {
  width: 100%;
  margin-top: 12px;
}

.category {
  padding-bottom: 96px;
  overflow: hidden;
}

.category__title {
  display: inline;
}

.category__count {
  vertical-align: 10%;
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 16px;
}

.category__subcategories {
  margin-top: 12px;
}

@media screen and (width >= 768px) {
  .category__subcategories {
    margin-top: 16px;
  }
}

.category__inner {
  column-gap: var(--size-gap);
  grid-template: "filter sort"
                 "applied-filters applied-filters"
                 "products products" 1fr
                 / minmax(0, 1fr) 175px;
  margin-top: 16px;
  display: grid;
}

@media screen and (width >= 1024px) {
  .category__inner {
    grid-template: "filter sort"
                   "filter applied-filters"
                   "filter products" 1fr
                   / minmax(0, 3fr) minmax(0, 9fr);
  }
}

.category__filter {
  grid-area: filter;
}

@media screen and (width >= 1024px) {
  .category__filter-button {
    display: none;
  }
}

.category__sort {
  grid-area: sort;
  justify-self: end;
}

@media screen and (width >= 1024px) {
  .category__sort {
    justify-self: start;
  }
}

.category__applied-filters {
  grid-area: applied-filters;
  margin-top: 12px;
}

@media screen and (width >= 768px) {
  .category__applied-filters {
    margin-top: 16px;
  }
}

.category__clear-filter {
  border: 1px solid var(--color-lines);
  color: var(--color-accent);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--transition-in), border-color var(--transition-in), color var(--transition-in);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  padding: 7px 11px;
  font-size: 14px;
  font-weight: 350;
  line-height: 1.4px;
  display: inline-flex;
}

.category__clear-filter:hover, .category__clear-filter:active, .category__clear-filter:focus-visible {
  background-color: var(--color-grey-05);
}

.category__clear-filter:focus-visible {
  box-shadow: var(--focus-visible-shadow);
  outline: none;
}

.category__products {
  grid-area: products;
  margin-top: 20px;
}

.category__products.is-loading {
  opacity: .35;
  transition: opacity .3s ease-in;
}

@media screen and (width >= 768px) {
  .category__products {
    margin-top: 24px;
  }
}

.category__load-more {
  width: 100%;
  max-width: 236px;
  margin: 0 auto 16px;
  display: grid;
}

.category__bottom {
  margin-top: 32px;
}

.category__faq {
  margin-top: 60px;
}

.content-page {
  padding-bottom: 96px;
}

.content-page__content {
  max-width: 640px;
  margin: 0 auto;
}

.content-page__content .callout {
  background: var(--color-blocks) url("content-callout.628b16c8.svg") no-repeat top 12px left 12px;
  border-radius: 8px;
  margin: 12px 0;
  padding: 12px 12px 12px 48px;
}

.content-page__content .details-group, .content-page__content .slider {
  margin: 16px 0;
}

.content-page__info {
  color: var(--color-text-aux);
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.content-page__hero {
  margin-bottom: 16px;
  display: block;
}

.content-page__hero img {
  border-radius: 8px;
  width: 100%;
}

@media screen and (width >= 768px) {
  .content-page__hero {
    margin: 0 -24px 24px;
  }
}

@media screen and (width >= 1024px) {
  .content-page__hero {
    margin: 0 -40px 24px;
  }
}

.custom-payment {
  flex-direction: column;
  justify-content: center;
  max-width: 320px;
  margin: 0 auto;
  padding: 16px 16px 96px;
  display: flex;
}

@media screen and (width >= 768px) {
  .custom-payment {
    padding-top: 24px;
  }
}

.custom-payment__legal {
  color: var(--color-text-aux);
  text-align: center;
  font-size: 12px;
}

.contacts-page__contacts {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  margin-bottom: 48px;
  display: grid;
}

@media screen and (width >= 768px) {
  .contacts-page__contacts {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (width >= 1200px) {
  .contacts-page__contacts {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.contacts-page__contact {
  background-color: var(--color-blocks);
  border-radius: 8px;
  min-height: 144px;
  padding: 12px;
  font-size: 13px;
  line-height: 1.4;
}

@media screen and (width >= 768px) {
  .contacts-page__contact {
    padding: 16px;
  }
}

.contacts-page__contact-title {
  margin-bottom: 4px;
  font-weight: 550;
}

.contacts-page__contact-text {
  margin-bottom: 4px;
}

.contacts-page__contact-aux {
  color: var(--color-text-aux);
}

.contacts-page__requisites {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 16px;
  display: grid;
}

@media screen and (width >= 768px) {
  .contacts-page__requisites {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (width >= 1200px) {
  .contacts-page__requisites {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.cart {
  padding-bottom: 96px;
}

@media screen and (width >= 1024px) {
  .cart {
    padding-top: 16px;
  }
}

.cart__empty {
  margin-top: 24px;
  display: flex;
}

@media screen and (width >= 768px) {
  .cart__empty {
    margin-top: 32px;
  }
}

.cart__empty-icon {
  flex-shrink: 0;
  margin-right: 8px;
}

.cart__empty-title {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 550;
  line-height: 24px;
}

@media screen and (width >= 1024px) {
  .cart__sections {
    gap: 24px var(--size-gap);
    width: var(--size-container-width);
    padding: 0 var(--size-container-sidegap);
    grid-template: "quick products"
                   "checkout products" 1fr
                   / minmax(0, 1fr) minmax(0, 1fr);
    margin: 0 auto;
    display: grid;
  }
}

@media screen and (width <= 1023px) {
  .cart__section {
    overflow: hidden;
  }

  .cart__section:not(:last-of-type) {
    border-bottom: 8px solid var(--color-grey-05);
  }
}

.cart__section--products {
  padding: 16px var(--size-container-sidegap);
}

@media screen and (width >= 1024px) {
  .cart__section--products {
    grid-area: products;
    padding: 0 0 0 16px;
  }
}

@media screen and (width >= 1200px) {
  .cart__section--products {
    padding-left: 24px;
  }
}

.cart__section--quick {
  padding: 16px var(--size-container-sidegap);
}

@media screen and (width >= 1024px) {
  .cart__section--quick {
    border-bottom: 1px solid var(--color-lines);
    grid-area: quick;
    padding: 0 0 24px;
  }

  .cart__section--checkout {
    grid-area: checkout;
  }

  .cart__products {
    position: sticky;
    top: 24px;
  }

  .global-is-header-shown .cart__products {
    top: calc(var(--size-header-panel-height)  + 16px);
    transition: top .5s var(--ease-in-out-quart);
  }
}

.cart__heading-bar {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cart__heading {
  display: inline-block;
}

.cart__count {
  vertical-align: 10%;
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
}

.cart__product {
  display: flex;
}

.cart__product:not(:last-child) {
  border-bottom: 1px solid var(--color-lines);
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.cart__product-image-link {
  background: var(--color-blocks) url("product-bg-sm.06184246.svg") no-repeat center;
  border-radius: 12px;
  flex-shrink: 0;
  width: 88px;
  height: 88px;
  margin-right: 12px;
  display: block;
  overflow: hidden;
}

.cart__product-image {
  width: 100%;
  height: 100%;
  display: block;
}

.cart__product-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cart__product-title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 350;
  line-height: 16px;
  display: -webkit-box;
  overflow: hidden;
}

.cart__product:has(.cart__product-image-link:hover) .cart__product-title {
  color: var(--color-accent);
}

.cart__product-prices {
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.cart__product-price {
  font-size: 14px;
  font-weight: 550;
  line-height: 20px;
}

.cart__product-price.is-discount {
  color: var(--color-marketing);
}

@media screen and (width >= 768px) {
  .cart__product-price {
    font-size: 14px;
  }
}

.cart__product-old-price {
  color: var(--color-grey-3);
  margin-left: 4px;
  padding-top: 2px;
  font-size: 11px;
  font-weight: 450;
  line-height: 16px;
  text-decoration: line-through;
}

.cart__product-bar {
  align-items: center;
  display: flex;
}

.cart__checkout-section {
  padding: 16px var(--size-container-sidegap);
}

.cart__checkout-section:not(:last-of-type) {
  border-bottom: 8px solid var(--color-grey-05);
}

@media screen and (width >= 1024px) {
  .cart__checkout-section {
    padding: 0 0 24px;
  }

  .cart__checkout-section:not(:last-of-type) {
    border-bottom: 1px solid var(--color-lines);
    margin-bottom: 24px;
  }
}

.cart__controls-group {
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  overflow: hidden;
}

.cart__controls-group-item {
  padding: 16px;
}

.cart__controls-group-item:not(:last-child) {
  border-bottom: 1px solid var(--color-lines);
}

.cart__suite-inputs {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  display: grid;
}

.cart__details {
  display: block;
}

.cart__details--is-total {
  border-top: 1px solid var(--color-lines);
  margin-top: 16px;
  padding-top: 16px;
}

.cart__details-row {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.cart__details-row:not(:last-of-type) {
  margin-bottom: 12px;
}

.cart__details-name {
  width: 70%;
}

.cart__details-value {
  text-align: right;
  width: 30%;
}

.cart__details-value--is-discount {
  color: var(--color-marketing);
}

.cart__details-name--total, .cart__details-value--total {
  font-size: 16px;
  font-weight: 550;
  line-height: 24px;
}

.cart__details-name--is-old-price, .cart__details-value--is-old-price {
  color: var(--color-grey-3);
  font-size: 14px;
  line-height: 20px;
}

.cart__legal {
  color: var(--color-text-aux);
  text-align: center;
  font-size: 12px;
}

.cart__map {
  border: 1px solid var(--color-lines);
  background: var(--color-blocks);
  border-radius: 8px;
  min-height: 340px;
  animation: .75s infinite alternate both loading-block;
  overflow: hidden;
}

.cart__postcard {
  border-radius: 8px;
  width: 100%;
  max-width: 336px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

.cart__quick {
  background: var(--color-blocks);
  border-radius: 8px;
  padding: 16px;
}

.cart__quick-title {
  margin-bottom: 4px;
  font-weight: 550;
}

.cart__quick-text {
  margin-bottom: 16px;
}

.cart__promocode {
  align-items: flex-start;
  display: flex;
}

.cart__promocode-input {
  flex: 1;
}

.cart__promocode-input input {
  text-transform: uppercase;
}

.cart__promocode-button {
  flex-shrink: 0;
  margin-left: 8px;
}

.checkout-status {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 24px 0 96px;
}

@media screen and (width >= 768px) {
  .checkout-status {
    padding-top: 32px;
  }
}

.checkout-status__message {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.checkout-status__icon {
  margin-bottom: 16px;
}

.checkout-status__title {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 550;
  line-height: 24px;
}

.checkout-status__params {
  border: 1px solid var(--color-lines);
  border-radius: 8px;
}

.checkout-status__section {
  padding: 12px 16px;
}

.checkout-status__section:not(:last-child) {
  border-bottom: 1px solid var(--color-lines);
}

@media screen and (width >= 768px) {
  .checkout-status__section {
    flex-wrap: wrap;
    display: flex;
  }
}

.checkout-status__name {
  color: var(--color-text-aux);
  margin-bottom: 4px;
}

@media screen and (width >= 768px) {
  .checkout-status__name {
    width: 160px;
    margin-right: 8px;
  }
}

.checkout-status__value:not(:last-of-type) {
  margin-bottom: 12px;
}

@media screen and (width >= 768px) {
  .checkout-status__value {
    width: calc(100% - 168px);
  }
}

.checkout-status__product-item:not(:last-child) {
  margin-bottom: 8px;
}

.checkout-status__product {
  display: flex;
}

.checkout-status__product-image {
  background: var(--color-blocks) url("product-bg-sm.06184246.svg") no-repeat center;
  border-radius: 8px;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  margin-right: 8px;
  display: block;
  position: relative;
  overflow: hidden;
}

.checkout-status__product-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.checkout-status__product-title {
  transition: color var(--transition-in);
  margin-bottom: 4px;
  font-size: 13px;
}

.checkout-status__product:hover .checkout-status__product-title, .checkout-status__product:focus-visible .checkout-status__product-title {
  color: var(--color-accent);
}

.checkout-status__product-prices {
  align-items: center;
  display: flex;
}

.checkout-status__product-price {
  font-size: 14px;
  font-weight: 550;
}

.checkout-status__product-price.is-discount {
  color: var(--color-marketing);
}

.checkout-status__product-old-price {
  color: var(--color-grey-3);
  margin-left: 4px;
  padding-top: 2px;
  font-size: 11px;
  line-height: 16px;
  text-decoration: line-through;
}

.blog {
  padding-top: 24px;
  padding-bottom: 96px;
}

.blog__list {
  grid-template-columns: 100%;
  row-gap: 16px;
  display: grid;
  position: relative;
}

.blog__list:after {
  content: "";
  background-color: var(--color-bg);
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (width >= 768px) {
  .blog__list {
    row-gap: 24px;
    column-gap: var(--size-gap);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (width >= 1024px) {
  .blog__list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.blog__item {
  padding-bottom: 16px;
  display: flex;
  position: relative;
}

.blog__item > * {
  width: 100%;
}

.blog__item:after {
  content: "";
  width: calc(100% + var(--size-gap));
  background-color: var(--color-lines);
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (width >= 768px) {
  .blog__item {
    padding-bottom: 24px;
  }
}

.reviews__section {
  grid-template: "heading"
                 "summary"
                 "list"
                 "more"
                 / 100%;
  align-items: start;
  display: grid;
}

.reviews__section:not(:last-of-type) {
  border-bottom: 1px solid var(--color-lines);
  margin-bottom: 48px;
  padding-bottom: 48px;
}

@media screen and (width >= 1024px) {
  .reviews__section {
    column-gap: var(--size-gap);
    grid-template: "heading heading"
                   "list summary"
                   "more more"
                   / minmax(0, 8fr) minmax(0, 4fr);
  }
}

.reviews__heading {
  grid-area: heading;
}

.reviews__summary {
  grid-area: summary;
  margin-bottom: 16px;
}

@media screen and (width >= 768px) {
  .reviews__summary {
    margin-bottom: 24px;
  }
}

@media screen and (width >= 1024px) {
  .reviews__summary {
    margin-left: 16px;
  }
}

@media screen and (width >= 1200px) {
  .reviews__summary {
    margin-left: 24px;
  }
}

.reviews__list {
  grid-area: list;
  grid-template-columns: 100%;
  gap: 16px;
  display: grid;
}

.reviews__more {
  margin-top: 16px;
}

.inline {
  display: inline;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.screen-reader-only {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.hidden {
  display: none;
}

@media screen and (width >= 1024px) {
  .md\:hidden {
    display: none;
  }
}

@media not all and (width >= 1024px) {
  .max-md\:hidden {
    display: none;
  }
}

.w-auto {
  width: auto;
}

.w-0 {
  width: 0;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: .125rem;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-full {
  width: 100%;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-1 {
  margin-left: 4px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mb-9 {
  margin-bottom: 36px;
}

.mb-10 {
  margin-bottom: 40px;
}

.mb-11 {
  margin-bottom: 44px;
}

.mb-12 {
  margin-bottom: 48px;
}

.mb-16 {
  margin-bottom: 64px;
}

.mb-24 {
  margin-bottom: 96px;
}

.mt-auto {
  margin-top: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-8 {
  margin-top: 32px;
}

.mt-9 {
  margin-top: 36px;
}

.mt-10 {
  margin-top: 40px;
}

.mt-11 {
  margin-top: 44px;
}

.mt-12 {
  margin-top: 48px;
}

.mt-16 {
  margin-top: 64px;
}

.my-12 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.m-0 {
  margin: 0;
}

.font-normal {
  font-weight: 350;
}

.font-semibold {
  font-weight: 550;
}

.text-aux {
  color: var(--color-text-aux);
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: 12px;
  line-height: 16px;
}

.text-base {
  font-size: 14px;
  line-height: 20px;
}

.text-lg {
  font-size: 16px;
  line-height: 24px;
}

.text-error {
  color: var(--color-error);
}

.text-dot:before {
  content: " • ";
  display: inline-block;
}
/*# sourceMappingURL=index.7e9746ba.css.map */
