.radio-group {
  overflow: hidden;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
}

.radio-group__item {
  position: relative;
  display: block;
  padding: 16px;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-lines);
  }
}

.radio-group__input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.radio-group__icon {
  position: absolute;
  top: 16px;
  left: 16px;
  display: block;
  color: var(--color-accent);
}

.radio-group__label {
  display: flex; // collapses all stray spaces
  padding-right: 40px;

  span {
    // collapses all stray spaces
    display: flex;
  }

  .radio-group__icon ~ & {
    padding-left: 32px;
  }
}

.radio-group__description {
  display: block;
  margin-top: 4px;
  padding-right: 40px;
  color: var(--color-text-aux);

  .radio-group__icon ~ & {
    padding-left: 32px;
  }
}

.radio-group__button {
  margin-top: 4px;
  color: var(--color-accent);
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  &:focus-visible {
    outline-width: 2px;
  }

  .radio-group__icon ~ & {
    padding-left: 32px;
  }
}

.radio-group__radio {
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-lines);
  border-radius: 100%;
  transition: border-color var(--transition-in), background-color var(--transition-in);

  .radio-group__item:hover & {
    border-color: var(--color-accent);
  }

  .radio-group__input:focus + & {
    border-color: var(--color-accent);
    box-shadow: var(--focus-visible-shadow);
  }

  .radio-group__input:checked + & {
    border-color: var(--color-accent);
    background-color: var(--color-accent);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #fff;
      transform: translate(-50%, -50%);
    }
  }
}
