@keyframes loading-block {
  from {
    background-color: var(--color-blocks);
  }

  to {
    background-color: var(--color-blocks-loading);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}
