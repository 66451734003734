@use 'sass:math';

// Settings
// ==================================================
$hamburger-layer-width: 16px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 3px;
$hamburger-layer-color: #000;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: #fff;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// Hamburger
// ==================================================
.hamburger {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  width: 40px;
  height: 40px;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  outline: none;

  // Normalize (<button>)
  font: inherit;
  text-transform: none;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: math.div($hamburger-layer-height, -2);

  &,
  &::before,
  &::after {
    position: absolute;
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    border-radius: $hamburger-layer-border-radius;
    background-color: $hamburger-layer-color;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

/*
 * Collapse
 */
.hamburger--collapse {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 0.13s;

    &::after {
      top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
      transition: top 0.2s 0.2s cubic-bezier(0.3333, 0.6666, 0.6666, 1),
        opacity 0.1s linear;
    }

    &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.3333, 0.6666, 0.6666, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(
          0,
          ($hamburger-layer-spacing + $hamburger-layer-height) * -1,
          0
        )
        rotate(-45deg);

      &::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.3333, 0, 0.6666, 0.3333),
          opacity 0.1s 0.22s linear;
      }

      &::before {
        top: 0;
        transition: top 0.1s 0.16s cubic-bezier(0.3333, 0, 0.6666, 0.3333),
          transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(-90deg);
      }
    }
  }
}
