@use '../lib/screen';

// Include at the very end of all styles

.inline {
  display: inline;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.screen-reader-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.hidden {
  display: none;
}

.md\:hidden {
  @include screen.from(screen.$md) {
    display: none;
  }
}

.max-md\:hidden {
  @media not all and (min-width: screen.$md) {
    display: none;
  }
}

.w-auto {
  width: auto;
}

.w-0 {
  width: 0;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: 0.125rem; /* 2px */
}

.w-1 {
  width: 0.25rem; /* 4px */
}

.w-1\.5 {
  width: 0.375rem; /* 6px */
}

.w-2 {
  width: 0.5rem; /* 8px */
}

.w-2\.5 {
  width: 0.625rem; /* 10px */
}

.w-3 {
  width: 0.75rem; /* 12px */
}

.w-3\.5 {
  width: 0.875rem; /* 14px */
}

.w-4 {
  width: 1rem; /* 16px */
}

.w-5 {
  width: 1.25rem; /* 20px */
}

.w-6 {
  width: 1.5rem; /* 24px */
}

.w-7 {
  width: 1.75rem; /* 28px */
}

.w-8 {
  width: 2rem; /* 32px */
}

.w-9 {
  width: 2.25rem; /* 36px */
}

.w-10 {
  width: 2.5rem; /* 40px */
}

.w-11 {
  width: 2.75rem; /* 44px */
}

.w-12 {
  width: 3rem; /* 48px */
}

.w-14 {
  width: 3.5rem; /* 56px */
}

.w-16 {
  width: 4rem; /* 64px */
}

.w-20 {
  width: 5rem; /* 80px */
}

.w-24 {
  width: 6rem; /* 96px */
}

.w-28 {
  width: 7rem; /* 112px */
}

.w-32 {
  width: 8rem; /* 128px */
}

.w-36 {
  width: 9rem; /* 144px */
}

.w-40 {
  width: 10rem; /* 160px */
}

.w-44 {
  width: 11rem; /* 176px */
}

.w-48 {
  width: 12rem; /* 192px */
}

.w-52 {
  width: 13rem; /* 208px */
}

.w-56 {
  width: 14rem; /* 224px */
}

.w-60 {
  width: 15rem; /* 240px */
}

.w-64 {
  width: 16rem; /* 256px */
}

.w-72 {
  width: 18rem; /* 288px */
}

.w-80 {
  width: 20rem; /* 320px */
}

.w-96 {
  width: 24rem; /* 384px */
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-1 {
  margin-left: 4px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mb-9 {
  margin-bottom: 36px;
}

.mb-10 {
  margin-bottom: 40px;
}

.mb-11 {
  margin-bottom: 44px;
}

.mb-12 {
  margin-bottom: 48px;
}

.mb-16 {
  margin-bottom: 64px;
}

.mb-24 {
  margin-bottom: 96px;
}

.mt-auto {
  margin-top: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-8 {
  margin-top: 32px;
}

.mt-9 {
  margin-top: 36px;
}

.mt-10 {
  margin-top: 40px;
}

.mt-11 {
  margin-top: 44px;
}

.mt-12 {
  margin-top: 48px;
}

.mt-16 {
  margin-top: 64px;
}

.my-12 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.m-0 {
  margin: 0;
}

.font-normal {
  font-weight: 350;
}

.font-semibold {
  font-weight: 550;
}

.text-aux {
  color: var(--color-text-aux);
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: 12px;
  line-height: 16px;
}

.text-base {
  font-size: 14px;
  line-height: 20px;
}

.text-lg {
  font-size: 16px;
  line-height: 24px;
}

.text-error {
  color: var(--color-error);
}

.text-dot {
  &::before {
    content: '\00a0•\00a0';
    display: inline-block;
  }
}
