.stars-select {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.stars-select__input {
  display: block;
  width: 56px;
  height: 48px;
  padding: 0 4px;
  background-color: var(--color-grey-1);
  outline: none;
  cursor: pointer;
  transition: background-color var(--transition-in);
  appearance: none;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  mask-image: url(../svg/icons/star-48.svg);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible,
  &:checked {
    background-color: var(--color-yellow);

    & ~ .stars-select__input {
      background-color: var(--color-yellow);
    }
  }
}
