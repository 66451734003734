.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & > * {
    margin: 0 2px;

    &:nth-last-child(9) {
      width: 28px;
    }

    &:nth-last-child(9) ~ * {
      width: 28px;
    }
  }
}

.pagination__ellipsis {
  width: 36px;
  height: 20px;
  font-weight: 450;
  text-align: center;
}

.pagination__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  font-weight: 450;
  transition: color var(--transition-in), background-color var(--transition-in),
    border-color var(--transition-in), box-shadow var(--transition-in);

  &:hover,
  &:focus-visible,
  &:active {
    background-color: var(--color-grey-05);
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: 0;
  }

  &:not([href]) {
    color: var(--color-grey-2);
  }

  &[aria-current='page'] {
    border-color: var(--color-accent);
    background-color: var(--color-accent);
    color: #fff;
  }
}
