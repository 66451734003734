.separator-text {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  gap: 8px;
  align-items: center;

  &::before, &::after {
    content: '';
    height: 1px;
    background: var(--color-lines);
  }
}
