@use '../lib/transition';

.link {
  transition: color transition.$in;

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &:focus-visible {
    outline-width: 2px;
  }
}

.link-standalone {
  color: var(--color-accent);
  transition: color transition.$in;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  &:focus-visible {
    outline-width: 2px;
  }
}

.links-in-text {
  a {
    color: var(--color-accent);
    transition: color transition.$in;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }

    &:focus-visible {
      outline-width: 2px;
    }
  }
}
