.bundle-contents {
  width: 100%;
  border-collapse: collapse;
}

.bundle-contents__row {
  position: relative;
}

.bundle-contents__name-cell {
  padding: 0;
}

.bundle-contents__value-cell {
  vertical-align: bottom;
  padding: 0;
  text-align: right;
}

.bundle-contents__row:not(:first-child) {
  .bundle-contents__name-cell,
  .bundle-contents__value-cell {
    padding-top: 8px;
  }
}

.bundle-contents__name {
  // display: inline-block;
  padding-right: 4px;
  background-color: var(--color-bg);

  &::after {
    content: '';
    position: absolute;
    bottom: 0.5em;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 3px;
    background: url(../svg/dots.svg) repeat-x left center;
  }
}

.bundle-contents__value {
  display: inline-block;
  padding-left: 4px;
  background-color: var(--color-bg);
  white-space: nowrap;
}
