.round-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 999px;
}

.round-icon--accent {
  background-color: rgb(193 35 37 / 8%);
  color: var(--color-accent);
}

.round-icon--success {
  background-color: rgb(41 142 55 / 8%);
  color: var(--color-success);
}
