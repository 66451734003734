@use '../lib/screen';

.filter-chips__swiper {
  overflow: visible;

  @include screen.to(screen.$md) {
    overflow: initial;

    .swiper-slide {
      width: auto;
    }
  }
}

.filter-chips__list {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 4px;
  }

  @include screen.from(screen.$sm) {
    flex-wrap: wrap;

    & > * {
      margin-bottom: 4px;
    }
  }
}

.filter-chips__item {
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 6px 8px;
  border: 1px solid var(--color-lines);
  border-radius: 999px;
  white-space: nowrap;
}

.filter-chips__name {
  color: var(--color-text-aux);
}

.filter-chips__value {
  margin-left: 4px;
}

.filter-chips__clear {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 9px;
  background-color: var(--color-grey-2);
  cursor: pointer;
  user-select: none;
  transition: background-color var(--transition-in);
  appearance: none;
  mask-image: url(../svg/icons/clear-fill.svg);

  &:hover,
  &:focus-visible,
  &:active {
    background-color: var(--color-grey-3);
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }
}
