.card-favorite-button {
  position: relative;
  display: block;
  width: 36px;
  height: 36px;
  background: url(../svg/favorite-button-bg.svg) no-repeat center;
  outline: none;
  cursor: pointer;
  appearance: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 8px;
    right: 8px;
    display: block;
    width: 20px;
    height: 20px;
    transition: background-color var(--transition-in);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }

  &::after {
    background-color: var(--color-black);
    mask-image: url(../svg/favorite-button-heart-outline.svg);
  }

  &::before {
    background-color: var(--color-white);
    mask-image: url(../svg/favorite-button-heart-bg.svg);
  }

  &:hover,
  &:focus-visible {
    &::after {
      background-color: var(--color-accent);
    }
  }

  &.is-active {
    &::after {
      background-color: var(--color-accent);
    }

    &::before {
      background-color: var(--color-accent);
    }
  }
}
