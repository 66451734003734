.message {
  display: flex;
}

.message__icon {
  flex-shrink: 0;
  margin-right: 16px;
}

.message__title {
  margin-bottom: 4px;
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
}

.message__text {}
