@use '../lib/screen';

.b24-widget-button-wrapper {
  --_offset-right: 16px;
  --_offset-bottom: calc(var(--size-tabbar-height) + 16px);

  position: relative;

  // button should be below header to not overlap it's dialogs
  z-index: var(--z-index-b24-widget) !important;
  isolation: isolate;

  &.b24-widget-button-bottom {
    // but it should get on top when active
    z-index: var(--z-index-b24-widget-on-top) !important;
  }

  @include screen.from(screen.$md) {
    --_offset-right: 24px;
    --_offset-bottom: 24px;
  }
}

.b24-widget-button-shadow {
  z-index: var(--z-index-b24-widget-overlay) !important;
  background-color: var(--color-overlay) !important;
}

.b24-widget-button-position-bottom-right {
  right: var(--_offset-right) !important;
  bottom: var(--_offset-bottom) !important;

  @include screen.to(screen.$md) {
    body.has-mobile-panel & {
      bottom: calc(var(--size-buy-bar-height) + var(--_offset-bottom)) !important;
    }
  }
}

.b24-widget-button-inner-container {
  transform: none !important;

  @include screen.to(screen.$md) {
    // transform: scale(0.7) !important;
  }
}

.b24-widget-button-block {
  width: 48px !important;
  height: 48px !important;
}

.b24-widget-button-inner-block {
  height: 48px !important;
  background-color: var(--color-accent) !important;
}

.b24-widget-button-inner-mask {
  display: none !important;
}

.b24-widget-button-pulse {
  display: none !important;
}

.b24-crm-button-icon,
.b24-widget-button-close-item {
  transform: scale(0.82);
  transform-origin: center;
}

.b24-widget-button-social-item {
  margin-right: 0 !important;
}
