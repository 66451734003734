.rating-stars {
  display: flex;
  align-items: center;
}

.rating-stars__star {
  color: var(--color-grey-2);

  &.is-active {
    color: var(--color-yellow);
  }
}
