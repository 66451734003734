@use '../lib/screen';

.products-slider {
  position: relative;
}

.products-slider__swiper {
  overflow: visible;

  // compensate for the :focus-visible outline
  margin: 0 -3px;
  padding: 3px;

  @include screen.from(screen.$md) {
    overflow: hidden;
  }
}

.products-slider__slide {
  display: flex;
  width: 138px;
  max-width: 138px;
  height: auto;
  margin-right: 8px;

  @include screen.from(screen.$sm) {
    width: 228px;
    max-width: 228px;
    margin-right: 12px;
  }

  @include screen.from(screen.$md) {
    max-width: 100%;
    margin-right: 16px;
  }

  @include screen.from(screen.$lg) {
    width: 268px;
  }
}

.products-slider__slim-slide {
  display: flex;
  max-width: 112px;
  height: auto;

  @include screen.from(screen.$sm) {
    max-width: 132px;
  }

  @include screen.from(screen.$md) {
    max-width: 100%;
  }
}

.products-slider__card {
  width: 100%;
  height: 100%;
}

.products-slider__nav {
  display: none;

  @include screen.from(screen.$md) {
    position: absolute;
    top: 96px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 0 12px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 8%);
    cursor: pointer;
    user-select: none;
    transition: opacity var(--transition-in), visibility var(--transition-in);
    appearance: none;

    &.products-slider__nav--slim {
      top: 50%;
      transform: translateY(-50%);
    }

    .i {
      background-color: var(--color-grey-4);
    }

    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:hover,
    &:focus-visible {
      .i {
        background-color: var(--color-accent);
      }
    }

    &:focus-visible {
      box-shadow: var(--focus-visible-shadow);
      outline: none;
    }
  }

  @include screen.from(screen.$lg) {
    top: 112px;
  }
}

.products-slider__nav--prev {
  left: -15px;
}

.products-slider__nav--next {
  right: -15px;
}
