@use '../lib/screen';

.letters {
  overflow: hidden;
}

.letters__swiper {
  overflow: visible;

  @include screen.from(screen.$md) {
    overflow: hidden;
  }
}

.letters__slide {
  width: 136px;
  height: 80px;
}

.letters__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
}

.letters__logo {
  display: block;
  width: 100%;
  height: 100%;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
