@use '../lib/screen';

.products-slider-preview {
  position: relative;
  display: flex;
  overflow: visible;

  @include screen.from(screen.$md) {
    overflow: hidden;
  }
}

.products-slider-preview a {
  position: relative;
  display: block;
  width: 138px;
  height: 309px;
  margin-right: 8px;
  color: var(--color-grey-2);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(
      90deg,
      var(--color-grey-1) 0,
      var(--color-grey-05) 40%,
      var(--color-grey-1) 60%,
      var(--color-grey-1) 100%
    );
    background-size: 200% 200%;
    animation: placeholder 3s ease infinite;
    aspect-ratio: 1 / 1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 36px;
    border-radius: 8px;
    background: linear-gradient(
        90deg,
        var(--color-grey-1) 0,
        var(--color-grey-05) 40%,
        var(--color-grey-1) 60%,
        var(--color-grey-1) 100%
    );
    background-size: 200% 200%;
    animation: placeholder 3s ease infinite;
  }

  @include screen.from(screen.$sm) {
    width: 228px;
    height: 391px;
    margin-right: 12px;
  }

  @include screen.from(screen.$md) {
    margin-right: 16px;
  }

  @include screen.from(screen.$lg) {
    width: 268px;
    height: 431px;
    padding-top: 334px;
    padding-bottom: 86px;
  }
}

@keyframes placeholder {
  0% {
    background-position: 100% 0;
  }

  50% {
    background-position: 0 0;
  }

  100% {
    background-position: -100% 0;
  }
}
