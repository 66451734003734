.blog-card {
  position: relative;
  display: block;
}

.blog-card__category {
  position: absolute;
  top: 16px;
  left: 16px;
  display: block;
  padding: 2px 8px;
  border-radius: 999px;
  background: rgb(0 0 0 / 40%);
  color: var(--color-white);
  font-weight: 550;
  font-size: 12px;
  line-height: 16px;
}

.blog-card__views {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 999px;
  background: rgb(0 0 0 / 40%);
  color: var(--color-white);
  font-weight: 550;
  font-size: 12px;
  line-height: 16px;

  .i {
    margin-right: 4px;
  }
}

.blog-card__link {
  display: block;
}

.blog-card__picture {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  background: var(--color-blocks);
  isolation: isolate;

  &::before {
    content: '';
    display: block;
    padding-top: calc(3 / 4 * 100%);
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in;

    .blog-card:hover &,
    .blog-card:focus-visible & {
      transform: scale(1.05);
    }
  }
}

.blog-card__time {
  margin-bottom: 4px;
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 16px;
}

.blog-card__title {
  margin-bottom: 4px;
  font-weight: 550;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  transition: color var(--transition-in);

  .blog-card:hover &,
  .blog-card:focus-visible & {
    color: var(--color-accent);
  }
}

.post-card__description {
  font-weight: 350;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
