@use '../lib/screen';
@use '../lib/transition';

.header {
  position: relative;
  z-index: var(--z-index-header);
}

.header__panel {
  position: relative;
  padding-top: var(--size-header-panel-height);
}

.header__panel-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-bg);
  transition: none;

  &.is-detached {
    position: fixed;
    transform: translateY(-100%);
  }

  &.is-shown {
    transition: transform 0.5s var(--ease-in-out-quart);
    transform: none;
  }
}

.header__placeholder {
}

.top-header {
  @include screen.from(screen.$md) {
    background-color: var(--color-grey-05);
  }
}

.top-header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 12px 0;

  @include screen.from(screen.$md) {
    padding: 8px 0;
  }
}

.top-header__logo {
  display: block;
  flex: 1;
  text-align: center;

  @include screen.from(screen.$md) {
    display: none;
  }
}

.top-header__logo-img {
  max-width: 88px;
  height: auto;
  margin: 0 auto 4px;
}

.top-header__logo-text {
  color: var(--color-grey-3);
  font-size: 12px;
  font-family: var(--font-logo);
  line-height: 1.33;
  text-align: center;
}

.top-header__hamburger {
  @include screen.from(screen.$md) {
    display: none;
  }
}

.top-header__menu {
}

.top-header__menu-inner {
  @include screen.to(screen.$md) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);

    &.is-transition-enter {
      transition: transform 0.2s var(--ease-in-out-quart);
    }

    &.is-transition-enter-start {
      transform: translateX(-100%);
    }

    &.is-transition-enter-end {
      transform: none;
    }

    &.is-transition-leave {
      transition: transform 0.1s var(--ease-in-out-quart);
    }

    &.is-transition-leave-start {
      transform: none;
    }

    &.is-transition-leave-end {
      transform: translateX(-100%);
    }
  }

  @include screen.from(screen.$md) {
    display: block !important; // overrides Alpine's x-show
  }
}

.top-header__menu-heading {
  padding: 16px;
  border-bottom: 1px solid var(--color-lines);
  font-weight: 450;
  font-size: 16px;
  text-align: center;

  @include screen.from(screen.$md) {
    display: none;
  }
}

.top-header__close {
  position: absolute;
  top: 8px;
  right: 16px;

  @include screen.from(screen.$md) {
    display: none;
  }
}

.top-header__menu-list {
  @include screen.to(screen.$md) {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    padding: 8px var(--size-container-sidegap);
  }

  @include screen.from(screen.$md) {
    display: flex;
    align-items: center;
    margin-left: -6px;
    font-weight: 350;
    font-size: 13px;
  }
}

.top-header__menu-link {
  display: block;
  width: 100%;
  padding: 16px 0;
  background: transparent;
  outline: none;
  line-height: 1.2;
  text-align: left;
  appearance: none;

  &.is-special {
    padding: 8px 0;

    span {
      display: inline-block;
      margin-left: -8px;
      padding: 8px;
      border-radius: 999px;
      background-color: var(--color-grey-05);
    }
  }

  @include screen.to(screen.$md) {
    .top-header__menu-item:not(:last-child) & {
      border-bottom: 1px solid var(--color-lines);
    }
  }

  @include screen.from(screen.$md) {
    display: inline-block;
    padding: 4px 6px;

    &.is-special {
      padding: 0 6px;

      span {
        margin-left: 0;
        padding: 4px 8px;
        background-color: var(--color-grey-1);
      }
    }
  }
}

.top-header__menu-buttons {
  display: flex;
  width: 100%;
  padding: 8px var(--size-container-sidegap);

  .contact-button {
    flex: 1;

    &:first-child {
      margin-right: 8px;
    }
  }

  @include screen.from(screen.$md) {
    display: none;
  }
}

.top-header__contacts {
  display: none;

  @include screen.from(screen.$md) {
    position: relative;
    display: block;
  }
}

.top-header__contacts-button {
  position: relative;
  padding: 4px 24px 4px 4px;
  border: 0;
  background: transparent;
  font-weight: 350;
  font-size: 13px;
  line-height: 16px;
  transition: color transition.$in;
  appearance: none;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: currentcolor;
    transition: transform var(--transition-in);
    transform: translateY(-50%) scaleY(-1);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(../svg/icons/chevron-up-16.svg);
  }

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &:focus-visible {
    outline-width: 2px;
  }

  &[aria-expanded='true'] {
    &::after {
      transform: translateY(-50%);
    }
  }
}

.top-header__time {
  display: inline-block;
  margin-left: 8px;
  color: var(--color-grey-3);

  &::before {
    content: '|';
    display: inline-block;
    margin-right: 8px;
  }
}

.top-header__call-button {
  @include screen.from(screen.$md) {
    display: none;
  }
}

.mid-header {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-grey-1);
  background-color: var(--color-bg);

  @include screen.from(screen.$md) {
    padding: 8px 0;
  }
}

.mid-header__inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.mid-header__logo {
  display: none;
  border-radius: 4px;

  @include screen.from(screen.$md) {
    display: block;
  }
}

.mid-header__logo-text {
  color: var(--color-grey-3);
  font-size: 12px;
  font-family: var(--font-logo);
  line-height: 1.3;
}

.mid-header__catalog-button {
  margin-right: 8px;

  @include screen.from(screen.$md) {
    display: none;
  }
}

.mid-header__searchbar {
  position: relative;
  flex: 1;
  align-self: stretch;

  @include screen.from(screen.$md) {
    min-height: 60px;
    margin: 0 16px;
  }
}

.mid-header__button {
  min-width: 70px;

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  @include screen.to(screen.$md) {
    display: none;
  }
}
