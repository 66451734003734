.micro-icon-button {
  display: inline-flex;
  vertical-align: text-bottom;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: var(--color-grey-2);
  cursor: pointer;
  user-select: none;
  transition: color var(--transition-in);
  appearance: none;

  &:hover,
  &:focus-visible,
  &:active {
    color: var(--color-grey-3);
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }
}
