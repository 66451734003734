@use "../lib/screen";

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page__main {
  flex: 1;

  &.has-padding {
    padding-top: 16px;
    padding-bottom: 96px;

    @include screen.from(screen.$sm) {
      padding-top: 24px;
    }
  }
}
