.contact-button {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 12px 16px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  transition: color var(--transition-in);
  appearance: none;

  img {
    flex-shrink: 0;
    margin-right: 8px;
  }

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: 0;
  }
}
