@use '../lib/screen';

.tabs {
}

.tabs__list {
}

.tabs__tab {
  display: block;
  min-height: 36px;
  margin-right: 8px;
  padding: 7px 16px;
  border: 1px solid var(--color-lines);
  border-radius: 999px;
  line-height: 20px;
  transition: background-color var(--transition-in);

  &[aria-selected='true'],
  &:hover,
  &:focus-visible {
    background: var(--color-grey-05);
  }
}

.tabs__tab-counter {
  color: var(--color-grey-3);
}

.tabs__slider {
  position: relative;
}

.tabs__item {
  width: auto;
}

.tabs__nav {
  display: none;

  @include screen.from(screen.$md) {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 0 12px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 8%);
    cursor: pointer;
    user-select: none;
    transition: opacity var(--transition-in), visibility var(--transition-in);
    transform: translateY(-50%);
    appearance: none;

    .i {
      background-color: var(--color-grey-4);
    }

    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:hover,
    &:focus-visible {
      .i {
        background-color: var(--color-accent);
      }
    }

    &:focus-visible {
      box-shadow: var(--focus-visible-shadow);
      outline: none;
    }
  }
}

.tabs__nav--prev {
  left: -15px;
}

.tabs__nav--next {
  right: -15px;
}
