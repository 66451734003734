@use '../lib/screen';

.main-banner {
  position: relative;
  overflow: hidden;
  margin-bottom: 48px;
  padding-bottom: 53px;
  user-select: none;
}

.main-banner__inner {
  position: relative;
  height: 400px;
  user-select: none;

  @include screen.from(screen.$md) {
    height: 480px;
  }
}

.main-banner__img {
  display: block;
  pointer-events: none;

  img {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    max-width: none;
    height: 100%;
    margin: 0 auto;
    transform: translateX(-50%);
  }
}

.main-banner__link {
  display: block;
  height: 100%;
}

.main-banner__container {
  display: block;
  height: 100%;
  padding-top: 48px;

  @include screen.from(screen.$md) {
    padding-top: 96px;
  }
}

.main-banner__heading {
  position: relative;
  font-size: 24px;
  font-family: Spectral, serif;
  line-height: 28px;

  @include screen.from(screen.$sm) {
    font-size: 40px;
    line-height: 44px;
  }
}

.main-banner__badge-wrap {
  position: absolute;
  bottom: 0;
  left: var(--size-container-offset-left);
  transform: translateY(50%);
}

.main-banner__badge {
  display: inline-block;
  width: 100%;
  padding: 12px;
  border: 1px solid var(--color-lines);
  border-radius: 16px;
  background-color: var(--color-bg);

  @include screen.from(380px) {
    width: auto;
  }
}
