.dropdown {
  position: relative;
}

.dropdown__body {
  animation: fade-in 0.25s both;

  &[aria-hidden='true'] {
    display: none;
  }
}
