.suggestions-nowrap {
  white-space: nowrap;
}

.suggestions-wrapper {
  position: relative;
  vertical-align: top;
  margin: 0;
  padding: 0;
}

/**
 * Выпадающий блок с найденными подсказками
 */
.suggestions-suggestions {
  position: absolute;
  left: 0;
  z-index: 9999;
  box-sizing: border-box;
  min-width: 100%;
  padding: 8px 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: var(--shadow-dropdown);
  cursor: pointer;
}

.suggestions-suggestions strong {
  color: var(--color-accent);
  font-weight: 550;
}

.suggestions-suggestions.suggestions-mobile {
  border-style: none;
}

/**
 * Контейнер для одной подсказки
 */
.suggestions-suggestion {
  overflow: hidden;
  padding: 8px 12px;
  transition: color var(--transition-in);

  &:hover {
    color: var(--color-accent);
  }
}

/**
 * Выбранная (активная) подсказка
 */
.suggestions-selected {
  background: #f0f0f0;
}

.suggestions-selected:hover {
  background: #f0f0f0;
}

/**
 * Информационный блок в верхней части выпадашки с подсказками
 */
.suggestions-hint {
  overflow: hidden;
  padding: 4px;
  color: #777;
  font-size: 85%;
  line-height: 20px;
  white-space: nowrap;
}

/**
 * Дополнительный текст в подсказке, который идет второй строкой
 */
.suggestions-subtext {
  color: #777;
}

/**
 * Размещает дополнительный текст в одну строку с основным текстом подсказки
 */
.suggestions-subtext_inline {
  display: inline-block;
  vertical-align: bottom;
  min-width: 6em;
  margin: 0 0.5em 0 0;
}

/**
 * Разделитель нескольких дополнительных текстов
 */
.suggestions-subtext-delimiter {
  display: inline-block;
  width: 2px;
}

/**
 * Выделяет подсказку
 */
.suggestions-subtext_label {
  margin: 0 0 0 0.25em;
  padding: 0 3px;
  border-radius: 3px;
  background: #f5f5f5;
  font-size: 85%;
}

.suggestions-value[data-suggestion-status='LIQUIDATED'] {
  position: relative;
}

.suggestions-value[data-suggestion-status='LIQUIDATED']::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  border-top: 1px solid rgb(0 0 0 / 40%);
}

/**
 * Промо-блок
 */
.suggestions-promo {
  display: none;
}
