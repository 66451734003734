.notification-banner {
  padding: 10px 0;
  // use color directly as we need to display notifications to old browsers
  background-color: #333;
  color: #fff;
  text-align: center;

  p + p {
    margin-top: 1em;
  }

  a {
    text-decoration: underline;
    text-decoration-color: var(--color-grey-3);

    &:hover,
    &:focus-visible {
      text-decoration-color: #fff;
    }

    &:focus-visible {
      outline-color: var(--color-grey-3);
    }
  }
}

.notification-banner--is-theme-gray {
  background-color: #333;
  color: #fff;
}

.notification-banner--is-theme-red {
  background-color: #a21d1f;
  color: #fff;
  font-weight: 500;
}
