.i {
  display: inline-block;
  vertical-align: text-bottom;
  width: 20px;
  height: 20px;
  background-color: currentcolor;
  transition: background-color var(--transition-in);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.i--32 {
  width: 32px;
  height: 32px;
}

.i--anonymous {
  mask-image: url(../svg/icons/anonymous.svg);
}

.i--me {
  mask-image: url(../svg/icons/me.svg);
}

.i--not-me {
  mask-image: url(../svg/icons/not-me.svg);
}

.i--alarm-exclamation {
  mask-image: url(../svg/icons/alarm-exclamation.svg);
}

.i--location {
  mask-image: url(../svg/icons/location.svg);
}

.i--calendar {
  mask-image: url(../svg/icons/calendar.svg);
}

.i--phone {
  mask-image: url(../svg/icons/phone.svg);
}

.i--no-phone {
  mask-image: url(../svg/icons/no-phone.svg);
}

.i--catalog {
  mask-image: url(../svg/icons/catalog.svg);
}

.i--home {
  mask-image: url(../svg/icons/home.svg);
}

.i--hamburger {
  mask-image: url(../svg/icons/hamburger.svg);
}

.i--close {
  mask-image: url(../svg/icons/close.svg);
}

.i--upload-file {
  mask-image: url(../svg/icons/upload-file.svg);
}

.i--upload-image {
  mask-image: url(../svg/icons/upload-image-20.svg);
}

.i--image-32 {
  width: 32px;
  height: 32px;
  mask-image: url(../svg/icons/image-32.svg);
}

.i--close-24 {
  width: 24px;
  height: 24px;
  mask-image: url(../svg/icons/close-24.svg);
}

.i--search {
  mask-image: url(../svg/icons/search.svg);
}

.i--cart {
  mask-image: url(../svg/icons/cart.svg);
}

.i--cart-24 {
  width: 24px;
  height: 24px;
  mask-image: url(../svg/icons/cart-24.svg);
}

.i--fire {
  mask-image: url(../svg/icons/fire-20.svg);
}

.i--flower {
  mask-image: url(../svg/icons/flower.svg);
}

.i--flower-2 {
  mask-image: url(../svg/icons/flower-2.svg);
}

.i--vip {
  mask-image: url(../svg/icons/vip.svg);
}

.i--chevron-left {
  mask-image: url(../svg/icons/chevron-left.svg);
}

.i--chevron-right {
  mask-image: url(../svg/icons/chevron-right.svg);
}

.i--chevron-up {
  mask-image: url(../svg/icons/chevron-up.svg);
}

.i--chevron-down {
  mask-image: url(../svg/icons/chevron-down.svg);
}

.i--shop {
  mask-image: url(../svg/icons/shop.svg);
}

.i--filter {
  mask-image: url(../svg/icons/filter.svg);
}

.i--truck {
  mask-image: url(../svg/icons/truck.svg);
}

.i--card {
  mask-image: url(../svg/icons/card.svg);
}

.i--card-terminal {
  mask-image: url(../svg/icons/card-terminal.svg);
}

.i--wallet {
  mask-image: url(../svg/icons/wallet.svg);
}

.i--crypto {
  mask-image: url(../svg/icons/crypto.svg);
}

.i--heart {
  mask-image: url(../svg/icons/heart.svg);
}

.i--heart-broken {
  mask-image: url(../svg/icons/heart-broken.svg);
}

.i--heart-star {
  mask-image: url(../svg/icons/heart-star.svg);
}

.i--comment {
  mask-image: url(../svg/icons/comment.svg);
}

.i--medal {
  mask-image: url(../svg/icons/medal.svg);
}

.i--photo {
  mask-image: url(../svg/icons/photo.svg);
}

.i--checkmark-24 {
  width: 24px;
  height: 24px;
  mask-image: url(../svg/icons/checkmark-24.svg);
}

.i--check-fill {
  mask-image: url(../svg/icons/check-fill.svg);
}

.i--clear-fill {
  mask-image: url(../svg/icons/clear-fill.svg);
}

.i--help-fill {
  mask-image: url(../svg/icons/help-fill.svg);
}

.i--shop-courier {
  mask-image: url(../svg/icons/shop-courier.svg);
}

.i--costume-courier {
  mask-image: url(../svg/icons/costume-courier.svg);
}

.i--service-courier {
  mask-image: url(../svg/icons/service-courier.svg);
}

.i--florist {
  mask-image: url(../svg/icons/florist.svg);
}

.i--spruce {
  mask-image: url(../svg/icons/spruce.svg);
}

.i--home-16 {
  width: 16px;
  height: 16px;
  mask-image: url(../svg/icons/home-16.svg);
}

.i--star-16 {
  width: 16px;
  height: 16px;
  mask-image: url(../svg/icons/star-16.svg);
}

.i--vk {
  width: 32px;
  height: 32px;
  mask-image: url(../svg/icons/vk-32.svg);
}

.i--instagram {
  width: 32px;
  height: 32px;
  mask-image: url(../svg/icons/instagram-32.svg);
}

.i--user-12 {
  width: 12px;
  height: 12px;
  mask-image: url(../svg/icons/user-12.svg);
}

.i--eye-16 {
  width: 16px;
  height: 16px;
  mask-image: url(../svg/icons/eye-16.svg);
}

.i--arrow-top-24 {
  width: 24px;
  height: 24px;
  mask-image: url(../svg/icons/arrow-top-24.svg);
}
