.badge-counter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 999px;
  background-color: var(--color-accent);
  color: #fff;
  font-weight: 550;
  font-size: 12px;
  line-height: 16;
}

.badge-counter--md {
  width: 20px;
  height: 20px;
}
