@use '../lib/screen';

.product-page {
  margin-bottom: 48px;

  @include screen.from(screen.$sm) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
    align-items: start;
    width: var(--size-container-width);
    margin: 0 auto 48px;
    padding: 0 var(--size-container-sidegap);
  }

  @include screen.from(screen.$md) {
    grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
  }
}

.product-page__gallery {
  margin-bottom: 16px;

  @include screen.from(screen.$sm) {
    position: sticky;
    top: 24px;
    margin-bottom: 0;

    .global-is-header-shown & {
      top: calc(var(--size-header-panel-height) + 16px);
      transition: top 0.5s var(--ease-in-out-quart);
    }
  }
}

.product-page__info {
  padding: 0 var(--size-container-sidegap);

  @include screen.from(screen.$sm) {
    padding: 0 0 0 12px;
  }

  @include screen.from(screen.$md) {
    padding-left: 16px;
  }

  @include screen.from(screen.$lg) {
    padding-left: 22px;
  }
}

.product-page__title {
  font-size: 18px;
  line-height: 24px;
}

.product-page__prices {
  display: none;

  @include screen.from(screen.$md) {
    display: flex;
    align-items: center;
  }
}

.product-page__price {
  font-weight: 550;
  font-size: 16px;
  line-height: 20px;

  &.is-discount {
    color: var(--color-marketing);
  }

  @include screen.from(screen.$md) {
    font-weight: 550;
    font-size: 18px;
  }
}

.product-page__old-price {
  color: var(--color-grey-3);
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  text-decoration: line-through;

  @include screen.from(screen.$md) {
    margin-left: 6px;
    padding-top: 4px;
    font-weight: 350;
    font-size: 13px;
  }
}

.product-page__client {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--color-blocks);
  font-size: 13px;
  line-height: 18px;
}

.product-page__client-price {
  display: inline-block;
  margin-right: 4px;
  font-weight: 550;
  font-size: 16px;
}

.product-page__client-link {
  display: block;
  margin-top: 2px;
  font-weight: 450;
}

.product-page__delivery {
  font-size: 13px;
  line-height: 18px;
}

.product-page__delivery-item {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.product-page__delivery-list {
  list-style: disc inside;

  li {
    padding-left: 0.25em;
  }
}

.product-page__delivery-icon {
  flex-shrink: 0;
  margin-right: 12px;
  color: var(--color-accent);
}

.product-page__delivery-title {
  margin-bottom: 4px;
  font-weight: 550;
}

.product-page__delivery-link {
  display: inline-block;
  margin-top: 4px;
  font-weight: 450;
}

.product-page__pay-systems {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 8px;
}

.product-page__pay-system {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
}

.product-page__buy {
  @include screen.to(screen.$md) {
    position: fixed;
    bottom: var(--size-tabbar-height);
    left: 0;
    z-index: var(--z-index-tabbar);
    width: 100%;
    min-height: var(--size-buy-bar-height);
    padding: 12px 0;
    border-top: 1px solid var(--color-lines);
    background-color: var(--color-bg);
    filter: drop-shadow(0 0 12px rgb(0 0 0 / 12%));
  }

  @include screen.from(screen.$md) {
    margin-bottom: 32px;
  }
}

.product-page__buy-inner {
  display: flex;
  align-items: center;

  @include screen.to(screen.$md) {
    width: var(--size-container-width);
    margin: 0 auto;
    padding: 0 var(--size-container-sidegap);
  }
}

.product-page__buy-prices {
  margin-right: auto;

  @include screen.from(screen.$md) {
    display: none;
  }
}

.product-page__buy-button {
  min-width: 108px;

  @include screen.from(screen.$md) {
    width: 100%;
  }
}

.product-page__tocart {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  margin-right: 8px;
  padding: 3px 15px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: var(--color-success);
  color: #fff;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: background-color var(--transition-in), border-color var(--transition-in),
    color var(--transition-in);
  appearance: none;

  &:hover,
  &:focus-visible {
    background-color: var(--color-success-active);
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px rgb(46 193 35 / 16%);
    outline: none;
  }

  @include screen.to(screen.$md) {
    display: none;
  }
}

.product-page__tocart-incart {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
}

.product-page__tocart-goto {
  font-size: 12px;
  line-height: 16px;
}

.product-page__features {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 2fr));
  gap: 8px;
}

.product-page__feature {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--color-blocks);
  font-size: 13px;
  line-height: 18px;

  &:last-child {
    grid-column: span 2;
  }

  @include screen.from(screen.$sm) {
    padding: 16px;
  }
}

.product-page__feature-icon {
  margin-bottom: 12px;
  color: var(--color-accent);
}

.product-page__feature-title {
  margin-bottom: 4px;
  font-weight: 550;
}

.product-page__contact-button {
  width: 100%;
  margin-top: 12px;
}
