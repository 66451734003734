.badge {
  display: inline-block;
  min-height: 20px;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: var(--color-accent);
  color: #fff;
  font-weight: 550;
  font-size: 12px;
  line-height: 1.3;
}

.badge--slim {
  min-height: 16px;
  padding: 0 4px;
  font-size: 11px;
  line-height: 16px;
}

.badge--black {
  background-color: #000;
  color: #ffea00;
}

.badge--is-new {
  background-color: #298e37;
}

.badge--is-preorder {
  background-color: #ffa600;
}

.badge--is-discount {
  background-color: var(--color-marketing);
}

.badge--is-warranty {
  background-color: var(--color-marketing-warranty);
  pointer-events: auto; // for the tooltip
}

.badge--is-archived {
  --bg-main-color: var(--color-yellow);

  background: linear-gradient(
      to bottom,
      transparent 0,
      transparent 3px,
      var(--bg-main-color) 3px,
      var(--bg-main-color) calc(100% - 3px),
      transparent calc(100% - 3px)
    ),
    repeating-linear-gradient(
      45deg,
      var(--bg-main-color),
      var(--bg-main-color) 3px,
      var(--color-text-main) 3px,
      var(--color-text-main) 6px
    );
  color: var(--color-text-main);
}
