.partners-slider__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 145px;

  img {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}
