@use '../lib/screen';

.catalog-grid {
  --row-gap: 16px;

  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: var(--row-gap);
  overflow: hidden;
  column-gap: var(--size-gap);

  // overlaps and hides line of last items
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-bg);
  }

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include screen.from(screen.$sm) {
    --row-gap: 24px;
  }
}

.catalog-grid--has-4-items {
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.catalog-grid--has-6-items {
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.catalog-grid--wide {
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.catalog-grid__item {
  position: relative;
  display: flex;
  padding-bottom: var(--row-gap);

  & > * {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% + var(--size-gap));
    height: 1px;
    background-color: var(--color-lines);
  }

  &.is-full {
    grid-column: 1 / -1;
  }
}
