@use '../lib/screen';

.searchbar {
  position: relative;
  z-index: 1;
  background-color: var(--color-bg);

  @include screen.to(screen.$md) {
    &.is-open {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }

  @include screen.from(screen.$md) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px;
    border-radius: 28px;

    &.is-open {
      box-shadow: 0 0 12px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 8%);
    }
  }
}

.searchbar__form-wrap {
  display: flex;
  align-items: center;

  .searchbar.is-open & {
    padding: 12px 16px;
    border-bottom: 1px solid var(--color-lines);

    @include screen.from(screen.$sm) {
      padding: 12px calc((100vw - var(--size-container-width)) / 2 + 24px);
    }

    @include screen.from(screen.$md) {
      padding: 0;
      border-bottom: 0;
    }
  }
}

.searchbar__form {
  position: relative;
  flex: 1;
}

.searchbar__cancel {
  flex-shrink: 0;
  margin-left: 8px;
  padding: 10px 0;
  color: var(--color-accent);
  appearance: none;

  &:hover,
  &:focus-visible {
    color: var(--color-accent-active);
  }

  .searchbar:not(.is-open) & {
    display: none;
  }

  @include screen.from(screen.$md) {
    display: none;
  }
}

.searchbar__input {
  width: 100%;
  min-height: 40px;
  padding: 9px 16px;
  border: 1px solid var(--color-grey-1);
  border-radius: 999px;
  font-size: 16px; // prevent ios zoom on focus
  line-height: 1.3;
  transition: border-color var(--transition-in), box-shadow var(--transition-in);
  appearance: none;

  &:hover {
    border-color: var(--color-accent);
  }

  &:focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 3px rgb(193 35 37 / 16%);
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-grey-3);
    opacity: 1;
  }

  .searchbar.is-open & {
    min-height: 44px;
  }

  @include screen.from(screen.$md) {
    min-height: 44px;
    padding: 11px 16px;
    font-size: 14px;
  }
}

.searchbar:hover {
  // fix hover effect when mouse is over the submit button
  .searchbar__input {
    border-color: var(--color-accent);
  }
}

.searchbar__submit {
  position: absolute;
  top: 50%;
  right: 4px;
  width: 36px;
  height: 36px;
  transform: translateY(-50%);

  @include screen.to(screen.$md) {
    .searchbar:not(.is-open) & {
      display: none;
    }
  }
}

.searchbar__result {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 16px;

  .searchbar:not(.is-open) & {
    display: none;
  }

  @include screen.from(screen.$sm) {
    padding: 0 calc((100vw - var(--size-container-width)) / 2 + 24px);
  }

  @include screen.from(screen.$md) {
    //padding: 16px 16px 8px;
    padding: 0 16px;
  }
}

.searchbar__section {
  margin-top: 16px;
  padding-bottom: 8px;

  &:not(:first-of-type) {
    margin-top: 24px;
  }
}

.searchbar__heading {
  margin-bottom: 8px;
  font-weight: 550;
}

.searchbar__link {
  display: block;
  padding: 8px 0;

  mark {
    background: transparent;
    color: inherit;
    font-weight: 550;
  }

  @include screen.from(screen.$md) {
    padding: 4px 0;
  }
}

.searchbar__link--icon {
  position: relative;
  padding-left: 32px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--color-grey-3);
    transform: translateY(-50%);
    mask-image: url(../svg/icons/search.svg);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }
}

.searchbar__products-list {
  @include screen.from(screen.$sm) {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 12px;
  }
}

.searchbar__product-item {
  @include screen.to(screen.$sm) {
    margin-bottom: 12px;
  }
}

.searchbar__product {
  @include screen.to(screen.$sm) {
    display: flex;
  }
}

.searchbar__product-image {
  position: relative;
  display: block;
  flex-shrink: 0;
  overflow: hidden;
  width: 25%;
  margin-right: 8px;
  border-radius: 8px;
  background: var(--color-blocks) url(../svg/product-bg-sm.svg) no-repeat center;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include screen.from(screen.$sm) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.searchbar__product-title {
  margin-bottom: 4px;
  font-weight: 350;
  font-size: 12px;
  line-height: 16px;
  transition: color var(--transition-in);

  mark {
    background: transparent;
    color: inherit;
    font-weight: 550;
  }

  .searchbar__product:hover &,
  .searchbar__product:focus-visible & {
    color: var(--color-accent);
  }
}

.searchbar__product-price {
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
}

.searchbar__all-button {
  width: 100%;
  margin-top: 16px;
}
