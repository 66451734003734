@use '../lib/screen';

.content-page {
  padding-bottom: 96px;
}

.content-page__content {
  max-width: 640px;
  margin: 0 auto;

  .callout {
    margin: 12px 0;
    padding: 12px 12px 12px 48px;
    border-radius: 8px;
    background: var(--color-blocks) url(../svg/content-callout.svg) no-repeat top 12px left 12px;
  }

  .details-group {
    margin: 16px 0;
  }

  .slider {
    margin: 16px 0;
  }
}

.content-page__info {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: var(--color-text-aux);
}

.content-page__hero {
  display: block;
  margin-bottom: 16px;

  img {
    width: 100%;
    border-radius: 8px;
  }

  @include screen.from(screen.$sm) {
    margin: 0 -24px 24px;
  }

  @include screen.from(screen.$md) {
    margin: 0 -40px 24px;
  }
}
