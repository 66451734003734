@use '../lib/screen';
@use '../lib/typography';

$bp-horizontal: 450px;

.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-text-main);
  user-select: none;
}

.product-card--has-border {
  overflow: hidden;
  padding: 4px;
  border-radius: 8px;
  background: #fff;
}

.product-card--is-horizontal {
  @include screen.from($bp-horizontal) {
    flex-direction: row;
    align-items: stretch;
  }
}

.product-card__info {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .product-card--has-border & {
    padding: 0 8px 8px;
  }

  @include screen.from($bp-horizontal) {
    .product-card--is-horizontal & {
      flex: 0 0 62%;
      padding: 8px 8px 8px 12px;
    }
  }
}

.product-card__link {
  display: block;
  overflow: hidden;
  width: 100%;
  transition: color var(--transition-in);

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }
}

.product-card__slider {
  --swiper-theme-color: var(--color-accent);
  --swiper-pagination-bullet-inactive-color: var(--color-grey-2);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 6px;
  --swiper-pagination-bullet-height: 6px;
  --swiper-pagination-bullet-horizontal-gap: 2px;

  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 8px;
  padding-bottom: 16px;

  .swiper-pagination {
    bottom: 0;
    display: flex;
    justify-content: center;
    line-height: 8px;
  }
}

.product-card__slide {
  display: block;
  width: 100% !important;
  user-select: none;
}

.product-card__image-wrap {
  position: relative;
  width: 100%;
  margin-bottom: 8px;

  @include screen.from($bp-horizontal) {
    .product-card--is-horizontal & {
      margin-bottom: 0;
    }
  }
}

.product-card__image {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 8px;
  background: var(--color-blocks) url(../svg/product-bg-md.svg) no-repeat center;
  background-size: contain;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.product-card__favorite {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.product-card__title {
  font-size: 14px;
  line-height: 20px;

  .product-card--is-slim & {
    font-size: 12px;
    line-height: 16px;
  }

  @include screen.to(screen.$sm) {
    font-size: 12px;
    line-height: 16px;
    @include typography.line-clamp(2);
  }

  @include screen.from($bp-horizontal) {
    @include typography.line-clamp(2);
  }
}

.product-card__badges {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.product-card__badges-list {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding: 8px;

  @include screen.from(screen.$sm) {
    padding: 12px;

    .product-card--is-slim & {
      padding: 8px;
    }
  }
}

.product-card__prices {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.product-card__price {
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;

  &.is-discount {
    color: var(--color-marketing);
  }

  .product-card--is-slim & {
    font-size: 14px;
    line-height: 20px;
  }

  @include screen.from(screen.$sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

.product-card__old-price {
  margin-left: 4px;
  padding-top: 2px;
  color: var(--color-grey-3);
  font-weight: 450;
  font-size: 11px;
  line-height: 16px;
  text-decoration: line-through;

    @include screen.from(screen.$sm) {
    font-size: 12px;
    line-height: 16px;
  }
}

.product-card__bundle-content {
  display: flex;
  align-items: center;
  margin-top: 8px;
  border: 0;
  background: transparent;
  color: var(--color-accent);
  appearance: none;

  .product-card--is-slim & {
    .i {
      width: 16px;
      height: 16px;
    }
  }
}

.product-card__bundle-content-text {
  margin-left: 8px;
  padding-top: 2px;
  border-bottom: 1px solid transparent;
  line-height: 20px;

  .product-card__bundle-content:hover &,
  .product-card__bundle-content:focus-visible {
    border-color: var(--color-accent);
  }

  .product-card--is-slim & {
    margin-left: 4px;
    font-size: 13px;
    line-height: 18px;
  }
}

.product-card__buy {
  width: 100%;
  margin-top: 16px;

  .product-card--is-slim & {
    margin-top: 12px;
  }

  @include screen.from($bp-horizontal) {
    .product-card--is-horizontal & {
      width: 110px;
    }
  }
}
