/*
https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&family=Spectral&family=Roboto+Flex:wght@200..1000&display=swap
*/

@font-face {
  font-weight: 200 1000;
  font-style: normal;
  font-stretch: 100%;
  font-family: 'Roboto Flex';
  src: url(../fonts/roboto-flex_cyrillic-ext_variable.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

// used in critical CSS in app.twig, imported inside assets-index.js
// @font-face {
//   font-weight: 200 1000;
//   font-style: normal;
//   font-stretch: 100%;
//   font-family: 'Roboto Flex';
//   src: url(../fonts/roboto-flex_cyrillic_variable.woff2)
//     format('woff2');
//   font-display: swap;
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }

@font-face {
  font-weight: 200 1000;
  font-style: normal;
  font-stretch: 100%;
  font-family: 'Roboto Flex';
  src: url(../fonts/roboto-flex_latin-ext_variable.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-weight: 200 1000;
  font-style: normal;
  font-stretch: 100%;
  font-family: 'Roboto Flex';
  src: url(../fonts/roboto-flex_latin_variable.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'IBM Plex Serif';
  src: url(../fonts/ibm-flex-serif_all_400_subset.woff2) format('woff2');
  font-display: block;
}

/* cyrillic */
// manually subsetted and used in critical CSS on front page,
// imported inside assets-index.js
// @font-face {
//   font-weight: 400;
//   font-style: normal;
//   font-family: Spectral;
//   src: url(../fonts/spectral-cyrillic-400.woff2)
//     format('woff2');
//   font-display: swap;
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
