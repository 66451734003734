@use '../lib/screen';

.gallery-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-dialog);
  display: flex;
}

.gallery-dialog[aria-hidden='true'] {
  display: none;
}

.gallery-dialog__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0 0 0 / 50%);
  animation: fade-in 200ms both;
}

.gallery-dialog__body {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-height: 100%;
  background-color: rgb(255 255 255);
  animation: fade-in 400ms 200ms both;
}

.gallery-dialog__fieldset {
  opacity: 1;
  transition: opacity var(--transition-in);

  &.is-submitting {
    opacity: 0.5;
  }
}

.gallery-dialog__content {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 65px);
  margin: 0 auto;
  padding: 16px 0;

  @include screen.from(screen.$sm) {
    padding: 24px var(--size-container-sidegap);
  }

  @include screen.from(screen.$md) {
    margin: 0;
    padding: 24px;
  }
}

.gallery-dialog__swiper {
  flex: 1 1 auto;
  overflow: visible;
  margin: 0;

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 999px;
    background: var(--color-grey-05);
    color: var(--color-text-main);
    transition: color var(--transition-in), opacity var(--transition-in);

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-color: currentcolor;
      font-family: serif;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
    }

    &:hover {
      color: var(--color-accent);
    }

    @include screen.to(screen.$md) {
      display: none;
    }
  }

  .swiper-button-prev {
    left: -10px;

    &::after {
      mask-image: url(../svg/icons/chevron-left.svg);
    }
  }

  .swiper-button-next {
    right: -10px;

    &::after {
      mask-image: url(../svg/icons/chevron-right.svg);
    }
  }
}

.gallery-dialog__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.gallery-dialog__picture {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.gallery-dialog__thumbnail-swiper {
  --swiper-navigation-size: 32px;

  flex-shrink: 0;
  overflow: visible;
  width: 100%;
  margin-top: 24px;
  padding: 0 16px;

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 999px;
    background: var(--color-white);
    color: var(--color-text-main);
    box-shadow: 0 0 0 1px rgb(0 0 0 / 8%), 0 0 12px 0 rgb(0 0 0 / 12%);
    transition: color var(--transition-in), opacity var(--transition-in);

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-color: currentcolor;
      font-family: serif;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
    }

    &:hover {
      color: var(--color-accent);
    }

    @include screen.to(screen.$md) {
      display: none;
    }
  }

  .swiper-button-prev {
    left: -10px;

    &::after {
      mask-image: url(../svg/icons/chevron-left.svg);
    }
  }

  .swiper-button-next {
    right: -10px;

    &::after {
      mask-image: url(../svg/icons/chevron-right.svg);
    }
  }

  @include screen.from(screen.$md) {
    padding: 0;
  }
}

.gallery-dialog__thumbnail-item {
  position: relative;
  overflow: hidden;
  width: 64px;
  height: 64px;
  padding: 4px;
  border: 1px solid var(--color-grey-1);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: border-color var(--transition-in);
  appearance: none;

  &:hover,
  &:focus-visible {
    border-color: var(--color-grey-2);
    outline: none;
  }

  &.swiper-slide-thumb-active {
    border-color: var(--color-accent);
  }

  &.has-user-badge::after {
  }
}

.gallery-dialog__thumbnail-badge {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 999px;
  background: var(--color-white);

  &::after {
    content: '';
    width: 12px;
    height: 12px;
    background: var(--color-grey-2);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(../svg/icons/user-12.svg);
  }
}

.gallery-dialog__thumbnail-picture {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: var(--color-blocks);

  &.is-video {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 24px;
      height: 24px;
      background: url(../svg/video-play.svg) no-repeat center;
      background-size: contain;
      transform: translate(-50%, -50%);
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.gallery-dialog__title {
  display: flex;
  align-items: center;
  min-height: 56px;
  padding-left: 16px;
  border-bottom: 1px solid var(--color-lines);
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;

  @include screen.from(screen.$sm) {
    padding-left: 24px;
  }

  @include screen.from(screen.$md) {
    min-height: 64px;
  }
}

.gallery-dialog__close {
  position: absolute;
  top: 8px;
  right: 8px;

  @include screen.from(screen.$sm) {
    right: 16px;
  }

  @include screen.from(screen.$md) {
    top: 12px;
    right: 12px;
  }
}
