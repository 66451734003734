@use '../lib/screen';

.category {
  overflow: hidden;
  padding-bottom: 96px;
}

.category__heading {
}

.category__title {
  display: inline;
}

.category__count {
  vertical-align: 10%;
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 16px;
}

.category__subcategories {
  margin-top: 12px;

  @include screen.from(screen.$sm) {
    margin-top: 16px;
  }
}

.category__inner {
  display: grid;
  grid-template:
    'filter          sort'
    'applied-filters applied-filters'
    'products        products' 1fr
    / minmax(0, 1fr) 175px;
  margin-top: 16px;
  column-gap: var(--size-gap);

  @include screen.from(screen.$md) {
    grid-template:
      'filter sort'
      'filter applied-filters'
      'filter products' 1fr
      / minmax(0, 3fr) minmax(0, 9fr);
  }
}

.category__filter {
  grid-area: filter;
}

.category__filter-button {
  @include screen.from(screen.$md) {
    display: none;
  }
}

.category__sort {
  grid-area: sort;
  justify-self: end;

  @include screen.from(screen.$md) {
    justify-self: start;
  }
}

.category__applied-filters {
  grid-area: applied-filters;
  margin-top: 12px;

  @include screen.from(screen.$sm) {
    margin-top: 16px;
  }
}

.category__clear-filter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  padding: 7px 11px;
  border: 1px solid var(--color-lines);
  border-radius: 999px;
  color: var(--color-accent);
  font-weight: 350;
  font-size: 14px;
  line-height: 1.4px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: background-color var(--transition-in),
    border-color var(--transition-in), color var(--transition-in);
  appearance: none;

  &:hover,
  &:focus-visible,
  &:active {
    background-color: var(--color-grey-05);
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }
}

.category__products {
  grid-area: products;
  margin-top: 20px;

  &.is-loading {
    opacity: 0.35;
    transition: opacity 0.3s ease-in;
  }

  @include screen.from(screen.$sm) {
    margin-top: 24px;
  }
}

.category__load-more {
  display: grid;
  width: 100%;
  max-width: 236px;
  margin: 0 auto 16px;
}

.category__bottom {
  margin-top: 32px;
}

.category__faq {
  margin-top: 60px;
}
