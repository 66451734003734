.search-query-card {
  display: inline-block;
  padding: 6px 16px;
  border: 1px solid var(--color-lines);
  border-radius: 999px;
  transition: background-color var(--transition-in);

  &:hover, &:focus-visible {
    background-color: var(--color-grey-05);
    outline: 0;
  }

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }
}
