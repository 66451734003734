@use '../lib/screen';

.p404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 24px 0 96px;
  text-align: center;

  @include screen.from(screen.$sm) {
    padding-top: 32px;
  }
}

.p404__404 {
  position: relative;
  margin-bottom: 16px;
  font-size: 60px;
  font-family: Spectral, serif;
  line-height: 1.2;
  text-align: center;

  @include screen.from(screen.$sm) {
    margin-bottom: 32px;
    font-size: 90px;
  }
}

.p404__buttons {
  display: grid;
  grid-template-columns: 100%;
  gap: 16px;
  width: 100%;

  @include screen.from(450px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
