@use '../lib/screen';

.feature-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 144px;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--color-grey-05);
  font-weight: 450;
  font-size: 13px;
  line-height: 1.4;

  @include screen.from(screen.$md) {
    padding: 16px;

    &::before {
      top: 16px;
      left: 16px;
    }
  }
}

.feature-card__icon {
  display: block;
  width: 20px;
  height: 20px;
  margin-bottom: 8px;
  background-color: var(--color-accent);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.feature-card__text {
  margin-bottom: 22px;
}

.feature-card__more {
  align-self: flex-start;
  margin-top: auto;
}
