@use '../lib/screen';

.landing-features__list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--size-gap);

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.landing-features__text {
  margin-top: 24px;
  color: var(--color-text-aux);
  font-weight: 350;
  font-size: 12px;

  & > * + * {
    // margin-top: 1em;
  }

  ul {
    list-style: disc inside;

    li {
      padding-left: 0.5em;
    }
  }
}
