.toast {
  position: fixed;
  top: 16px;
  left: 50%;
  z-index: var(--z-index-notifications);
  display: flex;
  align-items: center;
  width: max-content;
  padding: 7px 12px;
  border-radius: 8px;
  background-color: var(--color-bg);
  box-shadow: 0 0 12px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 8%);
  font-weight: 450;
  white-space: nowrap;
  transform: translate(-50%, 0);

  &.is-transition-enter {
    transition: transform 0.3s var(--ease-in-out-quart), opacity 0.3s var(--ease-in-out-quart);
  }

  &.is-transition-enter-start {
    opacity: 0;
    transform: translate(-50%, -100%);
  }

  &.is-transition-enter-end {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  &.is-transition-leave {
    transition: opacity 0.3s var(--ease-in-out-quart);
  }

  &.is-transition-leave-start {
    opacity: 1;
  }

  &.is-transition-leave-end {
    opacity: 0;
  }
}

.toast__icon {
  margin-right: 8px;
}

.toast__text {
  display: inline-block;
  padding: 1px 0;
}
