@use '../lib/screen';

.category-cards__swiper {
  overflow: visible;

  // compensate for the :focus-visible outline
  margin: 0 -3px;
  padding: 3px;

  @include screen.from(screen.$md) {
    overflow: hidden;
  }
}

.category-cards__list {
  display: flex;
  justify-content: space-between;
}

.category-cards__item {
  width: 136px;
  height: 192px;
  margin-right: 8px;

  @include screen.from(screen.$sm) {
    width: 168px;
    height: 224px;
    margin-right: 12px;
  }

  @include screen.from(screen.$md) {
    flex: 1;
    width: auto;
    height: 244px;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.category-cards__card {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  background: var(--color-grey-05);
  outline: 0;
  user-select: none;
  isolation: isolate;

  &:focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }
}

.category-cards__img {
  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.category-cards__title {
  position: relative;
  font-weight: 550;
  font-size: 16px;
  line-height: 1.25;
  transition: color var(--transition-in);

  .category-cards__card:hover &,
  .category-cards__card:focus-visible & {
    color: var(--color-accent);
  }
}
