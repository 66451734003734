@use '../lib/screen';
@use '../lib/transition';

.contacts-popup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: var(--z-index-dropdown);
  width: 256px;
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 8%), 0 0 12px 0 rgb(0 0 0 / 12%);
}

.contacts-popup__body {
  font-weight: 350;
  font-size: 13px;
  line-height: 16px;
}

.contacts-popup__time {
  margin-bottom: 4px;
  color: var(--color-text-aux);
}

.contacts-popup__address {
  display: block;
  margin-bottom: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-lines);

  @include screen.from(screen.$md) {
    padding-bottom: 16px;
  }
}

.contacts-popup__contact {
  display: grid;
  grid-template:
    'icon label'
    'icon value'
    / 36px 1fr;
  gap: 4px 8px;
  padding: 8px 0;

  @include screen.from(screen.$md) {
    padding: 4px 0;
  }
}

.contacts-popup__icon {
  grid-area: icon;

  img {
    width: 100%;
  }
}

.contacts-popup__label {
  grid-area: label;
  color: var(--color-text-aux);
}

.contacts-popup__value {
  grid-area: value;
  transition: color transition.$in;

  .contacts-popup__contact:hover &,
  .contacts-popup__contact:focus-visible & {
    color: var(--color-accent);
  }
}
