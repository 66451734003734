@use '../lib/screen';

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -124px;
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  padding: 7px 11px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 550;
  font-size: 14px;
  line-height: 1.4px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: background-color var(--transition-in), border-color var(--transition-in),
    color var(--transition-in);
  appearance: none;

  &:not(:disabled):focus-visible {
    box-shadow: var(--focus-visible-shadow);
    outline: none;
  }

  &:disabled {
    box-shadow: none;
    outline: none;
    cursor: not-allowed;
  }
}

.button--has-loader {
  display: inline-grid;
  justify-items: center;

  & > * {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }
}

.button--primary {
  background-color: var(--color-accent);
  color: #fff;

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible,
  &:not(:disabled):active {
    background-color: var(--color-accent-active);
  }

  &:disabled {
    border-color: var(--color-grey-2);
    background-color: var(--color-grey-2);
    color: #fff;
  }
}

.button--primary-outline {
  border-color: var(--color-accent-active);
  background-color: var(--color-bg);
  color: var(--color-accent-active);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible,
  &:not(:disabled):active {
    background-color: var(--color-accent-active);
    color: #fff;
  }

  &:disabled {
    border-color: var(--color-grey-2);
    background-color: var(--color-grey-2);
    color: #fff;
  }
}

.button--secondary-outline {
  border-color: var(--color-grey-1);
  background-color: var(--color-bg);
  color: var(--color-text-main);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible,
  &:not(:disabled):active {
    background-color: var(--color-grey-05);
  }

  &:disabled {
    border-color: var(--color-grey-2);
    background-color: var(--color-grey-2);
    color: #fff;
  }
}

.button--inverse {
  border-color: var(--color-white-16);
  background-color: transparent;
  color: #fff;

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible,
  &:not(:disabled):active {
    background-color: var(--color-white-4);
  }

  &:disabled {
    border-color: var(--color-white-16);
    background-color: transparent;
    color: var(--color-white-50);
  }
}


.button--xs {
  min-height: 24px;
  padding: 3px 8px;
  border-radius: 4px;
  font-weight: 550;
  font-size: 12px;
  line-height: 16px;
}

.button--s {
  // default height: 36px
}

.button--m {
  min-height: 44px;
  padding: 11px 15px;
}

@include screen.from(screen.$md) {
  .md\:button--m {
    min-height: 44px;
    padding: 11px 15px;
  }
}

.button--l {
  min-height: 52px;
  padding: 15px;
}

.button__content {
  opacity: 1;
  transition: opacity var(--transition-in);

  .button.is-loading & {
    opacity: 0;
  }
}

.button__loader {
  // https://uiball.com/loaders/
  --loader-speed: 2s;

  vertical-align: middle;
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity var(--transition-in);
  transform-origin: center;
  animation: rotate var(--loader-speed) linear infinite;

  circle {
    fill: none;
    stroke: currentcolor;
    animation: stretch calc(var(--loader-speed) * 0.75) ease-in-out infinite;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }

  .button.is-loading & {
    opacity: 1;
  }
}

.button__left-icon {
  margin-right: 8px;
}

.button__right-icon {
  margin-left: 4px;
}
