@use '../lib/screen';

.slider {
  --swiper-theme-color: var(--color-accent);
  --swiper-pagination-bullet-inactive-color: var(--color-grey-2);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 6px;
  --swiper-pagination-bullet-height: 6px;
  --_swiper-pagination-offset: var(--swiper-pagination-offset, 0);
  --_swiper-navigation-offset: var(--swiper-navigation-offset, -18px);

  position: relative;

  .swiper {
    width: 100%;
    padding-bottom: var(--_swiper-pagination-offset);
  }

  &.has-pagination {
    --swiper-pagination-offset: 16px;
  }

  .swiper-pagination {
    bottom: 0;
    line-height: 10px;
  }

  .swiper-pagination-bullets-dynamic {
    .swiper-pagination-bullet-active-prev,
    .swiper-pagination-bullet-active-next {
      transform: none;
    }

    .swiper-pagination-bullet-active-prev-prev,
    .swiper-pagination-bullet-active-next-next {
      transform: scale(0.9);
    }
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-slide.is-rounded {
    overflow: hidden;
    border-radius: 8px;
  }
}

.slider--is-mobile-visible {
  @include screen.to(screen.$lg) {
    .swiper {
      overflow: visible;
    }
  }
}

.slider__next,
.slider__prev {
  display: none;

  @include screen.from(screen.$sm) {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 0 12px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 8%);
    cursor: pointer;
    user-select: none;
    transition: opacity var(--transition-in), visibility var(--transition-in);
    transform: translateY(-50%);
    appearance: none;

    .i {
      background-color: var(--color-grey-4);
    }

    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:hover,
    &:focus-visible {
      .i {
        background-color: var(--color-accent);
      }
    }

    &:focus-visible {
      box-shadow: var(--focus-visible-shadow);
      outline: none;
    }
  }
}

.slider__prev {
  left: var(--_swiper-navigation-offset);
}

.slider__next {
  right: var(--_swiper-navigation-offset);
}
