@use '../lib/screen';

.small-gallery {
  display: flex;
  align-items: center;
}

.small-gallery__item {
  &:not(:last-of-type) {
    margin-right: 8px;
  }
}

.small-gallery__preview {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  width: 90px;
  height: 90px;
  border: 1px solid rgb(0 0 0 / 8%);
  border-radius: 8px;

  @include screen.from(screen.$sm) {
    width: 113px;
    height: 113px;
  }

  @include screen.from(screen.$lg) {
    width: 133px;
    height: 133px;
  }
}

.small-gallery__picture {
  position: relative;
  display: block;
  grid-area: 1 / 1 / -1 / -1;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .small-gallery__preview.is-counter & {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(4px);
    }
  }
}

.small-gallery__counter {
  position: relative;
  display: block;
  grid-area: 1 / 1 / -1 / -1;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 550;
  line-height: 32px;
}
